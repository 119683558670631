import { TranslationActionType } from '../../types/translationType'

export const setUpTranslateAuthPage = (translate: string[]) => {
    return {type: TranslationActionType.SET_UP_TRANSLATE_AUTH_PAGE, payload: translate}
}

export const setUpTranslateGameGhatPage = (translate: string[]) => {
    return {type: TranslationActionType.SET_UP_TRANSLATE_GAME_CHAT_PAGE, payload: translate}
}

export const setUpTranslateGamePage = (translate: string[]) => {
    return {type: TranslationActionType.SET_UP_TRANSLATE_GAME_PAGE, payload: translate}
}

export const setUpTranslateMainPage = (translate: string[]) => {
    return {type: TranslationActionType.SET_UP_TRANSLATE_MAIN_PAGE, payload: translate}
}

export const setUpTranslateNotFoundPage = (translate: string[]) => {
    return {type: TranslationActionType.SET_UP_TRANSLATE_NOT_FOUND_PAGE, payload: translate}
}

export const setUpTranslatePasswordRecoveryPage = (translate: string[]) => {
    return {type: TranslationActionType.SET_UP_TRANSLATE_PASSWORD_RECOVERY_PAGE, payload: translate}
}