import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { closeNoMoneyModal } from '../../store/actionCreators/noMoneyModal'

import { textsGamePage } from '../gameContent/config'
import { translation } from '../../utils/translation'

import { SContentWrap } from './style'
import { 
    SModalWrap,
    STitle,
    SH2,
    SCloseModalBtn,
    SGreyWrap
} from '../newGameModal/style'
import {
    SP,
    SBtn
} from '../readyToPlayModal/style'
import { SBlock } from '../devModal/style'

export const NoMoneyModal = () => {

    const dispatch = useDispatch()
    
    const { noMoneyModalState } = useTypedSelector(state => state.noMoneyModalState)
    const { translationStateGamePage } = useTypedSelector(state => state.translationStateGamePage)

    return (
        <SModalWrap
            showModal={noMoneyModalState}
        >
            <SBlock>
                <STitle>
                    <SH2
                        style={{'color': '#FF543D'}}
                    >
                        {translation('НЕ ХВАТАЕТ ДЕНЕГ', translationStateGamePage, textsGamePage)}
                    </SH2>
                    <SCloseModalBtn
                        onClick={() => dispatch(closeNoMoneyModal())}
                    >
                        <span/>
                        <span/>
                    </SCloseModalBtn>
                </STitle>
                <SContentWrap>
                    <SP
                        style={{'opacity': '0.6'}}
                    >
                        Картинка
                    </SP>
                    <SP
                        marginTop='3vh'
                        >
                        {translation('У вас не хватает денег для совершения действия', translationStateGamePage, textsGamePage)}.
                    </SP>
                    <SP
                        marginTop='1vh'
                        style={{'opacity': '0.6'}}
                    >
                        {translation('Для получения средств Вы можете заложить фирму, продать филиалы или взять кредит', translationStateGamePage, textsGamePage)}.
                    </SP>
                </SContentWrap>
                <SGreyWrap>
                    <SBtn
                        color='yellow'
                        onClick={() => dispatch(closeNoMoneyModal())}
                    >
                        {translation('ОК', translationStateGamePage, textsGamePage)}
                    </SBtn>
                </SGreyWrap>
            </SBlock>
        </SModalWrap>
    )
}