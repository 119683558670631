export enum TranslationActionType {
    SET_UP_TRANSLATE_AUTH_PAGE = 'SET_UP_TRANSLATE_AUTH_PAGE',
    SET_UP_TRANSLATE_GAME_CHAT_PAGE = 'SET_UP_TRANSLATE_GAME_CHAT_PAGE',
    SET_UP_TRANSLATE_GAME_PAGE = 'SET_UP_TRANSLATE_GAME_PAGE',
    SET_UP_TRANSLATE_MAIN_PAGE = 'SET_UP_TRANSLATE_MAIN_PAGE',
    SET_UP_TRANSLATE_NOT_FOUND_PAGE = 'SET_UP_TRANSLATE_NOT_FOUND_PAGE',
    SET_UP_TRANSLATE_PASSWORD_RECOVERY_PAGE = 'SET_UP_TRANSLATE_PASSWORD_RECOVERY_PAGE',
}
export interface TranslationState {
    translationStateAuthPage: string[],
    translationStateGameChatPage: string[],
    translationStateGamePage: string[],
    translationStateMainPage: string[],
    translationStateNotFoundPage: string[],
    translationStatePasswordRecoveryPage: string[]
}

export interface TranslationAction {
    type: string,
    payload: string[]
}