/* export const generateRefreshToken = (id: string) => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let refreshToken = ''
    for (let i = 0; i < 40; i++) {
    refreshToken += characters[Math.floor(Math.random() * characters.length)]
    }
    return refreshToken + id
} */

export const difTime = (date : any) => {
    let current : any = new Date;
    return (current - date) / 1000;
}
