import { LanguageModalActionType, LanguageModalState, LanguageModalAction } from '../../types/languageModalType'

const initialState: LanguageModalState = {
    languageModalState: false
}

export const languageModaReducer = (state = initialState, action: LanguageModalAction): LanguageModalState => {
    switch(action.type) {
        case LanguageModalActionType.CHANGE_VALUE_LANGUAGE_MODAL:
            return {
                ...state,
                languageModalState: !state.languageModalState
            }
        case LanguageModalActionType.CLOSE_LANGUAGE_MODAL:
            return {
                ...state,
                languageModalState: false
            }
        default:
            return state
    }   
}