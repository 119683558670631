export enum NoMoneyModalActionType {
    OPEN_NO_MONEY_MODAL = 'OPEN_NO_MONEY_MODAL',
    CLOSE_NO_MONEY_MODAL = 'CLOSE_NO_MONEY_MODAL'
}

export interface NoMoneyModalState {
    noMoneyModalState: boolean
}

export interface NoMoneyModalAction {
    type: string
}