import {
    useState,
    useMemo,
    useEffect,
    useRef
} from 'react'

import WTSServise from '../../services/WTSServise'

import { useDispatch } from 'react-redux'
import { setLanguage } from '../../store/actionCreators/language'
import { closeLanguageModal } from '../../store/actionCreators/languageModal'
import { setCustomTooltipState } from '../../store/actionCreators/customTooltip'

import { languageModalConfig } from './config'

import { 
    SLanguageModal,
    SLanguage,
    SUl
} from './style'

export const LanguageModal = (
    {game, languageModalState}
    : {game: boolean, languageModalState: boolean}) => {

    const dispatch = useDispatch()

    const modalRef = useRef<HTMLDivElement>(null)

    const [languageArr, setLanguageArr] = useState<[] | null>(null)

    useEffect(() => {
        updateLanguage()
    }, [])

    const updateLanguage = async () => {
        try {
            const response = await WTSServise.languages()
            setLanguageArr(response.data.data.languages)
        } catch (e: any) {
            if (!e) return

            if (e.request.status === 400) {
                dispatch(setCustomTooltipState({
                    status: 'error',
                    titleText: 'Ошибка',
                    text: 'Не удалось получить список языков с сервера'
                }))
                return
            }
            
            console.log(e)
        }
    }
    
    const changeLanguage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        const language = (e.target as HTMLDivElement).getAttribute('data-language')
        if (language) {
            localStorage.setItem('languageState', JSON.stringify(language))
            dispatch(setLanguage(language))
            dispatch(closeLanguageModal())
        }
    }

    useEffect(() => {
        const closeModal = (event: MouseEvent) => {
            if (!modalRef.current) return
            const target = event.target as HTMLElement;

            if (!modalRef.current.contains(target) && modalRef.current && target.id !== 'languageButton') {
                dispatch(closeLanguageModal())
            }
        }
    
        document.addEventListener('click', closeModal)
    
        return () => {
            document.removeEventListener('click', closeModal)
        }
    }, [languageModalState])

    const languageButtons = useMemo(() => {
        return languageArr?.map(({name}, i) => {
            
            const lastItem = languageArr.length

            const [lang] = languageModalConfig.filter((item) => item.id === name)
            const {text, icon} = lang

            return (
                <li key={text}>
                    <SLanguage 
                        data-language={name}
                    >
                        <span>
                            {text}
                        </span>
                        <img
                            src={icon}
                            alt={text}
                        />
                    </SLanguage>
                    {i === lastItem - 1 ? null : <hr/>}
                </li>
            )
        })
    }, [languageArr])

    return languageArr ? (
        <SLanguageModal
            ref={modalRef} 
            game={game} 
            languageModalState={languageModalState}
            onClick={(e) => changeLanguage(e)}
        >
            <SUl>
                {languageButtons}
            </SUl>
        </SLanguageModal>
    ) : null
}