import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { closeCustomTooltip } from '../../store/actionCreators/customTooltip'

import {
    SCustomTooltipWrap,
    SCustomTooltip
} from './style'
import { SCloseModalBtn } from '../newGameModal/style'

export const CustomTooltipWrap = ({children}: {children: React.ReactNode}) => {
    return (
        <SCustomTooltipWrap>
            {children}
        </SCustomTooltipWrap>
    )
}

export const CustomTooltip = () => {

    const dispatch = useDispatch()
    
    const { customTooltipState } = useTypedSelector(state => state.customTooltipState)

    useEffect(() => {
        const timeout = setTimeout(() => {
            dispatch(closeCustomTooltip())
        }, 5000)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    const renderTooltip = customTooltipState ? 
        <SCustomTooltip
            status={customTooltipState.status}
        >
            <SCloseModalBtn
                onClick={() => dispatch(closeCustomTooltip())}
            >
                <span/>
                <span/>
            </SCloseModalBtn>
            <h3>
                {customTooltipState.titleText}
            </h3>
            <p>
                {customTooltipState.text}
            </p>
        </SCustomTooltip> : null

    return renderTooltip
}