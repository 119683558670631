import { DevModalActionType, DevModalState, DevModalAction } from '../../types/devModalType'

const initialState: DevModalState = {
    devModalState: false
}

export const devModalReducer = (state = initialState, action: DevModalAction): DevModalState => {
    switch(action.type) {
        case DevModalActionType.OPEN_DEV_MODAL:
            return {
                ...state,
                devModalState: true
            }
        case DevModalActionType.CLOSE_DEV_MODAL:
            return {
                ...state,
                devModalState: false
            }
        default:
            return state
    }   
}