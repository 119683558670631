import { AuctionModalActionType, AuctionModalState, AuctionModalAction } from '../../types/auctionModalType'

const initialState: AuctionModalState = {
    auctionModalState: false
}

export const auctionModalReducer = (state = initialState, action: AuctionModalAction): AuctionModalState => {
    switch(action.type) {
        case AuctionModalActionType.OPEN_AUCTION_MODAL:
            return {
                ...state,
                auctionModalState: true
            }
        case AuctionModalActionType.CLOSE_AUCTION_MODAL:
            return {
                ...state,
                auctionModalState: false
            }
        default:
            return state
    }   
}