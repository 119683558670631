const postFrameMessage = (messageName: string, data: any) => {
    const iframe: any = document.getElementById("iframe");

    if (iframe)
        iframe.contentWindow.postMessage(JSON.stringify({
            ...data,
            ...{
                type: messageName
            }
        }), "*");
}

export default postFrameMessage;