import { socket } from '../../sockets/lobbie/createConnectionSocket'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { closeReadyToPlayModal } from '../../store/actionCreators/readyToPlayModal'

import { textsMainPage } from '../mainContent/config'
import { translation } from '../../utils/translation'

import {
    SP,
    SBtn
} from './style'
import { 
    SModalWrap,
    STitle,
    SH2,
    SCloseModalBtn,
    SGreyWrap
} from '../newGameModal/style'
import { SBlock } from '../devModal/style'

export const ReadyToPlayModal = () => {

    const dispatch = useDispatch()

    const { readyToPlayModalState, openModalLobbyId, lobbyName } = useTypedSelector(state => state.readyToPlayModalState)
    const { translationStateMainPage } = useTypedSelector(state => state.translationStateMainPage)
    

    const letsPlay = () => {
        // действия по переходу на страницу с определенной игрой
        socket.emit("lobby.join", { "lobbyId": openModalLobbyId });
        dispatch(closeReadyToPlayModal())
    }

    return (
        <SModalWrap
            showModal={readyToPlayModalState}
        >
            <SBlock>
                <STitle>
                    <SH2>
                        {translation('САДИМСЯ', translationStateMainPage, textsMainPage)}?
                    </SH2>
                    <SCloseModalBtn
                        onClick={() => dispatch(closeReadyToPlayModal())}
                    >
                        <span/>
                        <span/>
                    </SCloseModalBtn>
                </STitle>
                <SP
                    marginTop='8vh'
                >
                    {`${translation('Вы уверены, что хотите сесть за стол', translationStateMainPage, textsMainPage)} ”${lobbyName}”?`}
                </SP>
                <SGreyWrap
                    style={{'gap' : '2vh'}}
                >
                    <SBtn
                        color='green'
                        onClick={letsPlay}
                        style={{'minWidth': '80px'}}
                    >
                        {translation('ПРИНЯТЬ', translationStateMainPage, textsMainPage)}
                    </SBtn>
                    <SBtn
                        color='yellow'
                        onClick={() => dispatch(closeReadyToPlayModal())}
                        style={{'minWidth': '80px'}}
                    >
                        {translation('ОТМЕНА', translationStateMainPage, textsMainPage)}
                    </SBtn>
                </SGreyWrap>
            </SBlock>
        </SModalWrap>
    )
}