export enum PlayersActionType {
    SET_UP_PLAYERS = 'SET_UP_PLAYERS'
}

export interface PlayersState {
    PlayersListState: any
}

export interface PlayersAction {
    type: string,
    payload?: any
}
