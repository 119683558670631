import { NewGameModalActionType, NewGameModalState, NewGameModalAction } from '../../types/newGameModalType'

const initialState: NewGameModalState = {
    newGameModalState: false
}

export const newGameModalReducer = (state = initialState, action: NewGameModalAction): NewGameModalState => {
    switch(action.type) {
        case NewGameModalActionType.CHANGE_VALUE_NEW_GAME_MODAL:
            return {
                ...state,
                newGameModalState: !state.newGameModalState
            }
        default:
            return state
    }   
}