export enum MenuButtonsHideActionType {
    SET_UP_SHOW_BUTTONS = 'SET_UP_SHOW_BUTTONS',
    SET_UP_HIDE_BUTTONS = 'SET_UP_HIDE_BUTTONS',
    SET_UP_ADDITION_BUTTON = 'SET_UP_ADDITION_BUTTON',
    SET_HIDE_ADDITION_BUTTON = 'SET_HIDE_ADDITION_BUTTON'
}

export interface MenuButtonsHideState {
    MenuButtonsHideState: boolean,
    AdditionMenuButtonsState: boolean,
    AdditionMenuButtonsCommandState: string | undefined
}

export interface MenuButtonsHideAction {
    type: string,
    command? : string
}
