import data from '../../assets/icons/data.svg'
import rating from '../../assets/icons/rating.svg'
import store from '../../assets/icons/store.svg'
import bank from '../../assets/icons/bank.svg'

export const menuButtonsConfig = [
    {
        id: 'data',
        icon: data
    },
    {
        id: 'rating',
        icon: rating
    },
    {
        id: 'store',
        icon: store
    },
    {
        id: 'bank',
        icon: bank
    },
    {
        id: 'newgame',
        bigSize: true
    },
    {
        id: 'inviteFrend',
        bigSize: true
    },
]
