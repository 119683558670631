import {
    useState,
    useEffect,
    useRef
} from 'react'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setVolume } from '../../store/actionCreators/volum'
import { closeVolumeModal } from '../../store/actionCreators/volumModal'

import { textsMainPage } from '../mainContent/config'
import { translation } from '../../utils/translation'

import { 
    SVolumeModal,
    SH2,
    SPercent,
    SDotsWrap,
    InputVolume
} from './style'
import { SCloseModalBtn } from '../newGameModal/style'

export const VolumeModal = ({game, volumeModalState} : {game: boolean, volumeModalState: boolean}) => {

    const dispatch = useDispatch()

    const { translationStateGamePage } = useTypedSelector(state => state.translationStateGamePage)
    const { translationStateMainPage } = useTypedSelector(state => state.translationStateMainPage)

    const volume = localStorage.getItem('volumeState')

    const [volumeState, setVolumeState] = useState(JSON.parse(volume!))

    const modalRef = useRef<HTMLDivElement>(null)

    const changeVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const volume = (e.target as HTMLInputElement).value
        setVolumeState(volume)
        if (volume) localStorage.setItem('volumeState', JSON.stringify(volume))
    }
    
    useEffect(() => {
        const closeModalEmptyArea = (event: MouseEvent) => {
            if (!modalRef.current) return
            const target = event.target as HTMLElement;

            if (!modalRef.current.contains(target) && modalRef.current && target.id !== 'volumeButton') {
                closeModal()
            }
        }
    
        document.addEventListener('click', closeModalEmptyArea)
    
        return () => {
            document.removeEventListener('click', closeModalEmptyArea)
        }
    }, [])

    const closeModal = () => {
        dispatch(closeVolumeModal())
    }

    return (
        <SVolumeModal
            ref={modalRef}
            game={game}
            volumeModalState={volumeModalState}
        >
            <SCloseModalBtn
                onClick={closeModal}
                width={'4vh'}
                height={'4vh'}
            >
                <span/>
                <span/>
            </SCloseModalBtn>
            <SH2>
                {translation('Общая громкость', translationStateMainPage, textsMainPage)}
            </SH2>
            <hr/>
            <SPercent>
                <span>
                    {volumeState}%
                </span>
                <span>
                    100%
                </span>
            </SPercent>
            <SDotsWrap>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
            </SDotsWrap>
            <InputVolume
                value={volumeState}
                type="range" 
                min="0" 
                max="100" 
                step="10"
                onChange={(e) => changeVolume(e)}
            />
        </SVolumeModal>
    )
}