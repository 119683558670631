import { ReadyToPlayModalActionType, ReadyToPlayModalState, ReadyToPlayModalAction } from '../../types/readyToPlayModalType'

const initialState: ReadyToPlayModalState = {
    readyToPlayModalState: false,
    openModalLobbyId: 0,
    lobbyName: ""
}

export const readyToPlayModalReducer = (state = initialState, action: ReadyToPlayModalAction): ReadyToPlayModalState => {

    switch(action.type) {
        case ReadyToPlayModalActionType.OPEN_READY_TO_PLAY_MODAL:
            return {
                ...state,
                readyToPlayModalState: true,
                openModalLobbyId: action.payload,
                lobbyName: action.lobbyName
            }
        case ReadyToPlayModalActionType.CLOSE_READY_TO_PLAY_MODAL:
            return {
                ...state,
                readyToPlayModalState: false
            }
        default:
            return state
    }   
}
