import { ActiveChatUserBtnActionType, ActiveChatUserBtnState, ActiveChatUserBtnAction } from '../../types/activeChatUserBtnType'

const initialState: ActiveChatUserBtnState = {
    activeChatUserBtnState: 0
}

export const activeChatUserBtnReducer = (state = initialState, action: ActiveChatUserBtnAction): ActiveChatUserBtnState => {
    switch(action.type) {
        case ActiveChatUserBtnActionType.SET_ACTIVE_USER_CHAT_BTN:
            return {
                ...state,
                activeChatUserBtnState: action.payload
            }
        default: 
            return state
    }
}