import styled from 'styled-components'

import { device } from '../../theme'

export const SHeader = styled.header<{rating: boolean, game: boolean}>`
    position: fixed;
    display: flex;
    justify-content: ${({rating, game}) => rating || game ? 'space-between' : 'end'};
    align-items: center;
    height: 6vh;
    width: calc(100vw - (100vw - 100%));
    background: 
        linear-gradient(rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
        #1A1A1A;
    box-shadow: 0px 0.4vh 2vh 0px rgba(0, 0, 0, 0.70);
    z-index: 30;
    padding: 0 2.5vw;
`

export const SBGLogo = styled.a`
    position: absolute;
    top: 0;
    left: 50%;
    width: 20vw;
    min-width: 120px;
    height: 11vh;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 57.72%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 54.29%, rgba(0, 0, 0, 0.40) 109.72%), 
        #1A1A1A;
    border-radius: 0 0 2vh 2vh;
    transform: translateX(-50%) perspective(10vh) rotateX(-35deg);
    z-index: 1;
`

export const SLogo = styled.span`
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-family: Open Sans;
    font-size: 2vmin;
    z-index: 2;
    height: 8vh;
    @media (${device.tablet}) {
        font-size: 3vmin;
    }
`

export const SNavUl = styled.ul`
    display: flex;
    gap: 2vh;
    z-index: 1;
    @media (${device.tablet}) {
        width: 100%;
        flex-direction: column;
        align-items: start;
        padding: 2vh 0 2vh 2vh;
    }
`

export const SRatingWrap = styled.div`
    display: flex;
    gap: 2vh;
    @media (${device.tablet}) {
        position: absolute;
        display: fixed;
        top: 10.5vh;
        left: -26vh;
        width: 26vh;
        border-radius: 0 1vh 1vh 0;
        padding: 0 1vh 1vh 1vh;
        background: 
            linear-gradient(to right, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
            linear-gradient(to right, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
            #1A1A1A;
        transition: 0.5s all;

        &.active {
            left: 0;
        }
    }
`

export const SRating = styled.button`
    position: relative;
    height: 3.5vh;
    color: #FFF;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    font-family: Open Sans;
    font-size: 1.8vmin;
    font-weight: 700;
    background: #1A1A1A;
    box-shadow: 0px 0px 5vh 0px rgba(0, 0, 0, 0.70) inset, 0px 0.4vh 1vh 0px rgba(0, 0, 0, 0.50);
    border-radius: 1vh;
    padding: 0 2vh;
    transform-style: preserve-3d;

    &::before {
        content: '';
        left: -2px;
        top: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        position: absolute;
        background: 
            linear-gradient( rgba(255, 154, 61, 0.17), rgba(255, 154, 61, 1)), 
            linear-gradient(360deg, rgba(0, 0, 0, 0.81), rgba(0, 0, 0, 0)), 
            linear-gradient(rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0));
            transform: translateZ(-1px);
        border-radius: inherit;
    }

    @media (${device.tablet}) {
        height: 3vh;
        width: 24vh;
        margin-top: 1vh;
        font-size: 10px;
        &::before {
            top: -1px;
            left: -1px;
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }
`

export const SLi = styled.li<{bigSize: boolean | undefined}>`
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(2px);
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 88%;
        height: 88%;
        border-radius: ${({bigSize}) => bigSize ? null : '1vh'};
        background: ${({bigSize}) => bigSize ? null : '#D9D9D9'};
        box-shadow: 0px 0px 2vh 0px rgba(0, 0, 0, 0.60), 0px 0.4vh 0px 0px #000;
        transform: translateZ(-1px);
    }
`

export const SIcon = styled.img<{bigSize: boolean | undefined}>`
    border-radius: ${({bigSize}) => bigSize ? null : '1vh'};
    height: ${({bigSize}) => bigSize ? '2vh' : null};
`

export const SIconExit = styled.img`
    display: none;
    border-radius: 1vh;
    background: #FBB;

    @media (${device.tablet}) {
        display: block;
    }
`

export const SNavMenuButton = styled.button<{ red: boolean | undefined, bigSize: boolean | undefined }>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: ${({bigSize}) => bigSize ? '6vw' : '3.4vh'};
    min-width: ${({bigSize}) => bigSize ? '11vh' : '3.4vh'};
    height: 3.4vh;
    border-radius: 1vh;
    border: 2px solid linear-gradient(#e66465, #9198e5);
    background-color: ${({red, bigSize}) => bigSize ? (red ? '#361414' : '#242222') : null };
    color: ${({red}) => red ? '#FBB' : '#D9D9D9' };
    font-size: 2vmin;
    transform-style: preserve-3d;
    transform: translateZ(1px);

    &::after {
        content: '';
        position: absolute;
        display: none;
        width: ${({bigSize}) => bigSize ? 'calc(100% + 4px)' : 'calc(3.4vh + 4px)'};
        min-width: ${({bigSize}) => bigSize ? '11vh' : '3.4vh'};
        height: calc(100% + 4px);
        background: rgba(255, 255, 255, 0.2);
        border-radius: inherit;
        transform: translateZ(1px);
    }

    &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: ${({red}) => red 
            ? 'linear-gradient(rgba(255, 144, 144, 100), rgba(64, 25, 25, 100))' 
            : 'linear-gradient(rgba(255, 255, 255, 100), rgba(255, 253, 252, 0))'};
        border-radius: inherit;
        box-shadow: 0px 0px 2vh 0px rgba(0, 0, 0, 0.60), 0px 0.4vh 0px 0px #000;
        transform: translateZ(-2px);
    }

    &:hover {
        color: #FF9900;

        >img {
            background: ${({bigSize}) => bigSize ? null : '#FF9900'};
        }

        &::before {
            background: 
                linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 253, 252, 0)), 
                linear-gradient(rgba(255, 153, 0, 1), rgba(255, 153, 0, 0.24));
        }
    }

    &:focus {
        color: #D9D9D9;
        background-color: ${({red, bigSize}) => bigSize ? (red ? '#5E4343' : '#504E4E') : null };
        
        >img {
            background: ${({bigSize}) => bigSize ? null : '#D9D9D9'};
        }
        
        &::after {
            display: block;
        }
        
        &::before {
            background: ${({red}) => red 
                ? 'linear-gradient(rgba(255, 144, 144, 100), rgba(64, 25, 25, 100))' 
                : 'linear-gradient(rgba(255, 255, 255, 100), rgba(255, 253, 252, 0))'};
            transform: translateZ(-2px);
        }
    }

    @media (${device.tablet}) {
        width: 3.4vh;
        min-width: 0;
        > span {
            display: none;
        }

        &::before {
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }

        &::after {
            min-width: 0;
        }
    }
`

export const SNav = styled.nav`
    @media (${device.tablet}) {
        position: absolute;
        display: fixed;
        top: 10.3vh;
        right: -16vh;
        width: 8vh;
        border-radius: 1vh 0 0 1vh;
        background: 
            linear-gradient(to right, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
            linear-gradient(to right, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
            #1A1A1A;
        transition: 0.5s all;

        &.active {
            right: 0;
        }
    }
`

export const SHambuger = styled.button`
    display: none;
    @media (${device.tablet}) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.4vh;
        height: 3.4vh;
        /* background-color: #242222; */
        border-radius: 1vh;
        transform-style: preserve-3d;
        transform: translateZ(1px);
        transition: 0.5s all;

        /* &::before {
            content: '';
            position: absolute;
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            background: linear-gradient(rgba(255, 255, 255, 100), rgba(255, 253, 252, 0));
            border-radius: inherit;
            box-shadow: 0px 0px 2vh 0px rgba(0, 0, 0, 0.60), 0px 0.4vh 0px 0px #000;
            transform: translateZ(-2px);
        } */
        
        >span,
        span::before,
        span::after {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background-color: #D9D9D9;
            transition: 0.3s all;
        }

        > span::before {
            content: '';
            top: -5px;
        }
        > span::after {
            content: '';
            top: 5px;
        }

        &.active {
            > span {
                transform: rotate(-45deg);
            }
            > span::before {
                top: 0;
                transform: rotate(0deg);
            }
            > span::after {
                top: 0;
                transform: rotate(90deg);
            }
        }
    }
`

export const SStatisticsBtn = styled.button<{authorizationPage?: boolean}>`
    display: none;
    @media (${device.tablet}) {
        position: relative;
        display: ${({authorizationPage}) => authorizationPage ? 'none' : 'flex'};
        justify-content: center;
        align-items: center;
        width: 3.4vh;
        height: 2vh;
        transition: 0.5s all;

        > span,
        span::before,
        span::after {
            display: block;
            position: absolute;
            bottom: 0;
            height: 90%;
            width: 3px;
            background-color: #D9D9D9;
            transition: 0.3s all;
        }

        > span::before {
            content: '';
            left: -6px;
            height: 60%;
        }

        > span::after {
            content: '';
            left: 6px;
            height: 85%;
        }

        &.active {
            > span {
                height: 100%;
            }
        }
    }
`