import { CustomTooltipActionType, CustomTooltipState, CustomTooltipAction } from '../../types/customTooltipType'

const initialState: CustomTooltipState = {
    customTooltipState: null
}

export const customTooltipReducer = (state = initialState, action: CustomTooltipAction): CustomTooltipState => {
    switch(action.type) {
        case CustomTooltipActionType.SET_STATE_CUSTOM_TOOLTIP:
            return {
                ...state,
                customTooltipState: action.payload
            }
        case CustomTooltipActionType.CLOSE_CUSTOM_TOOLTIP:
            return {
                ...state,
                customTooltipState: null
            }
        default:
            return state
    }   
}