import { navButtonsConfigType } from '../../types/navButtonsConfigType'

import userIcon from '../../assets/icons/user.svg'
import settingsIcon from '../../assets/icons/settings.svg'
import soundIcon from '../../assets/icons/sound.svg'
import fullScreenIcon from '../../assets/icons/full_screen.svg'
import infoIcon from '../../assets/icons/info.svg'

export const navButtonsConfig: navButtonsConfigType[]  = [
    {
        id: 'userButton',
        iconBtn: userIcon
    },
    {
        id: 'settingsButton',
        iconBtn: settingsIcon
    },
    {
        id: 'volumeButton',
        iconBtn: soundIcon
    },
    {
        id: 'fullScreenButton',
        iconBtn: fullScreenIcon
    },
    {
        id: 'infoButton',
        iconBtn: infoIcon
    },
    {
        id: 'languageButton',
        bigSize: true
    },
    {
        id: 'entryButton',
        red: true,
        bigSize: true
    }
]

export const navButtonsConfigGameFieldPage: navButtonsConfigType[] = [
    {
        id: 'languageButton',
        bigSize: true
    },
    {
        id: 'infoButton',
        iconBtn: infoIcon
    },
    {
        id: 'userButton',
        iconBtn: userIcon
    },
    {
        id: 'settingsButton',
        iconBtn: settingsIcon
    },
    {
        id: 'volumeButton',
        iconBtn: soundIcon
    },
    {
        id: 'fullScreenButton',
        iconBtn: fullScreenIcon
    }
]

export const navButtonsConfigAuthorizationPage: navButtonsConfigType[] = [
    {
        id: 'fullScreenButton',
        iconBtn: fullScreenIcon
    },
    {
        id: 'languageButton',
        bigSize: true
    }
]