import { LanguageActionType, LanguageState, LanguageAction } from '../../types/languguageReduserType'

const savedLanguageState = localStorage.getItem('languageState')

const initialState: LanguageState = {
    languageState: JSON.parse(savedLanguageState!)
}

export const languageReducer = (state = initialState, action: LanguageAction): LanguageState => {
    switch(action.type) {
        case LanguageActionType.CHANGE_VALUE_LANGUAGE:
            localStorage.setItem('languageState', JSON.stringify(action.payload))
            return {
                ...state,
                languageState: action.payload
            }
        default:
            return state
    }   
}