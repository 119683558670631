import styled from 'styled-components'

export const SContentWrap = styled.div`
   width: 100%;
   padding: 3vh 3vh 0 3vh;
`

export const SSeconds = styled.span`
   color: #FF0C0C !important;
   font-size: 1.8vmin;
`