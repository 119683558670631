import { YouWinModalActionType, YouWinModalState, YouWinModalAction} from '../../types/youWinModalType'

const initialState: YouWinModalState = {
    youWinModalState: false,
    youWinModalData: {}
}

export const youWinModalReducer = (state = initialState, action: YouWinModalAction): YouWinModalState => {
    switch(action.type) {
        case YouWinModalActionType.OPEN_YOU_WIN_MODAL:
            return {
                ...state,
                youWinModalState: true,
                youWinModalData: action.data
            }
        case YouWinModalActionType.CLOSE_YOU_WIN_MODAL:
            return {
                ...state,
                youWinModalState: false,
                youWinModalData: action.data
            }
        default:
            return state
    }   
}
