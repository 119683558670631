export const colors: string[][] = [
    [
        '#613A24',
        '#3F2415',
        '#301B0E',
        ''
        /* Цвет коричневого чата */
    ],
    [
        '#273DFF',
        '#1820E1',
        '#1A00B8',
        '#969AFF'
        /* 1 user синий */
    ],
    [
        '#D71010',
        '#970606',
        '#6B0000',
        '#FF9696'
        /* 2 user красный */
    ],
    [
        '#50D710',
        '#579706',
        '#496B00',
        '#BEFF97'
        /* 3 user зеленый */
    ],
    [
        '#FD891A',
        '#DE6C20',
        '#DE521E',
        '#FF9A3D'
        /* 4 user оранжевый */
    ],
    [
        '#7F10D7',
        '#4E0697',
        '#43006B',
        '#9D7AFF'
        /* 5 user фиолетовый */
    ]
]

export const textsGamePage: string[] = [
    /* 0 */'Время в игре',
    /* 1 */'Ход',
    /* 2 */'Ставка',
    /* 3 */'Зрители',
    /* 4 */'ЯЗЫК',
    /* 5 */'Заключить сделку',
    /* 6 */'Менеджер',
    /* 7 */'ВЫХОД',
    /* 8 */'СДАТЬСЯ',
    /* 9 */'БРОСИТЬ КУБИКИ',
    /* 10 */'КУПИТЬ',
    /* 11 */'ПОСТРОИТЬ',
    /* 12 */'ПРОДАТЬ',
    /* 13 */'ЗАЛОЖИТЬ',
    /* 14 */'ОПЛАТИТЬ',
    /* 15 */'АУКЦИОН',
    /* 16 */'ОТКАЗАТЬСЯ',
    /* 17 */'ВЫКУПИТЬ',
    /* 18 */'ВЗЯТЬ КРЕДИТ',
    /* 19 */'Ошибка',
    /* 20 */'Не удалось перевести страницу',
    /* 21 */'Новичок',
    /* 22 */'Менеджер',
    /* 23 */'Бизнесмен',
    /* 24 */'Коммерсант',
    /* 25 */'Банкир',
    /* 26 */'Гранд',
    /* 27 */'Богач',
    /* 28 */'Капиталист',
    /* 29 */'Олигарх',
    /* 30 */'Инвестор',
    /* 31 */'Монополист',
    /* 32 */'Элита',
    /* 33 */'Эксперт',
    /* 34 */'Магнат',
    /* 35 */'Президент',
    /* 36 */'Недоступный',
    /* 37 */'Легенда',
    /* 38 */'ГОТОВО',
    /* 39 */'ОТМЕНА',
    /* 40 */'Пользователь',
    /* 41 */'выставил на аукцион',
    /* 42 */'Текущая цена',
    /* 43 */'Цена после подтверждения увеличится на',
    /* 44 */'Автоматический отказ через',
    /* 45 */'секунд',
    /* 46 */'УЧАСТВОВАТЬ',
    /* 47 */'ОТКАЗАТЬСЯ',
    /* 48 */'НЕ ХВАТАЕТ ДЕНЕГ',
    /* 49 */'У вас не хватает денег для совершения действия',
    /* 50 */'Для получения средств Вы можете заложить фирму, продать филиалы или взять кредит',
    /* 51 */'ОК',
    /* 52 */'ЗАБРАТЬ',
    /* 53 */'УВЕЛИЧИТЬ НАГРАДУ',
    /* 54 */'ПОЗДРАВЛЯЕМ',
    /* 55 */'С ПОБЕДОЙ',
    /* 56 */'ВЫ ПРОИГРАЛИ',
    /* 57 */'Сумма активов',
    /* 58 */'ЗАКЛЮЧАЕМ СДЕЛКУ',
    /* 59 */'Вам доплатят',
    /* 60 */'Вы доплатите',
    /* 61 */'ПОДПИСАТЬ',
    /* 62 */'ОТМЕНИТЬ',
    /* 63 */'Вы уверены, что хотите сдаться',
    /* 64 */'ДА',
    /* 65 */'НЕТ',
    /* 66 */'Тестеровщик'
]