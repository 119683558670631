import { ActiveTabUserListActionType, ActiveTabUserListState, ActiveTabUserListAction } from '../../types/activeTabUserList'

const initialState: ActiveTabUserListState = {
    activeTabUserListState: 'allUsers'
}

export const activeTabUserListReducer = (state = initialState, action: ActiveTabUserListAction): ActiveTabUserListState => {
    switch(action.type) {
        case ActiveTabUserListActionType.SET_ACTIVE_TAB_USER_LIST:
            return {
                ...state,
                activeTabUserListState: action.payload
            }
        default: 
            return state
    }
}