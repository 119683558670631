import { useMemo } from 'react'

import { socket } from '../../sockets/lobbie/createConnectionSocket'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { openReadyToPlayModal } from '../../store/actionCreators/readyToPlayModal'
import { setActiveTabGameList } from '../../store/actionCreators/activeTabGameList'
import { openDevModal } from '../../store/actionCreators/devModal'

import { colors } from './config'
import { gameListButtonsConfig } from './config'
import { textsMainPage } from '../mainContent/config'
import { translation } from '../../utils/translation'

import noDealsImg from '../../assets/icons/no_deals.svg'
import noVipCardImg from '../../assets/icons/no_vip_card.svg'
import closedImg from '../../assets/icons/closed.svg'
import noAvatar from '../../assets/img/no_avatar.png'

import {
    SLobbiesList,
    SButtonWrap, 
    SListButton,
    SFilterWrap,
    SFilter,
    SLobbiesListContentWrap,
    SGameLobby,
    SLi,
    SImg,
    SButtonPlus,
    SLobbieButton,
    SClosed,
    SGameListWrap
} from './style'

import { gameListConfigType } from '../../types/gameListConfigType'

export const LobbiesList = () => {

    const dispatch = useDispatch()

    const { lobbiesListState } = useTypedSelector(state => state.lobbiesReducer)
    const { activeTabGameListState } = useTypedSelector(state => state.activeTabGameListState)
    const { translationStateMainPage } = useTypedSelector(state => state.translationStateMainPage)

    //We are search user in this lobby
    const isUserInLobby = (userId : number, lobby : any) => {
        let isFound : boolean = false
        lobby.players.map((item : any) => {  /* Тут лучше использовать find()? */
            if (item.userId === userId)
                isFound = true
        })
        return isFound;
    }

    let gameListArr : gameListConfigType[] = []
    if (lobbiesListState && lobbiesListState.length) {
        gameListArr = []
        lobbiesListState.map((item : any) => { /* Тут лучше forEach? */
            let status : string = 'goOut', text = translation('ВЫЙТИ', translationStateMainPage, textsMainPage)

            //let userInLobby
            if (
                Number(localStorage.getItem("userId")) !== item.creator.userId &&
                !isUserInLobby(Number(localStorage.getItem("userId")), item)
            ) {
                status = 'play'
                text = translation('ИГРАТЬ', translationStateMainPage, textsMainPage)
            }

            if (item.started) {
                status = 'look'
                text = translation('СМОТРЕТЬ', translationStateMainPage, textsMainPage)
            }

            gameListArr.push({
                gameId: item.id,
                nameGame: item.name,
                gamers: {
                    type: 'everyManForHimself',
                    teams: 1,
                    gamersValue: 2
                },
                players: item.players,
                field: '',
                rates: 0,
                status: status,
                text: text
            })
        })
    }

    const buttonEvent = (gameId : number | undefined, status : string | undefined, lobbyName : string) => {
        switch (status) {
            case "play":
                dispatch(openReadyToPlayModal(gameId, lobbyName))
                //socket.emit("lobby.join", { "lobbyId": gameId });
                break;
            case "goOut":
                    socket.emit("lobby.leave", {}); //We leave lobby

                    socket.emit("lobby.get.all", {}); //Call new list
                break;
            default:
                //TODO create some notificate
                break;
        }
    };

    const changeActiveTabGameList = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        const id = (e.target as HTMLDivElement).id
        if (id) {
            dispatch(setActiveTabGameList(id))
            switch(id) {
                case 'gameList':
                    return 
                case 'currentGames':
                    return
                case 'tournaments':
                    return dispatch(openDevModal())
                case 'clans':
                    return dispatch(openDevModal())
            }
        }
    }

    const imageOnErrorHandler = (
        event: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
        event.currentTarget.src = noAvatar
    }

    const gameList = useMemo(() => {
        return gameListArr.map(({nameGame, gameId, gamers, players, field, rates, noDeals, vipCard, status, text}) => {
        
            const noDealsContent = noDeals 
                ? <SImg 
                    src={noDealsImg} 
                    alt='noDeals'
                  />
                : <span 
                    style={{'width': '2vw'}}
                  />

            const vipCardContent = vipCard 
                ? <SImg
                    src={noVipCardImg}
                    alt='noVipCard'
                  />
                : <span 
                    style={{'width': '4vw'}}
                  />

            const btnImgContent = status === 'closed' 
                ? <SClosed 
                    src={closedImg} 
                    alt='closed'
                  />
                : null

            const btn = status 
                ? <SLobbieButton 
                    status={status}
                  >
                    {btnImgContent}
                    {text}
                  </SLobbieButton>
                : null

            let users = []

            for (let i = 0; i < gamers.gamersValue; i++) {
                const playerData = players[i];

                const data = playerData 
                    ? <SButtonPlus
                        colors={colors[i]}
                      >
                        <img
                            src={playerData?.avatarUri ? playerData?.avatarUri : noAvatar}
                            alt='avatar'
                            onError={imageOnErrorHandler}
                        />
                      </SButtonPlus>
                    : <SButtonPlus colors={colors[i]}>
                        <span/>
                        <span/>
                      </SButtonPlus>
                users.push(data)
            }

            return (
                <SGameLobby
                    key={nameGame}
                >
                    <SLi 
                        width='16.15vw'
                        justifyContent='start'
                    >
                        {nameGame}
                    </SLi>
                    <SLi
                        width='16.15vw'
                    >
                        {users}
                    </SLi>
                    <SLi
                        width='8vw'
                    >
                        {field}
                    </SLi>
                    <SLi
                        width='8vw'
                    >
                        {rates}
                    </SLi>
                    <SLi
                        width='8vw'
                    >
                        {noDealsContent}
                        {vipCardContent}
                    </SLi>
                    <SLi
                        width='10vw'
                        onClick={() => buttonEvent(gameId, status, nameGame)}
                    >
                        {btn}
                    </SLi>
                </SGameLobby>
            )
        })
    }, [lobbiesListState])

    const gameListButtons = useMemo(() => {
        return gameListButtonsConfig.map(({id, valueCon}) => {

            const value = (id: string) => {
                switch(id) {
                    case 'gameList':
                        return gameList.length
                    case 'currentGames':
                        return lobbiesListState.length
                    default:
                        return '0'
                }
            }

            const text = (id: string) => {
                switch(id) {
                    case 'gameList':
                        return translation('СПИСОК ИГР', translationStateMainPage, textsMainPage)
                    case 'currentGames':
                        return translation('ТЕКУЩИЕ ИГРЫ', translationStateMainPage, textsMainPage)
                    case 'tournaments':
                        return translation('ТУРНИРЫ', translationStateMainPage, textsMainPage)
                    case 'clans':
                        return translation('КЛАНЫ', translationStateMainPage, textsMainPage)
                    default:
                        return null
                }
            }

            const valueContent = valueCon 
                ?   <span>
                        <span style={{'marginLeft': '4px'}}>
                            (
                        </span>
                        <span style={{'color': '#FA9E14'}}>
                            {value(id)}
                        </span>
                        <span>
                            )
                        </span>
                    </span>
                : null

            return (
                <SListButton
                    key={id}
                    id={id}
                    className={id === activeTabGameListState ? 'active' : ''}
                >
                    <span>
                        {text(id)}
                        {valueContent}
                    </span>
                </SListButton>
            )
        })
    }, [activeTabGameListState, gameList.length, lobbiesListState.length, translationStateMainPage])

    const filterContent = useMemo(() => {
        return [
            {name: translation('Название игры', translationStateMainPage, textsMainPage), width: '16.15vw'},
            {name: translation('Игроки', translationStateMainPage, textsMainPage), width: '16.15vw'},
            {name: translation('Поле', translationStateMainPage, textsMainPage), width: '8vw'},
            {name: translation('Ставки', translationStateMainPage, textsMainPage), width: '8vw'},
            {name: translation('Вип-карта', translationStateMainPage, textsMainPage), width: '8vw'}
        ].map(({name, width}, i) => {
            return (
                <SFilter
                    key={i}
                    width={width}
                >
                    {name}
                </SFilter>
            )
        })
    }, [translationStateMainPage])

    return (
        <SLobbiesList>
            <SButtonWrap 
                onClick={changeActiveTabGameList}
            >
                {gameListButtons}
            </SButtonWrap>
            <SGameListWrap>
                <SFilterWrap>
                    {filterContent}
                </SFilterWrap>
                <SLobbiesListContentWrap>
                    {gameList}
                </SLobbiesListContentWrap>
            </SGameListWrap>
        </SLobbiesList>
    )
}
