import russia from '../../assets/icons/russia.svg'
import english from '../../assets/icons/english.svg'
import chinese from '../../assets/icons/chinese.svg'
import indian from '../../assets/icons/indian.svg'
import ukrainian from '../../assets/icons/ukrainian.svg'
import german from '../../assets/icons/german.svg'
import french from '../../assets/icons/french.svg'
import japanese from '../../assets/icons/japanese.svg'
import korean from '../../assets/icons/korean.svg'
import arab from '../../assets/icons/arab.svg'
import spanish from '../../assets/icons/spanish.svg'
import portuguese from '../../assets/icons/portuguese.svg'
import turkish from '../../assets/icons/turkish.svg'
import italian from '../../assets/icons/italian.svg'
import thai from '../../assets/icons/thai.svg'

export const languageModalConfig = [
    {
        text: 'Русский',
        icon: russia,
        id: 'RU'
    },
    {
    text: 'Английский',
        icon: english,
        id: 'EN'
    },
    {
        text: 'Китайский',
        icon: chinese,
        id: 'ZH'
    },
    {
        text: 'Индийский',
        icon: indian,
        id: 'GU'
    },
    {
        text: 'Украинский',
        icon: ukrainian,
        id: 'UA'
    },
    {
        text: 'Немецкий',
        icon: german,
        id: 'DE'
    },
    {
        text: 'Французский',
        icon: french,
        id: 'FR'
    },
    {
        text: 'Японский',
        icon: japanese,
        id: 'JA'
    },
    {
        text: 'Корейский',
        icon: korean,
        id: 'KO'
    },
    {
        text: 'Арабский',
        icon: arab,
        id: 'AR'
    },
    {
        text: 'Испанский',
        icon: spanish,
        id: 'ES'
    },
    {
        text: 'Португальский',
        icon: portuguese,
        id: 'PT'
    },
    {
        text: 'Турецкий',
        icon: turkish,
        id: 'TR'
    },
    {
        text: 'Итальянский',
        icon: italian,
        id: 'IT'
    },
    {
        text: 'Тайский',
        icon: thai,
        id: 'TH'
    }
]