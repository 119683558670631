import { useTypedSelector } from '../../hooks/useTypedSelector'

import {
    CustomTooltipWrap,
    CustomTooltip
} from '../customTooltip'

import {
    SAuthLayoutSection,
    SSpanLeft,
    SSpanRight,
    SSpanBottom
} from './style'

export const AuthLayoutSection = ({ children, game }: { children: React.ReactNode, game: boolean }) => {

    const { customTooltipState } = useTypedSelector(state => state.customTooltipState)
    
    return (
        <SAuthLayoutSection
            game={game}
        >
            <CustomTooltipWrap>
                {customTooltipState ? <CustomTooltip/> : null}
            </CustomTooltipWrap>
            <SSpanLeft/>
            <SSpanRight/>
            <SSpanBottom/>
            {children}
        </SAuthLayoutSection>
    )
}