import {AuctionDataState, AuctionDataAction, AuctionDataActionType} from '../../types/auctionDataReduserType'

const initialState: AuctionDataState = {
    AuctionDataListState: {}
}

export const auctionDataReducer = (state = initialState, action: AuctionDataAction): AuctionDataState => {
    switch(action.type) {
        case AuctionDataActionType.SET_UP_AUCTION_DATA:
            return {
                ...state,
                AuctionDataListState: action.payload
            }
        default:
            return state
    }
}
