import styled, { css } from 'styled-components'

import thumb from '../../assets/icons/thumb.svg'

const mainPagePozition = css`
    right: 24vh;
    top: 7vh;
`

const gamePagePozition = css`
    top: 7vh;
`

export const SVolumeModal = styled.div<{game: boolean, volumeModalState: boolean}>`
    ${({game}) => game ? gamePagePozition : mainPagePozition}
    position: absolute;
    display: ${({volumeModalState}) => volumeModalState ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
        #1A1A1A;
    border-radius: 1vh;
    width: 28vh;
    padding: 0.5vh 1.5vh;
    gap: 0.5vh;

    >hr{  
        height: 0;
        width: 100%;
        border-top: 1px solid #000;
        border-bottom: 1px solid #D9D9D9;
        opacity: 0.2;
    }

    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(rgba(255, 255, 255, 100), rgba(255, 253, 252, 0));
        border-radius: inherit;
        transform: translateZ(-1px);
    }
`

export const SH2 = styled.h2`
    margin-top: 0.2vh;
    font-size: 2vmin;
    color: #FFF;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

export const SPercent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    >span{
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        opacity: 0.6;
    }
`

export const SDotsWrap = styled.div`
    width: 93%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    >span{
        width: 3px;
        height: 3px;
        border-radius: 3px;
        background: 
            linear-gradient(180deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%),
            #D9D9D9;
        opacity: 0.6;
    }
`

export const InputVolume = styled.input`
    width: 100%;
    -webkit-appearance: none;
    height: 1vh;
    border-radius: 1vh;
    margin-top: 0.5vh;
    margin-bottom: 2vh;

    &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        height: 1vh;
        background: rgba(0, 0, 0, 1);
        box-shadow: 
            0px 1px 0px #D9D9D9,
            1px 0px 0px #D9D9D9,
            -1px 0px 0px #D9D9D9,
            0px 0px 40px 0px rgba(0, 0, 0, 0.20) inset;
        border-radius: 1vh;
    }
    &::-moz-range-track {
        -webkit-appearance: none;
        height: 1vh;
        background: rgba(0, 0, 0, 1);
        box-shadow:
            0px 1px 0px #D9D9D9,
            1px 0px 0px #D9D9D9,
            -1px 0px 0px #D9D9D9,
            0px 0px 40px 0px rgba(0, 0, 0, 0.20) inset;
        border-radius: 1vh; 
    }

    &::-webkit-slider-thumb {
        margin-top: -0.5vh;
        -webkit-appearance: none;
        height: 3vh;
        width: 2vh;
        background: url(${thumb}) center center/cover no-repeat;
        cursor: ew-resize;
    }
    &::-moz-range-thumb {
        -webkit-appearance: none;
        height: 3vh;
        width: 2vh;
        background: url(${thumb}) center center/cover no-repeat;
        cursor: ew-resize;
        border: none;
    }
`