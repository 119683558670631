import styled, { keyframes } from 'styled-components'

const changeContent = keyframes`
    10% { content: "⠙"; }
    20% { content: "⠹"; }
    30% { content: "⠸"; }
    40% { content: "⠼"; }
    50% { content: "⠴"; }
    60% { content: "⠦"; }
    70% { content: "⠧"; }
    80% { content: "⠇"; }
    90% { content: "⠏"; }
`

export const SSpinner = styled.div<{fontSize: string, height: string}>`
    height: ${({height}) => height};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &::after {
        animation: ${changeContent} .8s linear infinite;
        display: block;
        content: "⠋";
        font-size: ${({fontSize}) => fontSize};
    }
`
