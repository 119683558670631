import {LobbieDataState, LobbieDataAction, LobbieDataActionType} from '../../types/lobbieDataReduserType'

const initialState: LobbieDataState = {
    lobbieDataListState: {}
}

export const lobbieDataReducer = (state = initialState, action: LobbieDataAction): LobbieDataState => {
    switch(action.type) {
        case LobbieDataActionType.SET_UP_LOBBIE_DATA:
            return {
                ...state,
                lobbieDataListState: action.payload
            }
        case LobbieDataActionType.SET_UP_TURNS:
            return {
                ...state,
                lobbieDataListState: {
                    ...state.lobbieDataListState,
                    totalTurns: action.payload
                }
            }
        default:
            return state
    }
}