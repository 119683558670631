import styled from 'styled-components'

export const SCustomTooltipWrap = styled.div`
    position: absolute;
    right: 2.5vw;
    top: 7vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 2vh;
    z-index: 100;
`

export const SCustomTooltip = styled.div<{status: string}>`
    width: 20vw;
    height: 15vh;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.70) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.50);
    border-radius: 1vh;
    padding: 2vh;
    background: ${({status}) => {
        switch(status) {
            case 'error':
                return 'linear-gradient(0deg, #D71010 0%, #970606 52.69%, #6B0000 100%)'
            case 'success':
                return 'linear-gradient(0deg, #50D710 0%, #579706 52.69%, #496B00 100%), linear-gradient(0deg, rgba(215, 16, 16, 0.00) 0%, rgba(151, 6, 6, 0.00) 52.69%, rgba(107, 0, 0, 0.00) 100%)'
            case 'notice':
                return 'linear-gradient(0deg, rgba(69, 25, 0, 0.18) 0%, rgba(69, 25, 0, 0.18) 100%), linear-gradient(0deg, #FFA329 0%, #FF8027 52.69%, #FF5A24 100%), linear-gradient(0deg, #D71010 0%, #970606 52.69%, #6B0000 100%)'
        }
    }};

    >h3 {
        font-size: 3vmin;
    }
    
    >p{
        font-size: 2vmin;
    }
`