export enum MenuButtonsActionType {
    SET_UP_BUTTONS = 'SET_UP_BUTTONS'
}

export interface MenuButtonsState {
    MenuButtonsListState: any
}

export interface MenuButtonsAction {
    type: string,
    payload?: any
}
