import {
    useState,
    useMemo,
    useEffect,
    useRef
} from 'react'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setActiveChatUserBtn } from '../../store/actionCreators/activeChatUserBtn'
import { openDevModal } from '../../store/actionCreators/devModal'

import {
    startListeningUserChatSocket,
    sendMessage,
    getMessage
} from '../../sockets/chat/createUserChatConnectionsSocket'

import { ChatInfoModal } from '../chatInfoModal'

import { textsMainPage } from '../mainContent/config'
import { translation } from '../../utils/translation'
import emoji from '../../assets/icons/emoji.svg'

import { 
    SChat,
    SSendMessageWrap,
    SInputWrap,
    SInput,
    SSendMessageBtn,
    SEmoji,
    SChatWindow,
    SButtonWrap,
    SChatButtonUserPage,
    SChatWrap,
    SChatMsg
} from './style'

export const ChatUser = () => {

    const dispatch = useDispatch()
    
    const { activeChatUserBtnState } = useTypedSelector(state => state.activeChatUserBtnState)
    const { chatUserState } = useTypedSelector(state => state.chatUserState)
    const { currentUserState } = useTypedSelector(state => state.currentUserState)
    const { translationStateMainPage } = useTypedSelector(state => state.translationStateMainPage)

    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        startListeningUserChatSocket()
        getMessage(dispatch)
    }, [dispatch])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }
    
    const handleSendMessage = () => {
        if (inputValue.trim() !== '') {
            sendMessage(currentUserState?.username, inputValue)
            setInputValue('')
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (inputValue.trim() !== '') {
                sendMessage(currentUserState?.username, inputValue)
                setInputValue('')
            }
        }
    }

    const mainPageGhatListContent = useMemo(() => {
        return [translation('ОБЩИЙ ЧАТ', translationStateMainPage, textsMainPage)]
            .map((item, i) => {
            return (
                <SChatButtonUserPage 
                    id={i.toString()}
                    key={i} 
                    className={i === activeChatUserBtnState ? 'active' : undefined}
                    newMsg={i === 1 ? true : false}
                    data-new-msg={'2'}
                >
                    <span style={{'marginTop': '-0.5vh'}}>{item}</span>
                </SChatButtonUserPage>
            )
        })
    }, [activeChatUserBtnState, translationStateMainPage])

    const _scrollDown = (el:any) => {
        if (el)
            el.scrollTop = el.scrollHeight
    }

    const renderMessages = useMemo(() => {
        return (
            <SChatWindow ref={el => _scrollDown(el)}>
                {chatUserState.map(({sender, text}: {sender: string, text: string}, i: number) => (
                    <SChatMsg 
                        key={i}
                    >
                        <span>{sender}</span> {text}
                    </SChatMsg>
                ))}
            </SChatWindow>
        )
    }, [chatUserState])

    return (
        <SChat>
            <SButtonWrap 
                onClick={(e) => {
                const target = e.target as HTMLButtonElement;
                const id = +target.id;
                dispatch(setActiveChatUserBtn(id))
            }}>  
                {mainPageGhatListContent}
            </SButtonWrap>
            <SChatWrap
                style={{'paddingTop': '1vh'}}
            >
                <ChatInfoModal/>
                {renderMessages}
                <SSendMessageWrap>
                    <SInputWrap>
                        <SInput 
                            type='text'
                            value={inputValue} 
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                        />
                    </SInputWrap>
                    <SEmoji
                        onClick={() => dispatch(openDevModal())}
                    >
                        <img src={emoji}/>
                    </SEmoji>
                    <SSendMessageBtn
                        width='13vh'
                        height='3.5vh'
                        onClick={handleSendMessage}
                    >
                        {translation('ОТПРАВИТЬ', translationStateMainPage, textsMainPage)}
                    </SSendMessageBtn>
                </SSendMessageWrap>
            </SChatWrap>
        </SChat>
    )
}