import {io} from "socket.io-client";
import {getUserLobbyDataSC} from "../lobbie/socketHelpCommands";
import {useDispatch} from 'react-redux';
import {addToChatlobby, setUpChatlobby} from "../../store/actionCreators/chatlobbyActionCreate";
import {useState} from "react";

export let socket2: any;
const ConnectChatSocket = async () => {

    console.log("CALLED CHAT CONNECT", window.location.href);

    //TODO change on Env
    socket2 = io('ws://78.24.222.80:6003', {});

   // const dispatch = useDispatch()

    socket2.on("connection", (ans: any) => {
        console.log("CONNECTED CHAT SUCCESS");
    });

    socket2.emit("chat:join:lobby", {
        userId: localStorage.getItem("userId"),
        lobbyName: localStorage.getItem("activeLobbieId")
    })

    const dispatch = useDispatch()
    const emptyState : string[] = [];

    socket2.on("message:get:lobby", (ans: any) => {
        let output: string[] = emptyState;

        if (ans.length > 0 && !ans.text) {
            output = [];
            ans.map((item: any) => {
                output.push(item.text);
            });
            dispatch(setUpChatlobby(output));
        } else {
            dispatch(addToChatlobby(ans.text))
        }
    });
}

export default ConnectChatSocket;
