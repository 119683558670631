import styled from 'styled-components'

export const SCheckboxWrap = styled.div<{marginTop?: string}>`
    display: flex;
    align-items: center;
    gap: 1vh;
    margin-top: ${({marginTop}) => marginTop ? marginTop : '1.5vh'};
`

export const SCheckbox = styled.div`
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 1.8vh;
    width: 1.8vh;
    cursor: pointer;
    background: #171717;
    transform-style: preserve-3d;
    cursor: pointer;
    
    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(180deg, #DCDCDC 0%, #737373 100%);
        border-radius: 2px;
        transform: translateZ(-2px);
    }
`

export const SCheckmark = styled.span<{checked: boolean, grey?: boolean}>`
    opacity: ${({checked}) => checked ? '1' : '0'};
    background: linear-gradient(180deg, #DCDCDC 0%, #737373 100%);
    top: 0;
    left: 0;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    cursor: pointer;
`