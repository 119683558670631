export enum ActiveChatUserBtnActionType {
    SET_ACTIVE_USER_CHAT_BTN = 'SET_ACTIVE_USER_CHAT_BTN'
}

export interface ActiveChatUserBtnState {
    activeChatUserBtnState: number
}

export interface ActiveChatUserBtnAction {
    type: string,
    payload: number
}