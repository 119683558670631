import styled from 'styled-components'

import { device } from '../../theme'

export const SMainContent = styled.div`
    display: grid;
    grid-template: 23vh 2vh 37vh 2vh 24vh/25vw 44vw 1vw 25vw;
    grid-template-areas: 
        'userCard . . menuUser'
        '. . . userList'
        'gameList gameList . userList'
        '. . . userList'
        'chat chat . userList';
    width: 100%;
    height: 100%;
    padding: 8vh 2.5vw 4vh 2.5vw;

    @media (${device.tablet}) {
        grid-template: 23vh 2vh 37vh 2vh 24vh/35vw 19vw 1vw 35vw;
        grid-template-areas: 
            'userCard . . menuUser'
            '. . . userList'
            'gameList gameList . userList'
            '. . . userList'
            'chat chat . userList';
        padding: 8vh 5vw 4vh 5vw;
    }

    @media (${device.mobileL}) {
        padding: 11vh 10vw 0 10vw;
        display: flex;
        flex-direction: column;
        gap: 5vh;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        ::-webkit-scrollbar {
            display: none;
        }
    }
`

export const SBlock = styled.div<{gridArea?: string, gamePage?: boolean, colors?: string[][], activeBtn?: number}>`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.7vh;
    background: #1A1A1A;
    transform-style: preserve-3d;
    grid-area: ${({gridArea}) => gridArea};
    
    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: ${({gamePage, colors, activeBtn}) => gamePage ? 
            (activeBtn ? 
                `linear-gradient(0deg, ${colors![activeBtn!][0]} 0%, ${colors![activeBtn!][1]} 52.69%, ${colors![activeBtn!][2]} 100%)` 
                    : 
                'linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1))')
                :
            'linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1))'};
        border-radius: inherit;
        transform: translateZ(-2px);
    }

    @media (${device.tablet}) {
        &::before {
            top: -1px;
            left: -1px;
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }
    
`