import { TranslationActionType, TranslationState, TranslationAction } from '../../types/translationType'

import { textsAuthPage } from '../../components/authorization/config'
import { textsGameGhatPage } from '../../components/chatGame/config'
import { textsGamePage } from '../../components/gameContent/config'
import { textsMainPage } from '../../components/mainContent/config'
import { textsPasswordRecoveryPage } from '../../components/passwordRecovery/config'
import { textsNotFoundPage } from '../../components/notFound/config'

const initialState: TranslationState = {
    translationStateAuthPage: textsAuthPage,
    translationStateGameChatPage: textsGameGhatPage,
    translationStateGamePage: textsGamePage,
    translationStateMainPage: textsMainPage,
    translationStateNotFoundPage: textsNotFoundPage,
    translationStatePasswordRecoveryPage: textsPasswordRecoveryPage
}

export const translationReducer = (state = initialState, action: TranslationAction): TranslationState => {
    switch(action.type) {
        case TranslationActionType.SET_UP_TRANSLATE_AUTH_PAGE:
            return {
                ...state,
                translationStateAuthPage: action.payload
            }
        case TranslationActionType.SET_UP_TRANSLATE_GAME_CHAT_PAGE:
            return {
                ...state,
                translationStateGameChatPage: action.payload
            }
        case TranslationActionType.SET_UP_TRANSLATE_GAME_PAGE:
            return {
                ...state,
                translationStateGamePage: action.payload
            }
        case TranslationActionType.SET_UP_TRANSLATE_MAIN_PAGE:
            return {
                ...state,
                translationStateMainPage: action.payload
            }
        case TranslationActionType.SET_UP_TRANSLATE_NOT_FOUND_PAGE:
            return {
                ...state,
                translationStateNotFoundPage: action.payload
            }
        case TranslationActionType.SET_UP_TRANSLATE_PASSWORD_RECOVERY_PAGE:
            return {
                ...state,
                translationStatePasswordRecoveryPage: action.payload
            }
        default:
            return state
    }
}