export enum ReadyToPlayModalActionType {
    OPEN_READY_TO_PLAY_MODAL = 'OPEN_READY_TO_PLAY_MODAL',
    CLOSE_READY_TO_PLAY_MODAL = 'CLOSE_READY_TO_PLAY_MODAL'
}

export interface ReadyToPlayModalState {
    readyToPlayModalState: boolean,
    openModalLobbyId: number | undefined,
    lobbyName: string | undefined
}

export interface ReadyToPlayModalAction {
    type: string,
    payload?: number,
    lobbyName?: string
}