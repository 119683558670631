export enum GiveUpModalActionType {
    OPEN_GIVE_UP_MODAL = 'OPEN_GIVE_UP_MODAL',
    CLOSE_GIVE_UP_MODAL = 'CLOSE_GIVE_UP_MODAL'
}

export interface GiveUpModalState {
    giveUpModalState: boolean
}

export interface GiveUpModalAction {
    type: string
}