import { MakeADealModalActionType } from '../../types/makeADealModalType'

export const closeMakeADealModal = () => {
    return {type: MakeADealModalActionType.CLOSE_MAKE_A_DEAL_MODAL, payload: {}}
}

export const openMakeADealModal = (data : any) => {
    return {type: MakeADealModalActionType.OPEN_MAKE_A_DEAL_MODAL, payload: data}
}

export const setUpDealDetails = (data : any) => {
    return {type: MakeADealModalActionType.SET_UP_DEAL_DETAILS, payload: data}
}