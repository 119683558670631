import styled from 'styled-components'

import { device } from '../../theme'

import scrollLines from '../../assets/icons/scroll_lines.svg'

export const ScrollWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 64vh;
    overflow: scroll;
    padding: 0 2vw 0 1vw;
    
    ::-webkit-scrollbar {
        width: 2vh;
        height: 100%;
        background: rgba(0, 0, 0, 0.30);
    }
    
    ::-webkit-scrollbar-thumb {
        background: url(${scrollLines}) center center/100% no-repeat, linear-gradient(90deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(270deg, #2D7500 0%, #276207 42.19%, #132E05 100%);
        border: 1px solid #8EFF59;
        border-radius: 2vh;
        min-height: 5vh;
    }
`

export const SH3 = styled.h3`
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    font-size: 2vmin;
    color: #FFC93D;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    
    @media (${device.mobileL}) {
        font-size: 10px;
    }
`

export const SP = styled.p`
    font-size: 2vmin;
    font-family: Philosopher;
    font-weight: 400;
    >span {
        font-weight: 700;
    }

    @media (${device.mobileL}) {
        font-size: 10px;
    }
`

export const SBlock = styled.div`
    position: relative;
    width: 70vw;
    height: 70vh;
    border-radius: 0.7vh;
    background: #1A1A1A;
    transform-style: preserve-3d;
    
    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1));
        border-radius: inherit;
        transform: translateZ(-2px);
    }

    @media (${device.tablet}) {
        &::before {
            top: -1px;
            left: -1px;
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }

    @media (${device.mobileL}) {
        min-width: 357px;
    }

    @media (${device.mobileM}) {
        min-width: 301px;
    }

    @media (${device.mobileS}) {
        min-width: 268px; 
    }
`