import { gameListButtonsConfigType } from '../../types/gameListButtonsConfigType'

export const gameListButtonsConfig: gameListButtonsConfigType[]  = [
    {
        id: 'gameList',
        valueCon: true
    },
    {
        id: 'currentGames',
        valueCon: true
    },
    {
        id: 'tournaments',
        valueCon: true
    },
    {
        id: 'clans',
    }

]

export const colors: string[][] = [
    [
        'rgba(0, 0, 0, 1), rgba(6, 31, 255, 1)'
    ],
    [
        'rgba(0, 0, 0, 1), rgba(255, 73, 73, 1)'
    ],
    [
        'rgba(0, 0, 0, 1), rgba(99, 73, 255, 1)'
    ],
    [
        'rgba(0, 0, 0, 1), rgba(255, 161, 73, 1)'
    ],
    [
        'rgba(0, 0, 0, 1), rgba(99, 255, 73, 1)'
    ]
]
