export enum AuctionModalActionType {
    OPEN_AUCTION_MODAL = 'OPEN_AUCTION_MODAL',
    CLOSE_AUCTION_MODAL = 'CLOSE_AUCTION_MODAL'
}

export interface AuctionModalState {
    auctionModalState: boolean
}

export interface AuctionModalAction {
    type: string
}