import styled, { css } from 'styled-components'

import { device } from '../../theme'

export const SLabel = styled.label<{marginTop?: string, fontSize?: string}>`
    margin-top: ${({marginTop}) => marginTop ? marginTop : '1vh'};
    display: inline-block;
    width: 100%;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.50);
    text-align: center;
    font-size: ${({fontSize}) => fontSize ? fontSize : null};

    @media (${device.mobileL}) {
        font-size: 10px;
        margin-top: 1.5vh;
    }
`

export const SInput = styled.input<{marginTop?: string}>`
    width: 100%;
    height: 4vh;
    margin-top: ${({marginTop}) => marginTop ? marginTop : '1vh'};
    padding: 0 2vh;
    border-radius: 1.2vh;
    background: 
        linear-gradient(180deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 75%), 
        linear-gradient(360deg, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0.00) 43.96%);
    color: #FFF;
    font-weight: 400;
    box-shadow: 
        0px 1px 0px rgba(255, 255, 255, 0.8), 
        1px 0px 0px rgba(255, 255, 255, 0.42), 
        -1px 0px 0px rgba(255, 255, 255, 0.42);

    &::placeholder {
        color: #FFF;
        font-weight: 400;
    }

    &.green {
        box-shadow: 
            0px 1px 0px rgba(56, 240, 75, 1), 
            1px 0px 0px rgba(56, 240, 75, 0.42), 
            -1px 0px 0px rgba(56, 240, 75, 0.42);
    }

    &.red {
        box-shadow: 
            0px 1px 0px rgba(255, 81, 81, 1), 
            1px 0px 0px rgba(255, 81, 81, 0.42), 
            -1px 0px 0px rgba(255, 81, 81, 0.42);
    }

    &:-webkit-autofill {
        /* Не работает с chrome*/
    }

    @media (${device.mobileL}) { 
        height: 3vh;
        font-size: 10px;
    }
`

export const SA = styled.a`
    font-size: 2vmin;
    color: #FFC93D;
    text-decoration: underline;
    margin-top: 1vh;
    @media (${device.mobileL}) { 
        margin-top: -1vh;
    }
`

export const SDarkgrayBox = styled.div`
    display: flex;
    padding: 0 3vh;
    flex-direction: column;
    align-items: center;
    margin-top: 1vh;
    width: 100%;
    height: 100%;
    background: #232323;
    border-radius: 0 0 1.4vh 1.4vh;

    @media (${device.mobileL}) {
        padding: 0 3vw;
    }
`

export const SBtn = styled.button<{ width: string }>`
    position: relative;
    margin-top: 1vh;
    width: ${({width}) => width}; 
    height: 4vh;
    color: #FFF;
    border-radius: 1vh;
    background: 
        linear-gradient(180deg, #06AE17 0%, #126B10 52.69%, #0B6209 100%), 
        linear-gradient(180deg, #353535 0%, #343434 52.69%, #282828 100%);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.50);
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.5);
    transform-style: preserve-3d;

    &::after {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border-radius: inherit;
        transform: translateZ(1px);
    }

    &::before {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        background: linear-gradient(rgba(29, 29, 29, 1), rgba(77, 77, 77, 1));
        border-radius: inherit;
        transform: translateZ(-1px);
        box-shadow: 0px -0.2vh 0.2vh rgba(125, 125, 125, 0.50);
    }

    &:hover {
        &::after {
            background: rgba(0, 0, 0, 0.2);
        }
    }

    &:focus {
        &::after {
            background: rgba(255, 255, 255, 0.2);
        }
    }

    @media (${device.mobileL}) { 
        height: 3vh;
        width: 100%;
    }
`

export const SP = styled.p`
    margin-top: 1vh;
    font-size: 2vmin;
    color: #FFC93D;
`

export const SLine = styled.span`
    margin-top: 1vh;
    height: 1px;
    width: 100%;
    background: black;
    opacity: 0.2;
    box-shadow: 0px 3px 0px #D9D9D9;
`

export const SGridBox = styled.div`
    margin-top: 2.5vh;
    display: grid;
    width: 100%;
    gap: 2vh;
    grid-template: 5vh 5vh 5vh / 1fr 1fr;
    grid-template-areas: 
        'guestAccountBtn guestAccountBtn'
        'googleBtn appleBtn'
        'facebookBtn yandexBtn'
    ;
    margin-bottom: 2vh;

    @media (${device.mobileL}) {
        grid-template: 3vh 3vh 3vh 3vh 3vh / 1fr;
        grid-template-areas: 
        'guestAccountBtn'
        'googleBtn'
        'appleBtn'
        'facebookBtn'
        'yandexBtn'
    ;
    }
`

const left = css`
    left: 1vw;
`

const right = css`
    right: 1vw;
`

export const SButton = styled.button<{ gridArea: string, green: boolean, odd: number, icon: string | undefined, entry: boolean}>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 4vh;
    background: linear-gradient(180deg, #353535 0%, #343434 52.69%, #282828 100%);
    border-radius: 1.2vh;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: ${({green}) => green ? '#53961D' : '#979797'};
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.50);
    transform-style: preserve-3d;
    transform: translateZ(1px);
    grid-area: ${({gridArea}) => gridArea};

    &::after {
        content: '';
        position: absolute;
        display: inline-block;
        width: 2.5vh;
        height: 2.5vh;
        background: ${({icon}) => `url(${icon})`} center center/cover no-repeat;
        
        ${({odd}) => odd ? left : right}
    }

    &::before {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        background: linear-gradient(rgba(29, 29, 29, 1), rgba(77, 77, 77, 1));
        border-radius: inherit;
        transform: translateZ(-2px);
        box-shadow: 0px -0.2vh 0.2vh rgba(125, 125, 125, 0.50);
    }

    @media (${device.mobileL}) {
        height: 3vh;
        font-size: 10px;
        &::after {
            height: 1.8vh;
            width: 1.8vh;
            ${({odd, entry}) => entry ? (odd ? left : left) : (odd ? left : right)}
        }
    }
`

export const SForm = styled.form`
    margin: 0 1.5vw;
    @media (${device.mobileL}) { 
        margin: 0 3vw;
    }
`

export const SReCaptchaWrap = styled.div`

    @media (${device.tablet}) { 
        transform: scale(0.9);
    }
    
    @media (${device.mobileL}) { 
        transform: scale(0.8);
    }

    @media (${device.mobileM}) { 
        transform: scale(0.7);
    }

    @media (${device.mobileS}) { 
        transform: scale(0.6);
    }
`