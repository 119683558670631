import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { closeYouLostModal } from '../../store/actionCreators/youLostModal'
import { closeYouWinModal } from '../../store/actionCreators/youWinModal'

import { textsGamePage } from '../gameContent/config'
import { translation } from '../../utils/translation'

import {
    SH2,
    SContentWrap
} from './style'
import { SBlackBgBlock } from '../youWinModal/style'
import { 
    SModalWrap,
    SGreyWrap
} from '../newGameModal/style'
import { SBtn } from '../readyToPlayModal/style'
import { SBlock } from '../devModal/style'

export const YouLostModal = () => {

    const dispatch = useDispatch()

    const { youLostModalState } = useTypedSelector(state => state.youLostModalState)
    const { translationStateGamePage } = useTypedSelector(state => state.translationStateGamePage)

    const closeModal = () => {
        dispatch(closeYouLostModal())
        window.location.href = "/user"
    }

    return (
        <SModalWrap
            /* padding={'30vh 38vw'} */
            showModal={youLostModalState}
        >
            <SBlock>
                <SContentWrap>
                    <SH2>
                        {translation('ВЫ ПРОИГРАЛИ', translationStateGamePage, textsGamePage)}
                    </SH2>
                    <span style={{'color' : '#FF0C0C', 'fontSize' : '11vmin'}}>
                        :(
                    </span>
                    <SBlackBgBlock marginTop={'3vh'}>
                        <span style={{'fontSize' : '2vmin'}}>
                            0 $
                        </span>
                        <span style={{'color' : '#FF0C0C', 'fontSize' : '2vmin'}}>
                            -0 кг.
                        </span>
                    </SBlackBgBlock>
                </SContentWrap>
                <SGreyWrap>
                    <SBtn
                        color='yellow'
                        onClick={() => closeModal()}
                        style={{'minWidth': '80px'}}
                    >
                        {translation('ОК', translationStateGamePage, textsGamePage)}
                    </SBtn>
                </SGreyWrap>
            </SBlock>
        </SModalWrap>
    )
}
