import {
    useState,
    useEffect,
    useMemo
} from 'react'

import { socket } from '../../sockets/lobbie/createConnectionSocket'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { closeAuctionModal } from '../../store/actionCreators/auctionModal'

import { translation } from '../../utils/translation'
import { textsGamePage } from '../../components/gameContent/config'

import {
    SContentWrap,
    SSeconds
} from './style'
import {
    SModalWrap,
    STitle,
    SH2,
    SCloseModalBtn,
    SGreyWrap
} from '../newGameModal/style'
import {
    SP,
    SBtn
} from '../readyToPlayModal/style'
import { SBlock } from '../devModal/style'

export const AuctionModal = () => {

    const dispatch = useDispatch()

    const { auctionModalState } = useTypedSelector(state => state.auctionModalState)
    const { AuctionDataListState }  = useTypedSelector(state => state.auctionDataReducer)
    const { translationStateGamePage } = useTypedSelector(state => state.translationStateGamePage)

    const [ remainingSeconds, setRemainingSeconds ] = useState(10) //TODO - wait data from Backend
    const [ user, setUser ] = useState('')
    const [ gameItem, setGameItem ] = useState('')
    const [ currentPrice, setCurrentPrice ] = useState(0)
    const [ priceAfterConfirmation, setPriceAfterConfirmation ] = useState(0)

    useEffect(() => {
        const countdown = setInterval(() => {
            setRemainingSeconds(prevSeconds => {
                if (prevSeconds === 0) {
                    clearInterval(countdown)
                    refuse()
                    return 0
                } else {
                    return prevSeconds - 1;
                }
            })
        }, 1000)
      
        return () => {
            clearInterval(countdown)
        }
    }, [])

    useMemo(() => {
        if (AuctionDataListState && AuctionDataListState.auction) {
            console.log ("Auction Data: ", AuctionDataListState)

            setUser(AuctionDataListState.auction.initiator.username)
            setGameItem(AuctionDataListState.auction.tile.name)
            setCurrentPrice(AuctionDataListState.auction.tile.monopoly.purchasePrice)
            setPriceAfterConfirmation(/*Number(AuctionDataListState.auction.amount) * 0.1*/ 20000)
        }
    }, [AuctionDataListState])

    const participate = () => {
        socket.emit("lobby.game.auction.participate", {})
    }

    const refuse = () => {
        socket.emit("lobby.game.auction.decline", {})
        dispatch(closeAuctionModal())
    }

    const closeByCross = () => {
        refuse()
    }

    return (
        <SModalWrap
            /* padding={'33vh 40.5vw'} */
            showModal={auctionModalState}
        >
            <SBlock>
                <STitle>
                    <SH2>
                        {translation('АУКЦИОН', translationStateGamePage, textsGamePage)}
                    </SH2>
                    <SCloseModalBtn
                        onClick={() => closeByCross()}
                    >
                        <span/>
                        <span/>
                    </SCloseModalBtn>
                </STitle>
                <SContentWrap>
                    <SP>
                        {translation('Пользователь', translationStateGamePage, textsGamePage)} {user} {translation('выставил на аукцион', translationStateGamePage, textsGamePage)} <span>{gameItem}</span>
                    </SP>
                    <SP
                        marginTop='2vh'
                    >
                        {translation('Текущая цена', translationStateGamePage, textsGamePage)}: <span>{String(currentPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} $</span>
                    </SP>
                    <SP>
                        {translation('Цена после подтверждения увеличится на', translationStateGamePage, textsGamePage)} <span>{String(priceAfterConfirmation).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} $</span>
                    </SP>
                    <SP
                        marginTop='3vh'
                        style={{'opacity': '0.6'}}
                    >
                        {`${translation('Автоматический отказ через', translationStateGamePage, textsGamePage)} `} 
                        <SSeconds>
                            {remainingSeconds}
                        </SSeconds>
                        {` ${translation('секунд', translationStateGamePage, textsGamePage)}`}
                    </SP>
                </SContentWrap>
                <SGreyWrap
                    style={{'gap' : '2vh'}}
                >
                    <SBtn
                        color='green'
                        onClick={participate}
                    >
                        {translation('УЧАСТВОВАТЬ', translationStateGamePage, textsGamePage)}
                    </SBtn>
                    <SBtn
                        color='yellow'
                        onClick={refuse}
                    >
                        {translation('ОТКАЗАТЬСЯ', translationStateGamePage, textsGamePage)}
                    </SBtn>
                </SGreyWrap>
            </SBlock>
        </SModalWrap>
    )
}
