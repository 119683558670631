export const getUserLobbyDataSC = async (socket : any) => {
    socket.emit("lobby.get.user",{});

    return new Promise(next => {
        const listener = (resp : any) => {
            socket.removeListener("lobby.get.user.result", listener);
            console.log ("getUserLobbyData", resp);
            next(resp);
        };

        socket.on("lobby.get.user.result", listener);
    }).then(resp => {
        return resp;
    });
};

export const getUsersTimerCallback = (socket : any, callback : any) => {
    socket.emit("lobby.get.timer",{});

    socket.on("lobby.get.timer.result", (ans : any) => {
        callback(ans);
    });
};
