import {
    useState,
    useEffect
} from 'react'

import WTSServise from '../../services/WTSServise'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setCustomTooltipState } from '../../store/actionCreators/customTooltip'
import { setUpTranslateNotFoundPage } from '../../store/actionCreators/translation'

import { textsNotFoundPage } from './config'
import { translation } from '../../utils/translation'

import { SNotFound } from './style'

export const NotFound = () => {

    const dispatch = useDispatch()

    const { languageState } = useTypedSelector(state => state.languageState)
    const { translationStateNotFoundPage } = useTypedSelector(state => state.translationStateNotFoundPage)

    const [texts, setTexts] = useState(textsNotFoundPage)

    useEffect(() => {
        updateTranslate()
    }, [languageState])
        
    const updateTranslate = async () => {
        try {
            const response = await WTSServise.translations(texts, languageState)
            let translateArr: string[] = []
            response.data.data.phraseTranslations.map((item: any) => {
                if(item.phrase.translation) {
                    translateArr.push(item.phrase.translation.text)
                    return
                } else {
                    translateArr.push(item.phrase.text)
                    return
                }
            })
            dispatch(setUpTranslateNotFoundPage(translateArr))
        
        } catch (e: any) {
            if (!e) return
            
            if (e.request.status === 400) {
                dispatch(setCustomTooltipState({
                    status: 'error',
                    titleText: translation('Ошибка', translationStateNotFoundPage, textsNotFoundPage),
                    text: translation('Ошибка сервера', translationStateNotFoundPage, textsNotFoundPage)
                }))
                return
            }
            
            console.log(e)
        }
    }

    return (
        <SNotFound>
            <h1>
                404
            </h1>
            <p>
                {translation('Неправильно набран адрес или такой страницы не существует', translationStateNotFoundPage, textsNotFoundPage)}
            </p>
        </SNotFound>
    )
}