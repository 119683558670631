export enum ActiveChatGameBtnActionType {
    SET_ACTIVE_GAME_CHAT_BTN = 'SET_ACTIVE_GAME_CHAT_BTN'
}

export interface ActiveChatGameBtnState {
    activeChatGameBtnState: number
}

export interface ActiveChatGameBtnAction {
    type: string,
    payload: number
}