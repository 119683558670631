import { GiveUpModalActionType, GiveUpModalState, GiveUpModalAction } from '../../types/giveUpModalType'

const initialState: GiveUpModalState = {
    giveUpModalState: false
}

export const giveUpModalReducer = (state = initialState, action: GiveUpModalAction): GiveUpModalState => {
    switch(action.type) {
        case GiveUpModalActionType.OPEN_GIVE_UP_MODAL:
            return {
                ...state,
                giveUpModalState: true
            }
        case GiveUpModalActionType.CLOSE_GIVE_UP_MODAL:
            return {
                ...state,
                giveUpModalState: false
            }
        default:
            return state
    }   
}