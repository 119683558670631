import { MenuButtonsHideAction, MenuButtonsHideActionType, MenuButtonsHideState} from "../../types/menuButtonsHideType";

const initialState: MenuButtonsHideState = {
    MenuButtonsHideState: true,
    AdditionMenuButtonsState: false,
    AdditionMenuButtonsCommandState: ""
}

export const menuButtonsHideReducer = (state = initialState, action: MenuButtonsHideAction): MenuButtonsHideState => {
    switch(action.type) {
        case MenuButtonsHideActionType.SET_UP_SHOW_BUTTONS:
            return {
                ...state,
                MenuButtonsHideState: true
            }
        case MenuButtonsHideActionType.SET_UP_HIDE_BUTTONS:
            return {
                ...state,
                MenuButtonsHideState: false
            }
        case MenuButtonsHideActionType.SET_UP_ADDITION_BUTTON:
            return {
                ...state,
                AdditionMenuButtonsState: true,
                AdditionMenuButtonsCommandState: action.command
            }
        case MenuButtonsHideActionType.SET_HIDE_ADDITION_BUTTON:
            return {
                ...state,
                AdditionMenuButtonsState: false
            }
        default:
            return state
    }   
}
