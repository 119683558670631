import { ChatInfoModalActionType, chatInfoModalState, chatInfoModalAction } from '../../types/chatInfoModalType'

const initialState: chatInfoModalState = {
    chatInfoModalState: false
}

export const chatInfoModaReducer = (state = initialState, action: chatInfoModalAction): chatInfoModalState => {
    switch(action.type) {
        case ChatInfoModalActionType.CHANGE_CHAT_INFO_MODAL:
            return {
                ...state,
                chatInfoModalState: !state.chatInfoModalState
            }
        case ChatInfoModalActionType.CLOSE_CHAT_INFO_MODAL:
            return {
                ...state,
                chatInfoModalState: false
            }
        default:
            return state
    }   
}