export enum InfoModalActionType {
    INFO_MODAL_OPEN = 'INFO_MODAL_OPEN',
    INFO_MODAL_CLOSE = 'INFO_MODAL_CLOSE'
}

export interface InfoModalState {
    infoModalState: boolean
}

export interface InfoModalAction {
    type: string
}