import avatar from '../../assets/img/avatar.png'

export const usersArrTemplate = [
    {
        id: 3,
        idKey: 0,
        avatar: avatar,
        nickmame: 'Ник',
        rang: 'менеджер',
        vip: false,
        common: '1 062 000',
        weekly: '3 480 000',
        items: [
            {
                id: 3,
                name: 'РАДИОСТАНЦИЯ',
                price: '30 000'
            },
            {
                name: 'ТУРАГЕНСТВО',
                price: '30 000'
            },
            {
                name: 'КАФЕ',
                price: '30 000'
            },
            {
                name: 'РЕСТОРАН',
                price: '30 000'
            },
            {
                name: 'РАДИОСТАНЦИЯ',
                price: '30 000'
            },
            {
                name: 'РЕСТОРАН',
                price: '30 000'
            },
            {
                name: 'РАДИОСТАНЦИЯ',
                price: '30 000'
            },
            {
                name: 'РЕСТОРАН',
                price: '30 000'
            },
            {
                name: 'РАДИОСТАНЦИЯ',
                price: '30 000'
            },
            {
                name: 'РЕСТОРАН',
                price: '30 000'
            }
        ]
    },
    {   
        id: 4,
        idKey: 1,
        avatar: avatar,
        nickmame: 'Ник',
        rang: 'менеджер',
        vip: false,
        common: '1 062 000',
        weekly: '3 480 000',
        items: [
            {
                id: 5,
                name: 'НЕФТЯНАЯ КОМПАНИЯ',
                price: '350 000'
            },
            {
                name: 'ГАЗОВАЯ КОМПАНИЯ',
                price: '350 000'
            },
            {
                name: 'СУДОСТРОИТЕЛЬНАЯ КОМПАНИЯ',
                price: '350 000'
            },
            {
                name: 'АВИАКОМПАНИЯ',
                price: '350 000'
            },
            {
                name: 'РАДИОСТАНЦИЯ',
                price: '30 000'
            },
            {
                name: 'РЕСТОРАН',
                price: '30 000'
            },
            {
                name: 'РАДИОСТАНЦИЯ',
                price: '30 000'
            },
            {
                name: 'РЕСТОРАН',
                price: '30 000'
            },
            {
                name: 'РАДИОСТАНЦИЯ',
                price: '30 000'
            },
            {
                name: 'РЕСТОРАН',
                price: '30 000'
            }
        ]
    }
]