import { io } from 'socket.io-client'
import { setUpChatUser, addToChatUser } from '../../store/actionCreators/chatUserLobby' 

export let socket3: any 

export const sendMessage = (username: any, message: any) => {
    socket3.emit('message:add', {
        type: "general",
        sender: username,
        message: message
    })
}

export const getMessage = (dispatch: any) => {
    socket3.on('message:get:general', (ans: any) => {
        if (ans.length > 0) {
            dispatch(setUpChatUser(ans))
        } else {
            dispatch(addToChatUser(ans))
        }
    })
}

export const startListeningUserChatSocket = () => {
    socket3 = io('ws://78.24.222.80:6003', {})

    socket3.on("connection", (ans: any) => {
        console.log("CONNECTED CHAT USER SUCCESS")
    })
}