import $api from '../http'
import { AxiosResponse } from 'axios'

export default class WTSServise {
    static async login(username: string, password: string): Promise<AxiosResponse> {
        return $api.post('/login', {username, password})
    }

    static async registration(email: string, username: string, password: string): Promise<AxiosResponse> {
        return $api.post('/register', {email, username, password})
    }

    static async refreshToken(): Promise<AxiosResponse> {
        return $api.post('/refresh')
    } 

    static async currentUser(): Promise<AxiosResponse> {
        return $api.get('/users/current')
    }

    static async languages(): Promise<AxiosResponse> {
        return $api.get('/languages')
    }
    
    static async translations(phrases: string[], languageKey: string): Promise<AxiosResponse> {
        return $api.post('/translations', {phrases, languageKey})
    }
}