export enum DevModalActionType {
    OPEN_DEV_MODAL = 'OPEN_DEV_MODAL',
    CLOSE_DEV_MODAL = 'CLOSE_DEV_MODAL'
}

export interface DevModalState {
    devModalState: boolean
}

export interface DevModalAction {
    type: string
}