import styled, { keyframes } from 'styled-components'

import gamersBG from '../../assets/img/gamersBG.jpg'

export const SMenu = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 12vw;
    background: url(${gamersBG}) center center/cover no-repeat;
    border-left: 0.5vh solid rgba(0, 0, 0, 0.6);
    margin-right: 1.5vw;
    padding-top: 7vh;
`

export const SRedMenuBtn = styled.button`
    width: 10vw;
    height: 4.8vh;
    background: 
        linear-gradient(rgba(233, 205, 156, 0.20) 0%, rgba(233, 205, 156, 0.07) 18.23%, rgba(233, 205, 156, 0.00) 45.81%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), 
        linear-gradient(180deg, #851313 0%, rgba(133, 19, 19, 0.00) 100%);
    color: #F4E6C2;
    text-align: center;
    text-shadow: 0px 4px 9px 0px rgba(232, 232, 232, 0.10);
    margin-top: 1.5vh;
    border-radius: 0.8vh;
    transform-style: preserve-3d;
    transform: translateZ(1px);
    font-size: 1.8vmin;

    &::after {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        display: none;
        width: calc(10vw + 4px);
        height: calc(4.8vh + 4px);
        border-radius: inherit;
        background: rgba(0, 0, 0, 0.1);
        transform: translateZ(10px);
    }

    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(10vw + 4px);
        height: calc(4.8vh + 4px);
        background: linear-gradient(rgba(255, 128, 128, 100), rgba(34, 16, 0, 0));
        border-radius: inherit;
        transform: translateZ(-1px);
        box-shadow: 
            0px 0px 20px rgba(0, 0, 0, 0.60), 
            0px 2px 0px #211102;
    }

    &:hover {
        &::after {
            display: block;
        }
    }

    &:focus {
        &::after {
            display: block;
            background: rgba(255, 255, 255, 0.1);
        }
    }
`

export const SMenuBtn = styled.button<{marginTop?: string, height?: string}>`
    width: 10vw;
    height: ${({height}) => height ? height : null};
    min-height: 4.8vh;
    background: 
        linear-gradient(rgba(255, 122, 0, 0.00), #FF7A00),
        #F5BC06;
    border-radius: 0.8vh;
    font-size: 1.8vmin;
    margin-top: ${({marginTop}) => marginTop ? marginTop : '1.5vh'};
    letter-spacing: 0.15vh;
    transform-style: preserve-3d;
    transform: translateZ(1px);

    &:hover {
        background: linear-gradient(rgba(255, 206, 48, 1), rgba(255, 147, 48, 1));

        &::before{
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            width: calc(10vw + 4px);
            height: ${({height}) => height ? `calc(${height} + 4px)` : `calc(4.8vh + 4px)`};
            border-radius: inherit;
            background: rgba(70, 25, 0, 1);
            transform: translateZ(-1px);
        }

        &::after{
            content: '';
            position: absolute;
            top: -4px;
            left: -4px;
            width: calc(10vw + 8px);
            height: ${({height}) => height ? `calc(${height} + 8px)` : `calc(4.8vh + 8px)`};
            border-radius: inherit;
            background: linear-gradient(rgba(255, 229, 136, 1), rgba(255, 188, 16, 1));
            transform: translateZ(-2px);
        }
    }
    
    &:focus {
        background: linear-gradient(rgba(248, 208, 81, 1), rgba(255, 163, 77, 1));
    }
`

const shineAnimation = keyframes`
    0% {
        box-shadow: 0 0 1.5vh #FF7A00;
    }
    50% {
        box-shadow: 0 0 3vh #F5BC06;
    }
    100% {
        box-shadow: 0 0 1.5vh #FF7A00;
    }
`

export const SAnimationWrap = styled.div`
    margin-top: 2vh;
    transform-style: preserve-3d;
    border-radius: 0.8vh;
    animation: infinite ${shineAnimation} 1500ms;
`