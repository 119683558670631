import { CustomTooltipActionType } from '../../types/customTooltipType'

export const setCustomTooltipState = (value: {
    status: 'error' | 'success'| 'notice',
    titleText: string,
    text: string
} | null) => {
    return {type: CustomTooltipActionType.SET_STATE_CUSTOM_TOOLTIP, payload: value}
}

export const closeCustomTooltip = () => {
    return {type: CustomTooltipActionType.CLOSE_CUSTOM_TOOLTIP}
}