import styled from 'styled-components'

import scrollLines from '../../assets/icons/scroll_lines.svg'
import usersListBG from '../../assets/img/usersListBG.jpg'

import { device } from '../../theme'

export const SUserList = styled.div`
    width: 100%;
    height: 60vh;
`

export const SOnline = styled.div`
    display : flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5vh;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
        #1A1A1A;
    border-radius: 1.4vh 1.4vh 0 0;
    font-size: 2.2vmin;

    @media (${device.tablet}) {
        font-size: 12px;
    }

    @media (${device.mobileM}) {
        font-size: 11px;
    }
`

export const SUsersListWrap = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 1vh;
    gap: 1.2vh;
    height: 49.5vh;
    overflow-y: scroll;
    scrollbar-color: #126D11 rgba(0, 0, 0, 0.30);
    scrollbar-width: auto;

    ::-webkit-scrollbar {
        width: 2vh;
        height: 100%;
        background: rgba(0, 0, 0, 0.30);
    }

    ::-webkit-scrollbar-thumb {
        background: 
            url(${scrollLines}) center center/100% no-repeat,
            linear-gradient(90deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%),
            linear-gradient(270deg, #2D7500 0%, #276207 42.19%, #132E05 100%);
        border: 1px solid #8EFF59;
        border-radius: 2vh;
    }
`

export const SUser = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 89.06%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), 
        #313131;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    border-radius: 0.4vh;
    height: 5.5vh;
    padding: 1vh 2vh;
    gap: 1vh;
    font-size: 1.4vmin;
    transform-style: preserve-3d;

    &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1));
        border-radius: inherit;
        transform: translateZ(-1px);
    }
`

export const SAvatar = styled.img`
    border-radius: 1.2vh;
    height: 3.7vh;
    margin: auto 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50), 0px -4px 4px rgba(125, 125, 125, 0.50);
`

export const SStatsWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const SUserBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2vh;
    width: 4.9vh;
    height: 4vh;
    border-radius: 1.4vh;
    background: #1A1A1A;
    border: 2px solid rgba(90, 90, 90, 1);
    box-shadow: 
        0px 0px 20px 0px rgba(0, 0, 0, 0.60), 
        0px 2px 0px 0px #000;

    >span {
        display: block;
        width: 0.6vh;
        height: 0.6vh;
        border-radius: 100%;
        background: rgba(220, 220, 220, 1);
    }
`

export const SButtonWrap = styled.div<{height?: string}>`
    display: flex;
    height: 3.5vh;
    gap: 0.2vh;
    padding-left: 1.5vh;
    & > *:nth-child(1) {
        z-index: 3;
    }
    & > *:nth-child(2) {
        z-index: 2;
    }
    & > *:nth-child(3) {
        z-index: 1;
    }
`

export const SUserListBlock = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.7vh;
    transform-style: preserve-3d;
    background: rgba(0, 0, 0, 0.50);
    transform-style: preserve-3d;
    z-index: 20;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: url(${usersListBG}) center center/cover no-repeat;
        transform: translateZ(-1px);
    }
    
    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1));
        border-radius: inherit;
        transform: translateZ(-2px);
    }

    @media (${device.tablet}) {
        &::before {
            top: -1px;
            left: -1px;
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }
`