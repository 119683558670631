export enum ActiveTabUserListActionType {
    SET_ACTIVE_TAB_USER_LIST = 'SET_ACTIVE_TAB_USER_LIST'
}

export interface ActiveTabUserListState {
    activeTabUserListState: string
}

export interface ActiveTabUserListAction {
    type: string,
    payload: string
}