import styled from 'styled-components'

export const SNotFound = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 25vh 25vw;
    background: 
        linear-gradient(0deg, rgba(69, 25, 0, 0.3) 0%, rgba(69, 25, 0, 0.3) 100%), 
        linear-gradient(180deg, #FFA329 0%, #FF8027 52.69%, #FF5A24 100%);
    border-radius: 5vh;
    padding: 5vh 4vh 0 4vh;
    transform-style: preserve-3d;
    
    &::before {
        content: '';
        left: -0.5vh;
        top: -0.5vh;
        width: calc(100% + 1vh);
        height: calc(100% + 1vh);
        position: absolute;
        background: 
            linear-gradient(0deg, rgba(69, 25, 0, 0.3) 0%, rgba(69, 25, 0, 0.3) 100%), 
            linear-gradient(0deg, #FFA329 0%, #FF8027 52.69%, #FF5A24 100%);
        transform: translateZ(-2px);
        border-radius: inherit;
    }

    >h1 {
        font-size: 25vmin;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
        opacity: 0.9;
    }

    >p{
        font-size: 3vmin;
        text-align: center;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
        opacity: 0.9;
    }
`