import styled from 'styled-components'

import { device } from '../../theme'

export const SMenuButtons = styled.div`
    display: grid;
    grid-template: 10vh 1.5vh 10vh/5vw 2.2vh 5vw 2.2vh 5vw 2.2vh 5vw;
    grid-template-areas: 
        'data . rating . store . bank'
        '. . . . . . .'
        'newgame newgame newgame . inviteFrend inviteFrend inviteFrend';
    
    @media (${device.tablet}) {
        grid-template: 9vh 1.3vh 9vh/7vw 2vh 7vw 2vh 7vw 2vh 7vw;
        grid-template-areas: 
            'data . rating . store . bank'
            '. . . . . . .'
            'newgame newgame newgame . inviteFrend inviteFrend inviteFrend';
    }

    @media (${device.mobileL}) {
        grid-template: 9vh 1.2vh 9vh/11vw 2vh 11vw 2vh 11vw 2vh 11vw;
        grid-template-areas: 
            'data . rating . store . bank'
            '. . . . . . .'
            'newgame newgame newgame . inviteFrend inviteFrend inviteFrend';
    }

    @media (${device.mobileM}) {
        grid-template: 9vh 1.2vh 9vh/13vw 2vh 13vw 2vh 13vw 2vh 13vw;
        grid-template-areas: 
            'data . rating . store . bank'
            '. . . . . . .'
            'newgame newgame newgame . inviteFrend inviteFrend inviteFrend';
    }

    @media (${device.mobileS}) and (max-height: 568px) {
        grid-template: 9vh 1.2vh 9vh/16vw 2vh 16vw 2vh 16vw 2vh 16vw;
        grid-template-areas: 
            'data . rating . store . bank'
            '. . . . . . .'
            'newgame newgame newgame . inviteFrend inviteFrend inviteFrend';
    }

    @media (${device.mobileS}) and (max-height: 480px) {
        grid-template: 10vh 1.2vh 10vh/16vw 2vh 16vw 2vh 16vw 2vh 16vw;
        grid-template-areas: 
            'data . rating . store . bank'
            '. . . . . . .'
            'newgame newgame newgame . inviteFrend inviteFrend inviteFrend';
    }

    
`

export const SMenuButton = styled.button<{gridArea: string, bigSize: boolean | undefined}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${({bigSize}) => bigSize ? 'center' : 'space-between'};
    font-size: ${({bigSize}) => bigSize ? '2.2vmin' : '1.4vmin'};
    color: #461900;
    background: 
        linear-gradient(180deg, rgba(255, 122, 0, 0.00) 10.94%, #FF7A00 100%),
        #F5BC06;
    grid-area: ${({gridArea}) => gridArea};
    border-radius: 0.7vh;
    padding: 1.6vh 0.6vh 1vh 0.6vh;
    transform-style: preserve-3d;

    &:hover {
        background: linear-gradient(rgba(255, 206, 48, 1), rgba(255, 147, 48, 1));
        
        &::before {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            border-radius: inherit;
            background: rgba(70, 25, 0, 1);
            transform: translateZ(-1px);
        }

        &::after {
            content: '';
            position: absolute;
            top: -4px;
            left: -4px;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border-radius: inherit;
            background: linear-gradient(rgba(255, 229, 136, 1), rgba(255, 188, 16, 1));
            transform: translateZ(-2px);
        }
    }
    
    &:focus {
        background: linear-gradient(rgba(248, 208, 81, 1), rgba(255, 163, 77, 1));
    }

    @media (${device.tablet}) {
        font-size: 10px;
        &::before {
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }

        &::after {
            top: -2px;
            left: -2px;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
        }
    }

    @media (${device.mobileM}) {
        font-size: 10px;
    }
`