import { CurrentUserActionType, CurrentUserState, CurrentUserAction } from '../../types/currentUserType'

const initialState: CurrentUserState = {
    currentUserState: null
}

export const currentUserReducer = (state = initialState, action: CurrentUserAction): CurrentUserState => {
    switch(action.type) {
        case CurrentUserActionType.SET_UP_CURRENT_USER:
            return {
                ...state,
                currentUserState: action.payload,
            }
        default:
            return state
    }
}
