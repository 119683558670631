export enum AuctionDataActionType {
    SET_UP_AUCTION_DATA = 'SET_UP_AUCTION_DATA'
}

export interface AuctionDataState {
    AuctionDataListState: any
}

export interface AuctionDataAction {
    type: string,
    payload?: any
}
