import styled from 'styled-components'

export const SPasswordRecoveryWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const SH1 = styled.h1`
    color: #FFC93D;
    font-size: 2.2vmin;
    margin-top: 1vh;
`

export const SForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

    >p {
        margin-top: 1vh;
    }
    
    >button {
        margin: 3vh 0 3vh 0;
    }
`