import styled, { css } from 'styled-components'

import { device } from '../../theme'

export const SWrap = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 16vh;
    font-size: 2.2vmin;
    margin-bottom: auto;
`

const leftPozitionBtn = css`
    transform: translateX(-46%) translateZ(1px);
    color: rgba(128, 128, 128, 0.603);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgb(19, 18, 18) 100%);
    border-radius: 1vh 5vh 0 0;
`

const rightPozitionBtn = css`
    transform: translateX(46%) translateZ(1px);
    color: rgba(128, 128, 128, 0.603);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgb(19, 18, 18) 100%);
    border-radius: 5vh 1vh 0 0;
    padding-left: 1.5vh;
`

export const SButtonFormTab = styled.button<{ left?: boolean }>`
    position: absolute;
    top: -5vh;
    width: 12vw;
    height: 5.5vh;
    text-align: center;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transform-style: preserve-3d;
    padding-bottom: 0.5vh;

    ${({left}) => left ? leftPozitionBtn : rightPozitionBtn }
    
    &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 253, 252, 0));
        border-radius: inherit;
        transform: translateZ(-1px);
    }

    &.active {
        background: linear-gradient(0deg, rgba(0,0,0,1)  0%, rgb(41, 41, 41) 100%);
        z-index: 10;
        color: rgba(255, 201, 61, 1);
        &::before {
            background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 253, 252, 0));
        }
    }

    @media (${device.laptop}) {
        min-width: 170px;
    }

    @media (${device.tablet}) {
        min-width: 150px;
        &::before {
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }

    @media (${device.mobileL}) {
        min-width: 30vw;
        height: 4.5vh; 
        top: -4vh;
    }

    @media (${device.mobileM}) {
        height: 4vh; 
        top: -3.5vh;
    }
`

export const SEntryRegistrationContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30vw;
    min-width: 30vw;
    background: 
        linear-gradient(rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
        #1A1A1A;
    border-radius: 1.4vh;
    transform-style: preserve-3d;
    z-index: 20;
    
    &::before {
        content: '';
        position: absolute;
        top: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(rgba(255, 255, 255, 100), rgba(255, 253, 252, 0));
        border-radius: inherit;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.50);
        transform: translateZ(-1px);
    }

    @media (${device.laptop}) {
        width: 360px;
    }

    @media (${device.tablet}) {
        width: 320px;
        &::before {
            top: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }

    @media (${device.mobileL}) { 
        width: 280px;
    }

    @media (${device.mobileM}) { 
        width: 240px;
    }
`