import { useState } from 'react'

import {
    SCheckboxWrap,
    SCheckbox,
    SCheckmark
} from './style'

export const Checkbox = (
    {initialState, children, id, onCheckboxChange, marginTop}:
    {initialState: boolean, children: React.ReactNode, id: string, onCheckboxChange?: (isChecked: boolean) => void, marginTop?: string}) => {
    
    const [ checked, setChecked ] = useState(initialState)

    const chengeCheckbox = () => {
        setChecked(!checked)
        onCheckboxChange?.(!checked)
    }

    return (
        <SCheckboxWrap
            marginTop={marginTop}
        >
            <SCheckbox
                onClick={chengeCheckbox}
            >
                <SCheckmark
                    id={id}
                    checked={checked}
                />
            </SCheckbox>
            {children}
        </SCheckboxWrap>
    )
}