import { useCallback, useState } from 'react'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useDispatch } from 'react-redux'
import { setLanguageModal, closeLanguageModal } from '../../store/actionCreators/languageModal'
import { setVolumeModal, closeVolumeModal } from '../../store/actionCreators/volumModal'
import { openYouLeaveModal } from '../../store/actionCreators/youLeaveModal'
import { openDevModal } from '../../store/actionCreators/devModal'
import { openInfoModal } from '../../store/actionCreators/infoModal'

import { LanguageModal } from '../languageModal'
import { VolumeModal } from '../volumeModal'
import { GameStatisticsContent } from '../gameStatisticsContent'

import { languageModalConfig } from '../languageModal/config'
import {
    navButtonsConfig,
    navButtonsConfigGameFieldPage,
    navButtonsConfigAuthorizationPage
} from './config'

import { textsAuthPage } from '../authorization/config'
import { textsMainPage } from '../mainContent/config'
import { translation } from '../../utils/translation'

import exit from '../../assets/icons/exit.svg'

import { 
    SHeader,
    SBGLogo,
    SLogo,
    SNavUl,
    SRatingWrap,
    SRating, 
    SIcon,
    SNavMenuButton,
    SLi,
    SNav,
    SHambuger,
    SStatisticsBtn,
    SIconExit
} from './style'

import { navButtonsConfigType } from '../../types/navButtonsConfigType'

export const Header = (
    {logo, rating, game, notFoundPage, authorizationPage}:
    {logo: boolean, rating: boolean, game: boolean, notFoundPage?: boolean, authorizationPage?: boolean}) => {

    const dispatch = useDispatch()

    const { languageModalState } = useTypedSelector(state => state.languageModalState)
    const { languageState } = useTypedSelector(state => state.languageState)
    const { volumeModalState } = useTypedSelector(state => state.volumeModalState)
    const { currentUserState } = useTypedSelector(state => state.currentUserState)
    const { translationStateAuthPage } = useTypedSelector(state => state.translationStateAuthPage)
    const { translationStateMainPage } = useTypedSelector(state => state.translationStateMainPage)
    const { lobbieDataListState } = useTypedSelector(state => state.lobbieDataListState)

    const [activeHamburger, setActiveHamburger] = useState(false)
    const [activeStatistics, setActiveStatistics] = useState(false)
    
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        
        const targetElement = e.target as HTMLElement

        switch(targetElement.id) {
            case 'userButton':
                dispatch(openDevModal())
                break
            case 'settingsButton':
                dispatch(openDevModal())
                break
            case 'volumeButton':
                dispatch(setVolumeModal())
                dispatch(closeLanguageModal())
                break
            case 'fullScreenButton':
                if (!document.fullscreenElement) {
                    document.documentElement.requestFullscreen().catch((err: Error) => {
                        console.error(`Error attempting to enable full-screen mode: ${err.message}`)
                    })
                }
                if (document.exitFullscreen) {
                    document.exitFullscreen().catch((err: Error) => {
                        console.error(`Error attempting to exit full-screen mode: ${err.message}`)
                    })
                }
                break
            case 'infoButton':
                dispatch(openInfoModal())
                break
            case 'languageButton':
                dispatch(setLanguageModal())
                dispatch(closeVolumeModal())
                break
            case 'entryButton':
                dispatch(openYouLeaveModal())
                break
            default:
                return null
        }
    }
    
    const renderNavMenuBtn = useCallback((arr: navButtonsConfigType[]) => { 
        return arr.map(({id, iconBtn, red, bigSize}) => {
            
            const langItem = languageModalConfig.find((item) => item.id === languageState)
            const iconItem = iconBtn
                ? 
                <SIcon
                    src={iconBtn}
                    alt={id}
                    bigSize={bigSize}
                />
                : 
                id === 'languageButton'
                    ?
                    <SIcon
                        src={langItem?.icon}
                        alt={id}
                        bigSize={bigSize}
                        style={window.innerWidth <= 425 ? {'height': '1.5vh'} : undefined}
                    />
                    :
                    <SIconExit
                        src={exit}
                        alt={id}
                    />
            return (
                <SLi
                    key={id}
                    bigSize={bigSize}
                >
                    <SNavMenuButton
                        id={id}
                        red={red}
                        bigSize={bigSize}
                    >
                        {id === 'languageButton' 
                            ? <span>
                                {translation('ЯЗЫК', translationStateAuthPage, textsAuthPage)}
                              </span> 
                            : null
                        }
                        {id === 'entryButton' 
                            ? <span>
                                {translation('ВЫХОД', translationStateAuthPage, textsAuthPage)}
                              </span> 
                            : null
                        }
                        {iconItem}
                    </SNavMenuButton>
                </SLi>
            )
        })
    }, [languageState, translationStateAuthPage])

    const btnContent = authorizationPage 
        ? renderNavMenuBtn(navButtonsConfigAuthorizationPage)
        : (
            game 
            ? renderNavMenuBtn(navButtonsConfigGameFieldPage)
            : renderNavMenuBtn(navButtonsConfig)
          )

    const logoContent = logo
        ?   <>
                <SBGLogo
                    href='/login'
                    title='The site of the company'
                />
                <SLogo>
                    {rating ? 'название игры' : 'ЛОГО'}
                </SLogo>
            </>
        : null

    const ratingContent = rating  
        ?   <SRatingWrap
                className={activeStatistics ? 'active' : undefined}
            >   
                <SRating>
                    {translation('Общий рейтинг', translationStateMainPage, textsMainPage)}:
                    <span
                        style={{'color' : '#FF0B0B', 'paddingLeft' : '4px'}}
                    >
                        {currentUserState?.totalCapital ? currentUserState.totalCapital : '0'}
                    </span>
                </SRating>
                <SRating>
                    {translation('Рейтинг недели', translationStateMainPage, textsMainPage)}:
                    <span
                        style={{'color' : '#34F005', 'paddingLeft' : '4px'}}
                    >
                        {currentUserState?.weeklyCapital ? currentUserState.weeklyCapital : '0'}
                    </span>
                </SRating>
            </SRatingWrap>
        : null

    const gameStatisticsContent = game
        ? !(Object.keys(lobbieDataListState).length === 0) ? <GameStatisticsContent/> : <span/>
        : null

    /* const hamburgerContent = window.innerWidth <= 320 ? console.log('да') : console.log('нет')  */

    const navContent = !notFoundPage 
        ?   <SNav
                className={activeHamburger ? 'active' : undefined}
            >
                <SNavUl
                    onClick={(e) => handleClick(e)}
                >
                    {btnContent}
                </SNavUl>
                <LanguageModal 
                    game={game} 
                    languageModalState={languageModalState}
                />
                <VolumeModal 
                    game={game}
                    volumeModalState={volumeModalState}
                />
            </SNav> 
        : null

    return (
        <SHeader
            rating={rating}
            game={game}
        >
            {logoContent}
            {ratingContent}
            {gameStatisticsContent}
            {navContent}

            <SStatisticsBtn
                className={activeStatistics ? 'active' : undefined}
                onClick={() => setActiveStatistics((state) => !state)}
                authorizationPage={authorizationPage}
            >
                <span/>
            </SStatisticsBtn>
            <SHambuger
                className={activeHamburger ? 'active' : undefined}
                onClick={() => setActiveHamburger((state) => !state)}
            >
                <span/>
                <span/>
                <span/>
                <span/>
            </SHambuger>
        </SHeader>
    );
}