import styled from 'styled-components'

import { device } from '../../theme'

export const SButtonsMaleFemale = styled.button<{ width: string}>`
    margin-top: 1vh;
    width: ${({width}) => width}; 
    height: 4vh;
    color: #FFF;
    border-radius: 1vh;
    background: linear-gradient(180deg, #353535 0%, #343434 52.69%, #282828 100%);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.50);
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.5);
    transform-style: preserve-3d;

    &::after {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border-radius: inherit;
        transform: translateZ(1px);
    }

    &::before {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        background: linear-gradient(rgba(29, 29, 29, 1), rgba(77, 77, 77, 1));
        border-radius: inherit;
        transform: translateZ(-1px);
        box-shadow: 0px -0.2vh 0.2vh rgba(125, 125, 125, 0.50);
    }
    
    &:hover {
        &::after {
            background: rgba(0, 0, 0, 0.2);
        }
    }

    &:focus {
        &::after {
            background: rgba(255, 255, 255, 0.2);
        }
    }

    &.active {
        background: 
            linear-gradient(180deg, #06AE17 0%, #126B10 52.69%, #0B6209 100%), 
            linear-gradient(180deg, #353535 0%, #343434 52.69%, #282828 100%);
    }

    @media (${device.mobileL}) { 
        height: 3vh;
    }
`

export const SButtonsMaleFemaleWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
    width: 100%;
    gap: 4vh;
`

export const SEnterWordWrap = styled.div`
    display: flex;
    align-items: center;
`

export const SGridBox = styled.div`
    margin-top: 2.5vh;
    display: grid;
    width: 100%;
    gap: 2vh;
    grid-template: 5vh 5vh / 1fr 1fr;
    grid-template-areas: 
        'googleBtn appleBtn'
        'facebookBtn yandexBtn'
    ;
    margin-bottom: 2vh;
    @media (${device.mobileL}) {
        grid-template: 3vh 3vh / 1fr 1fr;
        grid-template-areas: 
            'googleBtn appleBtn'
            'facebookBtn yandexBtn'
        ;
    }
`