import {
    useMemo,
    useState,
    useCallback
} from 'react'

import WTSServise from '../../services/WTSServise'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setCustomTooltipState } from '../../store/actionCreators/customTooltip'

import { Checkbox } from '../checkbox'
import { Spinner } from '../spinner'

import { socialButtonsConfig, textsAuthPage } from '../authorization/config'
import { translation } from '../../utils/translation'

import enterWordImg from '../../assets/img/enter-word.jpg'

import {
    SButtonsMaleFemaleWrap,
    SButtonsMaleFemale,
    SEnterWordWrap,
    SGridBox
} from './style'
import {
    SLabel,
    SInput,
    SDarkgrayBox,
    SP,
    SLine,
    SButton,
    SBtn,
    SForm
} from '../entryContent/style'

export const RegistrationContent = (
    {textSocialButtonsFun} : 
    {textSocialButtonsFun: (id: string) => string | null}) => {

    const dispatch = useDispatch()

    const { translationStateAuthPage } = useTypedSelector(state => state.translationStateAuthPage)

    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [imgControl, setImgControl] = useState('')
    const [validEmail, setValidEmail] = useState<boolean | null>(null)
    const [validUsername, setValidUsername] = useState<boolean | null>(null)
    const [validPassword, setValidPassword] = useState<boolean | null>(null)
    const [valueValidEmail, setValueValidEmail] = useState<string | null>(null)
    const [valueValidUsername, setValueValidUsername] = useState<string | null>(null)
    const [valueValidPassword, setValueValidPassword] = useState<string | null>(null)
    const [validRepeatPassword, setValidRepeatPassword] = useState<boolean | null>(null)
    const [validImgControl, setValidImgControl] = useState<boolean | null>(null)
    const [checkedAgree, setCheckedAgree] = useState(false)
    const [gender, setGender] = useState('male')
    const [loading, setLoading] = useState(false)

    const checkValidUsername = useCallback((username: string) => {
        if(username === '') {
            setValidUsername(false)
            setValueValidUsername(translation('Пустое поле никнейма', translationStateAuthPage, textsAuthPage))
            return
        }

        setValidUsername(true)
        setValueValidUsername(null)
    }, [translationStateAuthPage])

    const checkValidEmail = useCallback((email: string) => {
        if(email === '') {
            setValidEmail(false)
            setValueValidEmail(translation('Пустое поле email', translationStateAuthPage, textsAuthPage))
            return
        }

        if(!(/\S+@\S+\.\S+/.test(email))) {
            setValidEmail(false)
            setValueValidEmail(translation('Введите корректный email', translationStateAuthPage, textsAuthPage))
            return
        }
        
        setValidEmail(true)
        setValueValidEmail(null)
    }, [translationStateAuthPage])

    const checkValidPassword = useCallback((password: string) => {
        if(password === '') {
            setValidPassword(false)
            setValueValidPassword(translation('Пустое поле пароля', translationStateAuthPage, textsAuthPage))
            return
        }

        if (password.length < 8) {
            setValidPassword(false)
            setValueValidPassword(translation('Длина пароля менее 8 символов', translationStateAuthPage, textsAuthPage))
            return
        }
        
        setValidPassword(true)
        setValueValidPassword(null)
    }, [translationStateAuthPage])

    const checkValidRepeatPassword = useCallback((passwordOne: string, passwordTwo: string) => {
        if(passwordOne !== passwordTwo) {
            setValidRepeatPassword(false)
            return false
        }
        
        setValidRepeatPassword(true)
        return true
    }, [])

    const checkImgControl = useCallback((str: string) => {
        if (str !== '83288') {
            setValidImgControl(false)
            return
        }
        setValidImgControl(true)
    }, [])

    const registration = async (email: string, username: string, password: string) => {
        setLoading(true)
        try {
            const response = await WTSServise.registration(email, username, password)
            localStorage.setItem('accessToken', response.data.data.tokens.accessToken)
            setLoading(false)
            dispatch(setCustomTooltipState({
                status: 'success',
                titleText: translation('Успешно', translationStateAuthPage, textsAuthPage),
                text: `${translation('Вы успешно зарегистрировались', translationStateAuthPage, textsAuthPage)}!`
            }))
        } catch (e: any) {
            setLoading(false)
            if (!e) return

            if (e.request.status === 400) {
                dispatch(setCustomTooltipState({
                    status: 'error',
                    titleText: translation('Ошибка', translationStateAuthPage, textsAuthPage),
                    text: translation('Электронная почта или пароль неверны', translationStateAuthPage, textsAuthPage)
                }))
                return
            }

            dispatch(setCustomTooltipState({
                status: 'error',
                titleText: translation('Ошибка', translationStateAuthPage, textsAuthPage),
                text: translation('Ошибка сервера', translationStateAuthPage, textsAuthPage)
            }))
        }
    }

    const changeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value)
        checkValidUsername(e.target.value)
    }

    const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
        checkValidEmail(e.target.value)
    }

    const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
        checkValidPassword(e.target.value)
    }

    const changeRepeatPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRepeatPassword(e.target.value)
        checkValidRepeatPassword(password, e.target.value)
    }

    const changeImgControl = (e: React.ChangeEvent<HTMLInputElement>) => {
        setImgControl(e.target.value)
        checkImgControl(e.target.value)
    }

    const checkedAgreeChange = (newState: boolean) => {
        setCheckedAgree(newState)
    }

    const postData = () => {
        if(
            validUsername
            && validEmail
            && validPassword
            && validRepeatPassword
            && validImgControl
            && checkedAgree
        ) {
            registration(
                email,
                username,
                password
            )
        } else {
            if(!validUsername) {
                dispatch(setCustomTooltipState({
                    status: 'notice',
                    titleText: translation('Уведомление', translationStateAuthPage, textsAuthPage),
                    text: valueValidUsername ? valueValidUsername : translation('Пустое поле никнейма', translationStateAuthPage, textsAuthPage)
                }))
                return
            }
            if(!validEmail) {
                dispatch(setCustomTooltipState({
                    status: 'notice',
                    titleText: translation('Уведомление', translationStateAuthPage, textsAuthPage),
                    text: valueValidEmail ? valueValidEmail : translation('Пустое поле email', translationStateAuthPage, textsAuthPage)
                }))
                return
            }
            if(!validPassword) {
                dispatch(setCustomTooltipState({
                    status: 'notice',
                    titleText: translation('Уведомление', translationStateAuthPage, textsAuthPage),
                    text: valueValidPassword ? valueValidPassword : translation('Пустое поле пароля', translationStateAuthPage, textsAuthPage)
                }))
                return
            }
            if(!validRepeatPassword) {
                dispatch(setCustomTooltipState({
                    status: 'notice',
                    titleText: translation('Уведомление', translationStateAuthPage, textsAuthPage),
                    text: translation('Пароли не совпадают', translationStateAuthPage, textsAuthPage)
                }))
                return
            }
            if(!validImgControl) {
                dispatch(setCustomTooltipState({
                    status: 'notice',
                    titleText: translation('Уведомление', translationStateAuthPage, textsAuthPage),
                    text: translation('Введите корректное число с картинки', translationStateAuthPage, textsAuthPage)
                }))
                return
            }
            if(!checkedAgree) {
                dispatch(setCustomTooltipState({
                    status: 'notice',
                    titleText: translation('Уведомление', translationStateAuthPage, textsAuthPage),
                    text: translation('Вы должны быть согласны с пользовательским соглашением', translationStateAuthPage, textsAuthPage)
                }))
                return
            }
        }
    }

    const socialRegistrationButtons = useMemo(() => {
        return socialButtonsConfig.map(({id, icon}, i) => {
            if(!i) {
                return
            }
            return (
                <SButton 
                    id={id}
                    gridArea={id}
                    key={id}
                    green={!i}
                    odd={i%2}
                    icon={icon}
                    entry={false}
                >
                    {textSocialButtonsFun(id)}
                </SButton>
            )
        })
    }, [])

    const changeGender = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, gender: 'male' | 'female') => {
        e.preventDefault()
        setGender(gender)
    }
    
    return (
        <>
            <SForm
                action=''
                method='post'
                name='form'
            >
                <SLabel 
                    htmlFor='userNicknameInput'
                >
                    {`${translation('Никнейм', translationStateAuthPage, textsAuthPage)}:`}
                </SLabel>
                <SInput 
                    type='text'
                    name='username'
                    placeholder={translation('Никнейм юзера', translationStateAuthPage, textsAuthPage)}
                    id='userNicknameInput'
                    onChange={(e) => changeUsername(e)}
                    value={username}
                    autoComplete='username'
                    className={username 
                        ? (validUsername === null ? undefined 
                        : (validUsername ? 'green' : 'red')) : undefined
                    }
                />
                <SLabel 
                    htmlFor='emailInput'
                >
                    {`${translation('Электронная почта', translationStateAuthPage, textsAuthPage)}:`}
                </SLabel>
                <SInput 
                    type='text'
                    name='email'
                    placeholder='testiets@mail.ru'
                    id='emailInput'
                    autoComplete='email'
                    onChange={(e) => changeEmail(e)}
                    value={email}
                    className={email 
                        ? (validEmail === null ? undefined 
                        : (validEmail ? 'green' : 'red')) : undefined}
                /> 
                <SButtonsMaleFemaleWrap>
                    <SButtonsMaleFemale 
                        width={'100%'} 
                        onClick={(e) => changeGender(e, 'male')}
                        className={gender === 'male' ? 'active' : undefined }
                    >
                        {translation('БИЗНЕСМЕН', translationStateAuthPage, textsAuthPage)}
                    </SButtonsMaleFemale>
                    <SButtonsMaleFemale 
                        width={'100%'} 
                        onClick={(e) => changeGender(e, 'female')}
                        className={gender === 'female' ? 'active' : undefined }
                    >
                        {translation('БИЗНЕСВУМЕН', translationStateAuthPage, textsAuthPage)}
                    </SButtonsMaleFemale>
                </SButtonsMaleFemaleWrap>
                <SLabel 
                    htmlFor='password'
                >
                    {`${translation('Пароль', translationStateAuthPage, textsAuthPage)}:`}
                </SLabel>
                <SInput 
                    type='password'
                    name='password'
                    placeholder='********'
                    id='password'
                    autoComplete='password'
                    onChange={(e) => changePassword(e)}
                    value={password}
                    className={password 
                        ? (validPassword === null ? undefined 
                        : (validPassword ? 'green' : 'red')) : undefined
                    }
                />
                <SLabel
                    htmlFor='repeatPassword'
                >
                    {`${translation('Повторите пароль', translationStateAuthPage, textsAuthPage)}:`}
                </SLabel>
                <SInput
                    type='password'
                    name='repeatPassword'
                    placeholder='********'
                    id='repeatPassword'
                    autoComplete='repeatPassword'
                    onChange={(e) => changeRepeatPassword(e)}
                    value={repeatPassword}
                    className={repeatPassword 
                        ? (validRepeatPassword === null ? undefined 
                        : (validRepeatPassword ? 'green' : 'red')) : undefined
                    }
                />
                <SLabel
                    htmlFor='enterWordInput'
                >
                    {`${translation('Введите слово с картинки', translationStateAuthPage, textsAuthPage)}:`}
                </SLabel>
                <SEnterWordWrap>
                    <SInput
                        type='text'
                        name='imgControl'
                        placeholder=''
                        id='imgControl'
                        onChange={(e) => changeImgControl(e)}
                        value={imgControl}
                        className={imgControl 
                            ? (validImgControl === null ? undefined 
                            : (validImgControl ? 'green' : 'red')) : undefined
                        }
                    />
                    <img 
                        src={enterWordImg}
                        alt='imgControl'
                        style={{'height' : '4vh', 'margin': '1vh 0 0 1vh'}}
                    />
                </SEnterWordWrap>
                <Checkbox
                    initialState={false}
                    id='termsOfUse'
                    onCheckboxChange={checkedAgreeChange}
                >
                    <span>
                        {translation('Согласен с', translationStateAuthPage, textsAuthPage)} 
                    </span>
                    <span
                        style={{ 'color': 'rgba(255, 201, 61, 1)', 'textDecorationLine': 'underline', 'cursor': 'pointer', 'marginLeft' : '3px'}}>
                        {translation('пользовательским соглашением', translationStateAuthPage, textsAuthPage)}
                    </span>
                </Checkbox>
            </SForm>  
            <SDarkgrayBox>
                {loading 
                    ? <Spinner
                        fontSize='4.5vmin'
                        height='5.5vh'
                      /> 
                    : <SBtn 
                        width={'25vh'} 
                        id='submit'
                        onClick={postData}
                      >
                        {translation('ПРОДОЛЖИТЬ', translationStateAuthPage, textsAuthPage)}
                      </SBtn>
                }
                <SP>
                    {`${translation('Вход с помощью', translationStateAuthPage, textsAuthPage)}:`}
                </SP>
                <SLine/>
                <SGridBox>
                    {socialRegistrationButtons}
                </SGridBox>
            </SDarkgrayBox>
        </>
    )
}
