import {difTime} from "./difTime";

const auctionSound = new Audio("/sounds/auction.mp3");
const rolldiceSound = new Audio("/sounds/rolldice.mp3");
const currentturn = new Audio("/sounds/currentturn.mp3");
const winnerSound = new Audio("/sounds/winner.mp3");
const exchangewindow = new Audio("/sounds/exchangewindow.mp3");

export default class SoundManager {

    static isPlayAuction : Date = new Date();
    static isPlayRollDice : Date = new Date();
    static isPlayCurrentTurn : Date = new Date();
    static isPlayWinnerSound : Date = new Date();
    static isPlayExchangeWindow : Date = new Date();

    static playWinnerSound() {
        if (difTime(SoundManager.isPlayWinnerSound) > 0.15) {
            winnerSound.play();
            SoundManager.isPlayWinnerSound = new Date();
        }
    }

    static playCurrentTurn() {
        if (difTime(SoundManager.isPlayCurrentTurn) > 0.15) {
            currentturn.play();
            SoundManager.isPlayCurrentTurn = new Date();
        }
    }

    static playRollDice() {
        if (difTime(SoundManager.isPlayRollDice) > 0.25) {
            rolldiceSound.play();
            SoundManager.isPlayRollDice = new Date();
        }
    }

    static playAuction() {
        if (difTime(SoundManager.isPlayAuction) > 0.15) {
            auctionSound.play();
            SoundManager.isPlayAuction = new Date();
        }
    }

    static playIGetMakeADeal() {
        if (difTime(SoundManager.isPlayExchangeWindow) > 0.75) {
            exchangewindow.play();
            SoundManager.isPlayExchangeWindow = new Date();
        }
    }
}
