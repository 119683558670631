export enum YouWinModalActionType {
    OPEN_YOU_WIN_MODAL = 'OPEN_YOU_WIN_MODAL',
    CLOSE_YOU_WIN_MODAL = 'CLOSE_YOU_WIN_MODAL'
}

export interface YouWinModalState {
    youWinModalState: boolean,
    youWinModalData: any
}

export interface YouWinModalAction {
    type: string,
    data?:any
}
