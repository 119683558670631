import { ChatUserActionType, ChatUserState, ChatUserAction } from '../../types/chatUserType'

const initialState: ChatUserState = {
    chatUserState: []
}

export const chatUserReducer = (state = initialState, action: ChatUserAction): ChatUserState => {
    switch(action.type) {
        case ChatUserActionType.SET_UP_CHAT_USER:
            return {
                ...state,
                chatUserState: action.payload,
            }

        case ChatUserActionType.UPDATE_CHAT_USER:
            return {
                ...state,
                chatUserState: state.chatUserState ? state.chatUserState.concat(action.payload) : [action.payload],
            }

        default:
            return state
    }
}
