import styled from 'styled-components'

import { device } from '../../theme'

export const SH2 = styled.h2`
   text-align: center;
   text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
   font-size: 3.2vmin;
   background: 
      linear-gradient(rgba(172, 41, 0, 0), rgba(172, 41, 0, 1) 170%),
      linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%), rgba(255, 186, 0, 1);
   background-clip:
      linear-gradient(rgba(172, 41, 0, 0), rgba(172, 41, 0, 1) 170%),
      linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%), rgba(255, 186, 0, 1);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
`

export const SContentWrap = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   padding: 3vh 2vh 0 2vh;
`
export const SImg = styled.img`
   height: 20vh;
   margin-top: -17.5vh;
`

export const SUserWrapUl = styled.ul`
   display: flex;
   justify-content: center;
   gap: 3vh;

   >li{
      display: flex;
      flex-direction: column;
      gap: 0.5vh;
   }
`

export const SAvatar = styled.div<{vip: boolean}>`
   position: relative;
   border-radius: 0.8vh;
   transform-style: preserve-3d;
   width: 8vh;
   height: 9vh;

   &::before {
      content: '';
      top: -2px;
      left: -2px;
      position: absolute;
      width: calc(8vh + 4px);
      height: calc(9vh + 4px);
      background: ${({vip}) => vip 
         ? 'linear-gradient(-45deg, rgba(255, 168, 0, 1), rgba(255, 169, 2, 0)), linear-gradient(-135deg, rgba(255, 205, 147, 1), rgba(226, 187, 136, 1))' 
         : 'linear-gradient(rgba(255, 215, 202, 1), rgba(221, 205, 183, 1))'};
      border-radius: inherit;
      transform: translateZ(-1px);
   }
`

export const SVip = styled.img`
   position: absolute;
   width: 5vh;
   height: 5vh;
   top: -1.15vh;
   right: -1.3vh; 
`

export const SBlackBgBlock = styled.div<{marginTop?: string}>`
   display: flex;
   justify-content: center;
   gap: 2vh;
   margin-top: ${({marginTop}) => marginTop ? marginTop : null};
   padding: 1vh;
   width: 100%;
   border-radius: 0.6vh;
   background:
      linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.8) 75%),
      linear-gradient(360deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.00) 50%);
   transform-style: preserve-3d;

   &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 1px);
      background: linear-gradient(360deg, rgba(220, 220, 220, 0.4), rgba(0, 0, 0, 0));
      border-radius: inherit;
      transform: translateZ(-1px);
   }
`

export const SBlock = styled.div`
   position: relative;
   width: 30vw;
   height: 40vh;
   border-radius: 0.7vh;
   background: #1A1A1A;
   transform-style: preserve-3d;
    
   &::before {
      content: '';
      top: -2px;
      left: -2px;
      position: absolute;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background: linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1));
      border-radius: inherit;
      transform: translateZ(-2px);
   }

   @media (${device.tablet}) {
      min-width: 301px;
      &::before {
         top: -1px;
         left: -1px;
         position: absolute;
         width: calc(100% + 2px);
         height: calc(100% + 2px);
      }
   }

   @media (${device.mobileS}) {
      min-width: 268px; 
   }
`