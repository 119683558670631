export enum LobbieDataActionType {
    SET_UP_LOBBIE_DATA = 'SET_UP_LOBBIE_DATA',
    SET_UP_TURNS = 'SET_UP_TURNS'
}

export interface LobbieDataState {
    lobbieDataListState: any
}

export interface LobbieDataAction {
    type: string,
    payload?: any
}
