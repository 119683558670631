import {
    useState,
    useCallback,
    useEffect
} from 'react'

import WTSServise from '../../services/WTSServise'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setCustomTooltipState } from '../../store/actionCreators/customTooltip'
import { setUpTranslatePasswordRecoveryPage } from '../../store/actionCreators/translation'

import { textsPasswordRecoveryPage } from './config'
import { translation } from '../../utils/translation'

import {
    SPasswordRecoveryWrap,
    SForm
} from './style'
import { SEntryRegistrationContent } from '../authorization/style'
import { SBtn } from '../entryContent/style'
import {
    SLabel,
    SInput
} from '../entryContent/style'
import {
    STitle,
    SH2 
} from '../newGameModal/style'

export const PasswordRecovery = () => {

    const dispatch = useDispatch()

    const { languageState } = useTypedSelector(state => state.languageState)
    const { translationStatePasswordRecoveryPage } = useTypedSelector(state => state.translationStatePasswordRecoveryPage)

    const [texts, setTexts] = useState(textsPasswordRecoveryPage)
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState<boolean | null>(null)
    const [valueValidEmail, setValueValidEmail] = useState<string | null>(null)

    useEffect(() => {
        updateTranslate()
    }, [languageState])
        
    const updateTranslate = async () => {
        try {
            const response = await WTSServise.translations(texts, languageState)
            let translateArr: string[] = []
            response.data.data.phraseTranslations.map((item: any) => {
                if(item.phrase.translation) {
                    translateArr.push(item.phrase.translation.text)
                    return
                } else {
                    translateArr.push(item.phrase.text)
                    return
                }
            })
            dispatch(setUpTranslatePasswordRecoveryPage(translateArr))
        
        } catch (e: any) {
            if (!e) return
            
            if (e.request.status === 400) {
                dispatch(setCustomTooltipState({
                    status: 'error',
                    titleText: translation('Ошибка', translationStatePasswordRecoveryPage, textsPasswordRecoveryPage),
                    text: translation('Ошибка сервера', translationStatePasswordRecoveryPage, textsPasswordRecoveryPage)
                }))
                return
            }
            
            console.log(e)
        }
    }

    const checkValidEmail = useCallback((email: string) => {
        if(email === '') {
            setValidEmail(false)
            setValueValidEmail(translation('Пустое поле email', translationStatePasswordRecoveryPage, textsPasswordRecoveryPage))
            return
        }
        
        if(!(/\S+@\S+\.\S+/.test(email))) {
            setValidEmail(false)
            setValueValidEmail(translation('Введите корректный email', translationStatePasswordRecoveryPage, textsPasswordRecoveryPage))
            return false
        }

        setValidEmail(true)
        setValueValidEmail(null)
    }, [translationStatePasswordRecoveryPage])

    const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
        checkValidEmail(email)
    }

    const postData = () => {
        if(validEmail) {
            /* ЗАПРОС НА ВОССТАНОВЛЕНИЕ ПАРОЛЯ */
        }
    }

    return (
        <SPasswordRecoveryWrap>
            <SEntryRegistrationContent>
                <STitle>
                    <SH2>
                        {translation('ВОССТАНОВЛЕНИЕ ПАРОЛЯ', translationStatePasswordRecoveryPage, textsPasswordRecoveryPage)}
                    </SH2>
                </STitle>
                <SForm
                    action=''
                    method='post'
                    name='form'
                    style={{'width': '90%'}}
                >
                    <SLabel
                        htmlFor='emailPasswordRecovery'
                    >
                        {translation('Укажите email', translationStatePasswordRecoveryPage, textsPasswordRecoveryPage)}
                    </SLabel>
                    <SInput
                        id='emailPasswordRecovery'
                        type='text' 
                        name='emailPasswordRecovery' 
                        placeholder='' 
                        value={email}
                        onChange={(e) => changeEmail(e)}
                    />
                    <p>
                        {translation('Введите свой электронный адрес и мы отправим вам ссылку для восстановления доступа к аккаунту', translationStatePasswordRecoveryPage, textsPasswordRecoveryPage)}
                    </p>
                    <SBtn
                        width={'25vh'} 
                        id='submit'
                        onClick={postData}
                    >
                        {translation('ПОЛУЧИТЬ ССЫЛКУ', translationStatePasswordRecoveryPage, textsPasswordRecoveryPage)}
                    </SBtn>
                </SForm>
            </SEntryRegistrationContent>
        </SPasswordRecoveryWrap>
    )
}