export enum LobbiesActionType {
    SET_UP_LOBBIES = 'SET_UP_LOBBIES'
}

export interface LobbiesState {
    lobbiesListState: any
}

export interface LobbiesAction {
    type: string,
    payload?: any
}
