import {Header} from '../../components/header'
import {AuthLayoutSection} from '../../components/authLayoutSection'
import { NotFound } from '../../components/notFound'

export const NotFoundPage = () => {
    return (
        <>
            <Header rating={false} game={false} logo={true} notFoundPage={true}/>
            <main>
                <AuthLayoutSection game={false}>
                    <NotFound/>
                </AuthLayoutSection>
            </main>
        </>
    )
}