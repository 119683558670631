export const textsMainPage: string[] = [
    /* 0 */'Общий рейтинг',
    /* 1 */'Рейтинг недели',
    /* 2 */'ЯЗЫК',
    /* 3 */'ВЫХОД',
    /* 4 */'Золото',
    /* 5 */'Токены',
    /* 6 */'Капитал',
    /* 7 */'Общий',
    /* 8 */'За неделю',
    /* 9 */'Монополист',
    /* 10 */'ДАННЫЕ',
    /* 11 */'РЕЙТИНГ',
    /* 12 */'МАГАЗИН',
    /* 13 */'БАНК',
    /* 14 */'СОЗДАТЬ ИГРУ',
    /* 15 */'ПРИГЛАСИТЬ ДРУГА',
    /* 16 */'СПИСОК ИГР',
    /* 17 */'ТЕКУЩИЕ ИГРЫ',
    /* 18 */'ТУРНИРЫ',
    /* 19 */'КЛАНЫ',
    /* 20 */'Название игры',
    /* 21 */'Новичок',
    /* 22 */'Менеджер',
    /* 23 */'Бизнесмен',
    /* 24 */'Коммерсант',
    /* 25 */'Банкир',
    /* 26 */'Гранд',
    /* 27 */'Богач',
    /* 28 */'Капиталист',
    /* 29 */'Олигарх',
    /* 30 */'Инвестор',
    /* 31 */'Монополист',
    /* 32 */'Элита',
    /* 33 */'Эксперт',
    /* 34 */'Магнат',
    /* 35 */'Президент',
    /* 36 */'Недоступный',
    /* 37 */'Легенда',
    /* 38 */'Игроки',
    /* 39 */'Поле',
    /* 40 */'Ставки',
    /* 41 */'Вип-карта',
    /* 42 */'ИГРАТЬ',
    /* 43 */'ВЫЙТИ',
    /* 44 */'СМОТРЕТЬ',
    /* 45 */'ВСЕ ИГРОКИ',
    /* 46 */'ДРУЗЬЯ',
    /* 47 */'МОЙ КЛАН',
    /* 48 */'Онлайн',
    /* 49 */'Оффлайн',
    /* 50 */'В игре',
    /* 51 */'ОБЩИЙ ЧАТ',
    /* 52 */'ОТПРАВИТЬ',
    /* 53 */'Ошибка',
    /* 54 */'Ошибка сервера',
    /* 55 */'Не удалось получить данные о пользователе с сервера',
    /* 56 */'Введите ник игрока',
    /* 57 */'Пустое поле название игры',
    /* 58 */'Минимальная длина названия игры не может быть менее 3 символов, максимальная - более 20 символов',
    /* 59 */'Длина пароля менее 8 символов',
    /* 60 */'СТАНДАРТНЫЙ',
    /* 61 */'БОЛЬШОЙ',
    /* 62 */'ГИГАНТСКИЙ',
    /* 63 */'БЕЗ СТАВКИ',
    /* 64 */'кг',
    /* 65 */'Уведомление',
    /* 66 */'СОЗДАТЬ ИГРУ',
    /* 67 */'Название игры',
    /* 68 */'Тип игры',
    /* 69 */'Количество игроков',
    /* 70 */'Игровой стол',
    /* 71 */'Ставка',
    /* 72 */'золото',
    /* 73 */'Пароль',
    /* 74 */'для приватной игры',
    /* 75 */'Без VIP и карт',
    /* 76 */'Без сделок',
    /* 77 */'САДИМСЯ',
    /* 78 */'Вы уверены, что хотите сесть за стол',
    /* 79 */'ПРИНЯТЬ',
    /* 80 */'ОТМЕНА',
    /* 81 */'ВЫХОДИМ',
    /* 82 */'Вы уверены, что хотите выйти',
    /* 83 */'ДА',
    /* 84 */'НЕТ',
    /* 85 */'В чате запрещено',
    /* 86 */'Мат и оскорбления',
    /* 87 */'Публичные конфликты',
    /* 88 */'Оскорбительный ник или аватар',
    /* 89 */'Общая громкость'
]   