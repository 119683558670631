import { combineReducers } from 'redux'

import { entryRegistrationReducer } from './entryRegistrationReducer'
import { activeChatUserBtnReducer } from './activeChatUserBtnReducer'
import { activeChatGameBtnReducer } from './activeChatGameBtnReducer'
import { lobbiesReducer } from './lobbiesReducer'
import { newGameModalReducer } from './newGameModalReducer'
import { menuButtonsReducer } from './menuButtonsReducer'
import { playersReducer } from './playersReducer'
import { languageReducer } from './languageReducer'
import { languageModaReducer } from './languageModalReducer'
import { volumeModalReducer } from './volumeModalReducer'
import { lobbieDataReducer } from './lobbieDateReducer'
import { customTooltipReducer } from './customTooltipReducer'
import { readyToPlayModalReducer } from './readyToPlayModalReducer'
import { auctionModalReducer } from './auctionModalReducer'
import { noMoneyModalReducer } from './noMoneyModalReducer'
import { youWinModalReducer } from './youWinModalReducer'
import { youLostModalReducer } from './youLostReduser'
import { makeADealModalReducer } from './makeADealModalReducer'
import { giveUpModalReducer } from './giveUpModalReducer'
import { auctionDataReducer } from "./auctionDataReducer";
import { menuButtonsHideReducer } from "./menuButtonsHideReducer";
import { chatlobbyReducer } from "./chatlobbyReducer";
import { chatInfoModaReducer } from './chatInfoModalReducer'
import { devModalReducer } from './devModalReducer'
import { activeTabGameListReducer } from './activeTabGameListReducer'
import { activeTabUserListReducer } from './activeTabUserListReducer'
import { chatUserReducer } from './chatUserReducer'
import { currentUserReducer } from './currentUserReducer'
import { youLeaveModalReducer } from './youLeaveReducer'
import { translationReducer } from './translationReducer'
import { infoModalReducer } from './infoModalReducer'

export const rootReducer = combineReducers({
    entryRegistrationState: entryRegistrationReducer,
    activeChatUserBtnState: activeChatUserBtnReducer,
    activeChatGameBtnState: activeChatGameBtnReducer,
    lobbiesReducer: lobbiesReducer,
    newGameModalState: newGameModalReducer,
    menuButtonsReducer: menuButtonsReducer,
    playersReducer: playersReducer,
    languageState: languageReducer,
    languageModalState: languageModaReducer,
    volumeModalState: volumeModalReducer,
    lobbieDataListState: lobbieDataReducer,
    customTooltipState: customTooltipReducer,
    readyToPlayModalState: readyToPlayModalReducer,
    auctionModalState: auctionModalReducer,
    noMoneyModalState: noMoneyModalReducer,
    youWinModalState: youWinModalReducer,
    youLostModalState: youLostModalReducer,
    makeADealModalState: makeADealModalReducer,
    giveUpModalState: giveUpModalReducer,
    auctionDataReducer: auctionDataReducer,
    menuButtonsHideReducer: menuButtonsHideReducer,
    chatlobbyReducer: chatlobbyReducer,
    chatInfoModalState: chatInfoModaReducer,
    devModalState: devModalReducer,
    activeTabGameListState: activeTabGameListReducer,
    activeTabUserListState: activeTabUserListReducer,
    chatUserState: chatUserReducer,
    currentUserState: currentUserReducer,
    youLeaveModalState: youLeaveModalReducer,
    translationStateAuthPage: translationReducer,
    translationStateGameChatPage: translationReducer,
    translationStateGamePage: translationReducer,
    translationStateMainPage: translationReducer,
    translationStateNotFoundPage: translationReducer,
    translationStatePasswordRecoveryPage: translationReducer,
    infoModalState: infoModalReducer
})

export type RootState = ReturnType<typeof rootReducer>
