export enum MakeADealModalActionType {
    OPEN_MAKE_A_DEAL_MODAL = 'OPEN_MAKE_A_DEAL_MODAL',
    CLOSE_MAKE_A_DEAL_MODAL = 'CLOSE_MAKE_A_DEAL_MODAL',
    SET_UP_DEAL_DETAILS = 'SET_UP_DEAL_DETAILS'
}

export interface MakeADealModalState {
    makeADealModalState: boolean,
    makeADealModalDataState: any,
    dealDetails: any
}

export interface MakeADealModalAction {
    type: string,
    payload?: any
}