export enum CurrentUserActionType {
    SET_UP_CURRENT_USER = 'SET_UP_CURRENT_USER'
}

export interface CurrentUserState {
    currentUserState: any
}

export interface CurrentUserAction {
    type: string,
    payload?: any
}