import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { closeDevModal } from '../../store/actionCreators/devModal'

import {
    SP,
    SBtn
} from '../readyToPlayModal/style'
import { 
    SModalWrap,
    STitle,
    SH2,
    SCloseModalBtn,
    SGreyWrap
} from '../newGameModal/style'
import { SBlock } from './style'

export const DevModal = () => {

    const dispatch = useDispatch()

    const {devModalState} = useTypedSelector(state => state.devModalState)

    return (
        <SModalWrap
            /* padding={'33vh 40.5vw'} */
            showModal={devModalState}
        >
            <SBlock>
                <STitle>
                    <SH2>
                        В РАЗРАБОТКЕ
                    </SH2>
                    <SCloseModalBtn
                        onClick={() => dispatch(closeDevModal())}
                    >
                        <span/>
                        <span/>
                    </SCloseModalBtn>
                </STitle>
                <SP
                    marginTop='8vh'
                    style={{'padding': '0 1vw'}}
                >
                    Внимание! Этот блок находится в разработке и пока недоступен. Мы ценим ваше терпение и благодарим за понимание.
                </SP>
                <SGreyWrap
                    style={{'gap' : '2vh'}}
                >
                    <SBtn
                        color='green'
                        onClick={() => dispatch(closeDevModal())}
                        style={{'minWidth': '80px'}}
                    >
                        OK
                    </SBtn>
                </SGreyWrap>
            </SBlock>
        </SModalWrap>
    )
}