import styled, { css, keyframes } from 'styled-components'

import gamersBG from '../../assets/img/gamersBG.jpg'
import banknotesIcon from '../../assets/icons/banknotes.svg'

export const SGameWrap = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    gap: 1vw;
`

export const SGamersField = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20vw;
    background: url(${gamersBG}) center center/cover no-repeat;
    border-right: 0.5vh solid rgba(0, 0, 0, 0.6);
    margin-left: 1.5vw;
    padding-top: 7vh;
    gap: 1vh;
`

export const SGamersWpap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
    padding: 0 1vw;
`

export const SDealButton = styled.button<{colors: string[]}>`
    width: 9vw;
    height: 3vh;
    border-radius: 1vh;
    color: #FFF;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: ${({colors}) => `0.5px solid ${colors[3]}`};
    background: ${({colors}) => `linear-gradient(270deg, ${colors[0]} 0%, ${colors[1]} 52.69%, ${colors[2]} 100%)`};
    /* box-shadow: ${({colors}) => `0.1vh 0.1vh 1vh ${colors[3]}`}; */
`

const banknotesCss = css<{banknotes?: boolean}>`
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: ${({banknotes}) => banknotes ? 'flex' : 'none'};
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 15;
        background: url(${banknotesIcon}) center center/80% no-repeat;
        border-radius: inherit;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: ${({banknotes}) => banknotes ? 'flex' : 'none'};
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 12;
        background: rgba(255, 255, 255, 0.5);
        border-radius: inherit;
    }
`

export const SGamerCard = styled.div<{colors: string[], banknotes?: boolean}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 12vw;
    height: 14vh;
    border-radius: 0.8vh;
    border: ${({colors}) => `1px solid ${colors[3]}`};
    background: ${({colors}) => `linear-gradient(0deg, ${colors[0]} 0%, ${colors[1]} 52.69%, ${colors[2]} 100%)`};
    ${({banknotes}) => banknotes ? banknotesCss : null}
`

const progressCss = css<{colors: string[], progress?: number}>`
    &::after {
        content: '';
        left: 0;
        top: 0;
        width: ${({progress}) => progress ? `${progress}%` : '100%'};
        height: 100%;
        position: absolute;
        background: ${({colors}) => `linear-gradient(0deg, ${colors[0]} 0%, ${colors[1]} 52.69%, ${colors[2]} 100%)`};
        transform: translateZ(0px);
    }
`

const normalBg = css<{colors: string[], progress?: number}>`
    background: ${({colors}) => `linear-gradient(0deg, ${colors[0]} 0%, ${colors[1]} 52.69%, ${colors[2]} 100%)`};
`

export const SRang = styled.h2<{colors: string[], progress?: number}>`
    position: relative;
    font-size: 1.8vmin;
    padding: 0.7vh 0 0.7vh 0;
    height: 3vh;
    width: 100%;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.8vh 0.8vh 0 0;
    box-shadow: 0px 0.4vh 0.4vh rgba(0, 0, 0, 0.25);
    background: rgba(30, 30, 30, 1);
    overflow: hidden;

    ${({progress}) => progress || progress===0 ? progressCss : normalBg}

    &::before {
        content: attr(data-rang);
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
`

export const SWpap = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1vh 1vh 1vh 1.5vh;
    gap: 1vh;
    color: #000;
`

const secondsCss = css<{seconds?: number}>`
    &::before {
        content: attr(data-seconds);
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 0.7vh;
        color: #fff;
        font-size: 3vmin;
    }
`

export const SAvatarWrap = styled.div<{seconds?: number}>`
    position: relative;

    ${({seconds}) => seconds ? secondsCss : null}
`

export const SAvatar = styled.img`
    position: relative;
    height: 9vh;
    border-radius: 0.7vh;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.50);
`

export const SStatsWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 7vw;
    height: 100%;

    >hr {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.7);
    }
`

export const SCommonCapital = styled.span`
    width: 100%;
    position: relative;
    text-align: center;
    margin-top: 1vh;

    >img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 1.25vh;
    }
`

export const SWeeklyCapital = styled.span`
    width: 100%;
    position: relative;
    text-align: center;
    margin-top: 0.5vh;
    
    >img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 1vh;
    }
`

export const SIframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`

export const SIframeWpap = styled.div`
    width: 100%;
    height: 100%;
    padding: 8vh 0 5vh 0;
`

export const SIframeChatGame = styled.iframe`
    position: absolute;
    border: none;
    width: 50vw;
    height: 48vh;
    z-index: 1;
    top: 27vh;
    left: 24vw;
`

const shineAnimationColor1 = keyframes`
    0% {
        box-shadow: 0 0 1.5vh #273DFF;
    }
    50% {
        box-shadow: 0 0 3vh #273DFF;
    }
    100% {
        box-shadow: 0 0 1.5vh #273DFF;
    }
`

const shineAnimationColor2 = keyframes`
    0% {
        box-shadow: 0 0 1.5vh #D71010;
    }
    50% {
        box-shadow: 0 0 3vh #D71010;
    }
    100% {
        box-shadow: 0 0 1.5vh #D71010;
    }
`

const shineAnimationColor3 = keyframes`
    0% {
        box-shadow: 0 0 1.5vh #50D710;
    }
    50% {
        box-shadow: 0 0 3vh #50D710;
    }
    100% {
        box-shadow: 0 0 1.5vh #50D710;
    }
`

const shineAnimationColor4 = keyframes`
    0% {
        box-shadow: 0 0 1.5vh #FD891A;
    }
    50% {
        box-shadow: 0 0 3vh #FD891A;
    }
    100% {
        box-shadow: 0 0 1.5vh #FD891A;
    }
`

const shineAnimationColor5 = keyframes`
    0% {
        box-shadow: 0 0 1.5vh #7F10D7;
    }
    50% {
        box-shadow: 0 0 3vh #7F10D7;
    }
    100% {
        box-shadow: 0 0 1.5vh #7F10D7;
    }
`

export const SAnimationWrap = styled.div<{colors: number}>`
    margin-top: 2vh;
    transform-style: preserve-3d;
    border-radius: 0.8vh;
    animation: ${({colors}) => {
        switch(colors) {
            case 1: 
                return css`infinite ${shineAnimationColor1} 1500ms`
            case 2: 
                return css`infinite ${shineAnimationColor2} 1500ms`
            case 3: 
                return css`infinite ${shineAnimationColor3} 1500ms`
            case 4: 
                return css`infinite ${shineAnimationColor4} 1500ms`
            case 5: 
                return css`infinite ${shineAnimationColor5} 1500ms`
            default:
                return css`infinite ${shineAnimationColor1} 1500ms`
        }
    }};
`