import React, { Component } from 'react';
import { useEffect } from 'react';

import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from '../globalStyles'

import { AuthorizationPage } from '../pages/authorizationPage'
import { MainPage } from '../pages/mainPage'
import { GamePage } from '../pages/gamePage'
import { GameChatPage } from '../pages/gameChatPage'
import { NotFoundPage } from '../pages/notFoundPage'
import { PasswordRecoveryPage } from '../pages/passwordRecoveryPage'
class App extends Component {
	constructor(props: any) {
        super(props)
        
        const savedLanguageState = localStorage.getItem('languageState')
		const savedVolumeStare = localStorage.getItem('volumeState')

        if (!savedLanguageState) {
            localStorage.setItem('languageState', JSON.stringify('RU'))
        }

		if (!savedVolumeStare) {
			localStorage.setItem('volumeState', JSON.stringify('50'))
		}
    }
  
	render() {
		return (
			<>
				<GlobalStyle/>
			
				<BrowserRouter>
					<Routes>
						<Route path='/' element={<Navigate to='/login'/>}/>
						<Route path='/login' element={<AuthorizationPage/>}/>
						<Route path='/user' element={<MainPage/>}/>
						<Route path='/game' element={<GamePage/>}/>
						<Route path='/gamechat' element={<GameChatPage/>}/>
						<Route path='/password' element={<PasswordRecoveryPage/>}/>
						<Route path='*' element={<NotFoundPage/>}/>
					</Routes>
				</BrowserRouter>
			</>
		)
	}
}
  
export default App