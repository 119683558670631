import {PlayersState, PlayersAction, PlayersActionType} from '../../types/playersReduserType'

const initialState: PlayersState = {
    PlayersListState: {}
}

export const playersReducer = (state = initialState, action: PlayersAction): PlayersState => {
    switch(action.type) {
        case PlayersActionType.SET_UP_PLAYERS:
            return {
                ...state,
                PlayersListState: action.payload
            }
        default:
            return state
    }
}
