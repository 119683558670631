import {Header} from '../../components/header'
import {AuthLayoutSection} from '../../components/authLayoutSection'
import {GameContent} from '../../components/gameContent'
import ConnectSocket from "../../sockets/lobbie/createConnectionSocket";
import {useMemo} from "react";
import { Preloader } from '../../components/preloader'

let isInitial = false;
export const GamePage = () => {

    useMemo(() => {
        ConnectSocket();
    }, []);

    return (
        <>
            <Header rating={false} game={true} logo={false}/>
            <main>
                <AuthLayoutSection game={true}>
                    {/* <Preloader loading={60}/> */}
                    <GameContent/>
                </AuthLayoutSection>
            </main>
        </>
    )
}