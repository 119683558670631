import {
    useEffect,
    useCallback
} from 'react'

import WTSServise from '../../services/WTSServise'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
    changeValueEntry,
    changeValueRegistration
} from '../../store/actionCreators/valueEntryRegistration'
import { setUpTranslateAuthPage } from '../../store/actionCreators/translation'
import { setCustomTooltipState } from '../../store/actionCreators/customTooltip'

import { EntryContent } from '../entryContent'
import { RegistrationContent } from '../registrationContent'

import { textsAuthPage } from './config'
import { translation } from '../../utils/translation'

import {
    SWrap,
    SButtonFormTab,
    SEntryRegistrationContent
} from './style'

export const AuthorizationContent = () => {

    const dispatch = useDispatch()

    const { entryRegistrationState } = useTypedSelector(state => state.entryRegistrationState)
    const { translationStateAuthPage } = useTypedSelector(state => state.translationStateAuthPage)
    const { languageState } = useTypedSelector(state => state.languageState)

    useEffect(() => {
        updateTranslate()
    }, [languageState])
    
    const updateTranslate = async () => {
        try {
            const response = await WTSServise.translations(textsAuthPage, languageState)
            let translateArr: string[] = []
            response.data.data.phraseTranslations.map((item: any) => {
                if(item.phrase.translation) {
                    translateArr.push(item.phrase.translation.text)
                    return
                } else {
                    translateArr.push(item.phrase.text)
                    return
                }
            })

            dispatch(setUpTranslateAuthPage(translateArr))
        } catch (e: any) {
            if (!e) return
            
            if (e.request.status === 400) {
                dispatch(setCustomTooltipState({
                    status: 'error',
                    titleText: translation('Ошибка', translationStateAuthPage, textsAuthPage),
                    text: translation('Ошибка сервера', translationStateAuthPage, textsAuthPage)
                }))
                return
            }
            
            console.log(e)
        }
    }

    const textSocialButtons = useCallback((id: string) => {
        switch(id) {
            case 'guestAccountBtn':
                return `${translation('Гостевой аккаунт', translationStateAuthPage, textsAuthPage)} (${translation('Только просмотр', translationStateAuthPage, textsAuthPage)})`
            case 'googleBtn':
                return 'Google'
            case 'appleBtn':
                return 'Apple'
            case 'facebookBtn':
                return 'Facebook'
            case 'yandexBtn': 
                return 'Yandex'
            default:
                return null
        }
    }, [translationStateAuthPage])

    const content = entryRegistrationState === 'entry' 
        ? <EntryContent
            textSocialButtonsFun={textSocialButtons}
          /> 
        : <RegistrationContent
            textSocialButtonsFun={textSocialButtons}
          />

    return (
        <SWrap>
            <SButtonFormTab
                id='registrationTab'
                onClick={() => dispatch(changeValueRegistration())}
                className={entryRegistrationState === 'registration' ? 'active' : undefined}   
            >
                {translation('РЕГИСТРАЦИЯ', translationStateAuthPage, textsAuthPage)}
            </SButtonFormTab>
            <SButtonFormTab 
                id='entryButtonTab'
                onClick={() => dispatch(changeValueEntry())}
                className={entryRegistrationState === 'entry' ? 'active' : undefined}
                left={true}
            >
                {translation('ВХОД', translationStateAuthPage, textsAuthPage)}
            </SButtonFormTab>
            <SEntryRegistrationContent>
                {content}
            </SEntryRegistrationContent>
        </SWrap>
    )
}
