import styled, { css } from 'styled-components'

import { device } from '../../theme'

const mainPagePozition = css`
    right: 3.3vh;
    top: 7vh;
`

const gamePagePozition = css`
    right: 9vw;
    top: 7vh;
`

export const SLanguageModal = styled.div<{game: boolean, languageModalState: boolean}>`
    ${({game}) => game ? gamePagePozition : mainPagePozition}
    
    position: absolute;
    display: ${({languageModalState}) => languageModalState ? 'flex' : 'none'};
    transform-style: preserve-3d;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
        #1A1A1A;

    &::before {
        content: '';
        top: -1px;
        left: -1px;
        position: absolute;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background: linear-gradient(rgba(255, 255, 255, 100), rgba(255, 253, 252, 0));
        border-radius: inherit;
        transform: translateZ(-1px);
    }

    @media (${device.tablet}) {
        right: 12vw;
        top: 1vh;
    }
`

export const SUl = styled.ul`
    flex-direction: column;
    justify-content: center;
    width: 20vh;
    padding: 0.5vh 1vh 1vh 1vh;
    
    >li{
        display: flex;
        flex-direction: column;
        margin-top: 0.5vh;
        gap: 0.5vh;

        >hr{  
            height: 0;
            width: 100%;
            border-top: 1px solid #000;
            border-bottom: 1px solid #D9D9D9;
            opacity: 0.2;
        }
    }
`

export const SLanguage = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2vh;
    width: 100%;
    font-size: 1.8vmin;
    color: #C5C5C5;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    >img {
        height: 100%;
    }
`