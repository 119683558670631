import {LobbiesState, LobbiesAction, LobbiesActionType} from '../../types/lobbiesReduserType'

const initialState: LobbiesState = {
    lobbiesListState: {}
}

export const lobbiesReducer = (state = initialState, action: LobbiesAction): LobbiesState => {
    switch(action.type) {
        case LobbiesActionType.SET_UP_LOBBIES:
            return {
                ...state,
                lobbiesListState: action.payload
            }
        default:
            return state
    }
}
