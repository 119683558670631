import styled from 'styled-components'

import thumb from '../../assets/icons/thumb.svg'

export const SP = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.5vh;
    text-align: center;
    font-size: 2vmin;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    >span{
        color: #FFC93D;
    }
`

export const SDealIcon = styled.img`
    position: absolute;
    left: 2vh;
    top: 50%;
    transform: translateY(-50%);
    height: 2vh;
`

export const STimer = styled.span`
    position: absolute;
    display: flex;
    align-items: center;
    right: 1vh;
    width: 3.5vw;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    font-size: 2.5vmin;
`

export const SUsersWrap = styled.ul`
    display: flex;
    width: 100%;
    height: 33.5vh;
`

export const SUser = styled.li<{colors: string[]}>`
    width: 100%;
    height: 100%;
    background: ${({colors}) => `linear-gradient(0deg, ${colors[0]} 0%, ${colors[1]} 52.69%, ${colors[2]} 100%)`};
`

export const SContentUserWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0.5vh;
`

export const SStatsWrap = styled.div`
    display: grid;
    width: 100%;
    grid-template: 2vh 2vh 0.5vh 3.2vh 0.5vh 3.2vh / 1fr 1vh 3fr;
    grid-template-areas: 
        'avatar . nickname'
        'avatar . rang'
        'avatar . .'
        'avatar . totalCapital'
        'avatar . .'
        'avatar . weeklyCapital';
    padding: 1.5vh 2vh;
`

export const SAvatar = styled.div<{vip: boolean}>`
    grid-area: avatar;
    position: relative;
    border-radius: 0.8vh;
    transform-style: preserve-3d;
    width: 10vh;
    height: 11vh;

    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(10vh + 4px);
        height: calc(11vh + 4px);
        background: 
            linear-gradient(-45deg, rgba(255, 168, 0, 1), rgba(255, 169, 2, 0)), 
            linear-gradient(-135deg, rgba(255, 205, 147, 1), rgba(226, 187, 136, 1));
        border-radius: inherit;
        transform: translateZ(-1px);
    }
`

export const SVip = styled.img`
    position: absolute;
    width: 6vh;
    height: 6vh;
    top: -1.5vh;
    right: -1.8vh; 
`

export const SNickName = styled.span`
    grid-area: nickname;
    width: 100%;
    text-align: center;
`

export const SRang = styled.span`
    grid-area: rang;
    width: 100%;
    text-align: center;
    color: #FFC93D;
`

export const STransparentBlock = styled.div<{gridArea: string}>`
    grid-area: ${({gridArea}) => gridArea};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.2vh;
    width: 100%;
    border-radius: 0.8vh;
    background: rgba(0, 0, 0, 0.20);
    box-shadow: 
        0px 0.4vh 1vh 0px rgba(0, 0, 0, 0.70) inset, 
        0px 0.4vh 1vh 0px rgba(0, 0, 0, 0.50);
    border: 2px solid rgba(205, 205, 205, 0.4);
    
    >span{
        position: relative;
        color: #FFC93D;

        >img{
            position: absolute;
            left: -2vh;
            top: 50%;
            transform: translateY(-50%);
            height: 1.25vh;
        }
    }
`

export const SItemWrap = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
    padding: 0 0.5vh 0 0;
    overflow-y: scroll;
    scrollbar-color: #126D11 rgba(0, 0, 0, 0.30);
    scrollbar-width: auto;
    width: 100%;
    height: 15vh;

    ::-webkit-scrollbar {
        width: 1.5vh;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.15);
    }
`

export const SItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 3vh;
    padding: 0 1vh;
    width: 100%;
    background: 
        linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.00) 46.88%), 
        linear-gradient(360deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.15) 65.63%, 
        rgba(0, 0, 0, 0.14) 100%);

    >span{
        opacity: 1;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    >span:nth-child(2){
        color: #FFC93D;
    }
`

export const SChangeSurchargeBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 5vh;
`

export const SSurchargeWrap = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1vh;

    >span{
        position: absolute;
        top: 1vh;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1.8vmin;
    }
`

export const InputSurcharge = styled.input`
    margin-top: 0.7vh;
    width: 100%;
    -webkit-appearance: none;
    background: rgba(255, 255, 255, 0.6);
    margin-bottom: 2vh;

    &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        height: 1vh;
        background: rgba(0, 0, 0, 0.9);
        box-shadow: 
            0px 1px 0px #D9D9D9, 
            1px 0px 0px #D9D9D9, 
            -1px 0px 0px #D9D9D9, 
            0px 0px 40px 0px rgba(0, 0, 0, 0.20) inset;
        border-radius: 1vh;
    }
    &::-moz-range-track{
        -webkit-appearance: none;
        height: 1vh;
        background: rgba(0, 0, 0, 0.9);
        box-shadow: 
            0px 1px 0px #D9D9D9, 
            1px 0px 0px #D9D9D9, 
            -1px 0px 0px #D9D9D9, 
            0px 0px 40px 0px rgba(0, 0, 0, 0.20) inset;
        border-radius: 1vh;
    }

    &::-webkit-slider-thumb {
        margin-top: -0.5vh;
        -webkit-appearance: none;
        height: 3vh;
        width: 2vh;
        background: url(${thumb}) center center/cover no-repeat;
        cursor: ew-resize;
    }
    &::-moz-range-thumb {
        -webkit-appearance: none;
        height: 3vh;
        width: 2vh;
        background: url(${thumb}) center center/cover no-repeat;
        cursor: ew-resize;
        border: none;
    }
`

export const SPSurcharge = styled.p`
    position: relative;
    font-size: 1.8vmin;
`

export const SBtnWrap = styled.div`
    display: flex;
    justify-content: center;
    gap: 3vh;
`

export const SBlock = styled.div<{gridArea?: string, gamePage?: boolean, colors?: string[][], activeBtn?: number, showModal: boolean}>`
    display: ${({showModal}) => showModal ? 'block' : 'none'};
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.7vh;
    background: #1A1A1A;
    transform-style: preserve-3d;
    grid-area: ${({gridArea}) => gridArea};
    
    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: ${({gamePage, colors, activeBtn}) => gamePage ? 
            (activeBtn ? 
                `linear-gradient(0deg, ${colors![activeBtn!][0]} 0%, ${colors![activeBtn!][1]} 52.69%, ${colors![activeBtn!][2]} 100%)` 
                    : 
                'linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1))')
                :
            'linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1))'};
        border-radius: inherit;
        transform: translateZ(-2px);
    }
    
`