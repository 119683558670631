import styled from 'styled-components'

import { device } from '../../theme'

import userCardBG from '../../assets/img/userCardBG.jpg'
import userCardBtnBG from '../../assets/img/userCardBtnBG.jpg'
import userCardRangBG from '../../assets/img/userCardRangBG.jpg'

export const SUserCardWrap = styled.div`
    position: relative;
    grid-area: userCard;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: 0.7vh;
    background: rgba(0, 0, 0, 0.50);
    transform-style: preserve-3d;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: url(${userCardBG}) center center/cover no-repeat;
        transform: translateZ(-1px);
    }

    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1));
        border-radius: inherit;
        transform: translateZ(-2px);
    }

    @media (${device.tablet}) {
        &::before {
            top: -1px;
            left: -1px;
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }

    /* @media (orientation: landscape) and (${device.tablet}) {
        min-height: 50vh;
    } */
`
export const SNickName = styled.h2`
    width: 100%;
    height: 4vh;
    text-align: center;
    padding-top: 0.7vh;
    font-size: 2vmin; 
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
        #1A1A1A;
    border-radius: 0.7vh 0.7vh 0 0;

    @media (${device.tablet}) {
        font-size: 12px;
    }
`

export const SWrapCardContentGrid = styled.div`
    display: grid;
    justify-items: center;
    grid-template: 1vh 2vh 0.4vh 2.5vh 2vh 2vh 0.4vh 2.5vh 1vh 2.5vh 2vh / 2.5vh 1fr 2.5vh 1fr 2vh 1fr 2.5vh;
    grid-template-areas: 
        '. . . . . . .'
        '. avatar . gold . tokens .'
        '. avatar . . . . .'
        '. avatar . goldValue . totensValue .'
        '. avatar . . . . .'
        '. avatar . capital capital capital .'
        '. avatar . . . . .'
        '. avatar . totalCapital totalCapital totalCapital .'
        '. avatar . . . . .'
        '. avatar . weeklyCapital weeklyCapital weeklyCapital .'
        '. . . . . . .';

    @media (${device.mobileL}) {
        grid-template: 1vh 2vh 0.4vh 2.5vh 2vh 2vh 0.4vh 2.5vh 1vh 2.5vh 2vh / 1.5vh 1fr 1.5vh 1fr 1vh 1fr 1.5vh;
        grid-template-areas: 
            '. . . . . . .'
            '. avatar . gold . tokens .'
            '. avatar . . . . .'
            '. avatar . goldValue . totensValue .'
            '. avatar . . . . .'
            '. avatar . capital capital capital .'
            '. avatar . . . . .'
            '. avatar . totalCapital totalCapital totalCapital .'
            '. avatar . . . . .'
            '. avatar . weeklyCapital weeklyCapital weeklyCapital .'
            '. . . . . . .';
    }
`

export const SWrapAvatar = styled.div`
    margin-top: 1vh;
    grid-area: avatar;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

export const SAvatar = styled.div`
    position: relative;
    border-radius: 0.8vh;
    transform-style: preserve-3d;
    height: 10vh;
    width: 10vh;

    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: 
            linear-gradient(-45deg, rgba(255, 168, 0, 1), rgba(255, 169, 2, 0)), 
            linear-gradient(-135deg, rgba(255, 205, 147, 1), rgba(226, 187, 136, 1));
        border-radius: inherit;
        transform: translateZ(-1px);
    }
`

export const SAva = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 0.8vh;
`

export const SVip = styled.img`
    position: absolute;
    width: 5vh;
    height: 5vh;
    top: -1.2vh;
    right: -1.5vh;
`

export const SRank = styled.button`
    margin-top: 1.2vh;
    width: 12vh;
    height: 2.5vh;
    position: relative;
    transform-style: preserve-3d;
    border-radius: 2vh;
    background: url(${userCardRangBG}) center center/cover no-repeat;

    &::before {
        content: '';
        left: -2px;
        top: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        position: absolute;
        background: 
            linear-gradient( rgba(255, 154, 61, 0.17), rgba(255, 154, 61, 1)), 
            linear-gradient(360deg, rgba(0, 0, 0, 0.81), rgba(0, 0, 0, 0)), 
            linear-gradient(rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0));
        transform: translateZ(-2px);
        border-radius: inherit;
    }

    @media (${device.tablet}) {
        &::before {
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }
    
`

export const SContentRank = styled.span<{progress: string}>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12vh;
    height: 2.5vh;
    background: rgba(0, 0, 0, 0.50);
    transform-style: preserve-3d;
    transform: translateZ(1px); 
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    font-size: 1.2vmin;
    border-radius: 2vh;
    color: #FFA724;

    &::after {
        content: '';
        left: 2px;
        top: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        position: absolute;
        background: ${({progress}) => `linear-gradient(to right, #117600 ${progress}%, rgba(255, 255, 255, 0) ${progress}%)`};
        transform: translateZ(-1px);
        border-radius: inherit;
    }

    @media (${device.tablet}) {
        font-size: 11px;
    }
`

export const SNameIndicator = styled.span<{gridArea: string}>`
    grid-area: ${({gridArea}) => gridArea};
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    font-weight: 600;

    @media (${device.tablet}) {
        font-size: 11px;
    }
`

export const SRatingUserCard = styled.button<{gridArea: string, justifyContent?: string}>`
    position: relative;
    display: flex;
    justify-content: ${({justifyContent}) => justifyContent ? justifyContent : 'center'};
    align-items: center;
    grid-area: ${({gridArea}) => gridArea};
    border-radius: 0.8vh;
    color: #F4E6C2;
    width: 100%;
    height: 2.5vh;
    font-size: 1.4vmin;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    font-weight: 700;
    background: rgba(0, 0, 0, 0.40);
    box-shadow: 
        0px 0px 5vh 0px rgba(0, 0, 0, 0.70) inset, 
        0px 0.4vh 1vh 0px rgba(0, 0, 0, 0.50);
    border-radius: 0.7vh;
    padding: 0 2vw;
    transform-style: preserve-3d;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: url(${userCardBtnBG}) center center/cover no-repeat;
        transform: translateZ(-1px);
    }

    &::before {
        content: '';
        left: -2px;
        top: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        position: absolute;
        background: 
            linear-gradient( rgba(255, 154, 61, 0.17), rgba(255, 154, 61, 1)), 
            linear-gradient(360deg, rgba(0, 0, 0, 0.81), rgba(0, 0, 0, 0)), 
            linear-gradient(rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0));
        z-index: -1;
        border-radius: inherit;
        transform: translateZ(-2px);
    }

    &:hover {
        >div {
            background: #FEF478;
        }
    }

    @media (${device.tablet}) {
        font-size: 10px;
        &::before {
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }
`

export const SPlusWrap = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 1.6vh;
    height: 1.6vh;
    background: #FFEC00;
    border-radius: 0.5vh;
    box-shadow: 0px 0.2vh 0px 0px #9E4606;
    
    > span {
        position: absolute;
        width: 1vh;
        height: 3px;
        background: #7D1A01;
        border-radius: 0.2vh;
    }

    > span:first-child {
        transform: rotate(-90deg);
    }
`