import { YouLeaveModalActionType, YouLeaveModalState, YouLeaveModalAction } from '../../types/youLeaveModalType'

const initialState: YouLeaveModalState = {
    youLeaveModalState: false
}

export const youLeaveModalReducer = (state = initialState, action: YouLeaveModalAction): YouLeaveModalState => {

    switch(action.type) {
        case YouLeaveModalActionType.OPEN_YOU_LEAVE_MODAL:
            return {
                ...state,
                youLeaveModalState: true
            }
        case YouLeaveModalActionType.CLOSE_YOU_LEAVE_MODAL:
            return {
                ...state,
                youLeaveModalState: false
            }
        default:
            return state
    }   
}