import { Header } from '../../components/header'
import { AuthLayoutSection } from '../../components/authLayoutSection'
import { PasswordRecovery } from '../../components/passwordRecovery'

export const PasswordRecoveryPage = ()  => (
    <>
        <Header rating={false} game={false} logo={true} authorizationPage={true}/>
        <main>
            <AuthLayoutSection game={false}>
                <PasswordRecovery/>
            </AuthLayoutSection>
        </main>
    </>
)