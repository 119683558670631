import styled from 'styled-components'

import { device } from '../../theme'

export const SP = styled.p<{marginTop?: string}>`
    width: 100%;
    text-align: center;
    font-size: 1.7vmin;
    margin-top: ${({marginTop}) => marginTop};
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    
    >span{
        color: #FFC93D;
    }

    @media (${device.mobileL}) {
        font-size: 10px;
    }
`

export const SBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vh;
    height: 3.5vh;
    width: 8vw;
    border-radius: 1vh;
    border: 1px solid rgba(255, 255, 255, 0.5);
    transform-style: preserve-3d;
    color: #060606;
    background: ${({color}) => {
        switch(color) {
            case 'green':
                return 'linear-gradient(180deg, #06AE17 0%, #126B10 52.69%, #0B6209 100%)'
            case 'yellow': 
                return 'linear-gradient(180deg, #EDAE00 0%, #DE8400 52.69%, #D06700 100%)'
            default: 
                return null
        }
    }};
    
    &::after {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border-radius: inherit;
        transform: translateZ(1px);
    }

    &::before {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        background: linear-gradient(rgba(29, 29, 29, 1), rgba(77, 77, 77, 1));
        border-radius: inherit;
        transform: translateZ(-1px);
        box-shadow: 0px -0.2vh 0.2vh rgba(125, 125, 125, 0.50);
    }

    &:hover {
        &::after {
            background: rgba(0, 0, 0, 0.2);
        }
    }

    &:focus {
        &::after {
            background: rgba(255, 255, 255, 0.2);
        }
    }

    @media (${device.mobileL}) {
        font-size: 10px;
    }
`