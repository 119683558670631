import {ChatlobbyState, ChatlobbyAction, ChatlobbyActionType} from '../../types/chatlobbyReducerType'

const initialState: ChatlobbyState = {
    ChatlobbyListState: []
}

export const chatlobbyReducer = (state = initialState, action: ChatlobbyAction): ChatlobbyState => {
    switch(action.type) {
        case ChatlobbyActionType.SET_UP_CHATLOBBY:
            return {
                ...state,
                ChatlobbyListState: action.payload,
            }

        case ChatlobbyActionType.UPDATE_CHATLOBBY:
            return {
                ...state,
                ChatlobbyListState: state.ChatlobbyListState ? state.ChatlobbyListState.concat(action.payload) : [action.payload],
            }

        default:
            return state
    }
}
