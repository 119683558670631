import styled from 'styled-components'

export const SH2 = styled.h2`
   text-align: center;
   text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
   font-size: 3.2vmin;
   background:
      linear-gradient(rgba(172, 41, 0, 0), rgba(172, 41, 0, 1) 170%),
      linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%),
      #FF0C0C;
   background-clip: 
      linear-gradient(rgba(172, 41, 0, 0), rgba(172, 41, 0, 1) 170%),
      linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%), rgba(255, 186, 0, 1);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
`

export const SContentWrap = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   padding: 3vh 2vh 0 2vh;
`