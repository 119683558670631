import styled, { css } from 'styled-components'

import scrollLines from '../../assets/icons/scroll_lines.svg'
import gameChatBG from '../../assets/img/gameChatBG.jpg'

export const SChatWrap = styled.main`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
`

export const SChat = styled.div`
    height: calc(91vh - 2px);
    width: calc(100vw - 4px);
`

export const SSendMessageWrap = styled.div` 
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    bottom: 0;
    border-radius: 1.2vh;
    padding: 0.5vh 2vh;
    height: 8vh;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.50);
    gap: 2vh;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
        #212121;
`

export const SInputWrap = styled.div`
    width: 100%;
    height: 6vh;
    border-radius: 0.6vh;
    transform-style: preserve-3d;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 2px);
        background: linear-gradient(360deg, rgba(220, 220, 220, 1), rgba(90, 90, 90, 1));
        border-radius: inherit;
        transform: translateZ(-1px);
    }
`

export const SInput = styled.input`
    width: 100%;
    height: 100%;
    border-radius: 0.6vh;
    background: rgba(0, 0, 0, 0.80);
    color: #FFF;
    padding: 0 2vh;
    font-size: 4vmin;
`

export const SSendMessageBtn = styled.button`
    margin-top: 2px;
    width: 16vw;
    height: 6vh;
    border-radius: 0.8vh;
    font-size: 4vmin;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50);
    transform-style: preserve-3d;
    color: #FFF;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: 
        linear-gradient(180deg, #06AE17 0%, #126B10 52.69%, #0B6209 100%), 
        linear-gradient(180deg, #353535 0%, #343434 52.69%, #282828 100%);

    &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background: linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1));
        border-radius: inherit;
        transform: translateZ(-1px);
    }
`

export const SChatWindow = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
    height: 81vh;
    margin-right: 2vh;
    margin-top: 2vh;
    padding: 0 4vh;
    font-size: 4vmin;
    overflow-y: scroll;
    scrollbar-color: #126D11 rgba(0, 0, 0, 0.30);
    scrollbar-width: auto;

    ::-webkit-scrollbar {
        width: 4vh;
        height: 100%;
        background: rgba(0, 0, 0, 0.70);
    }

    ::-webkit-scrollbar-thumb {
        background: 
            url(${scrollLines}) center center/100% no-repeat, 
            linear-gradient(90deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), 
            linear-gradient(270deg, #2D7500 0%, #276207 42.19%, #132E05 100%);
        border: 1px solid #8EFF59;
        border-radius: 2vh;
        min-height: 15vh;
    }
`
export const Li = styled.li<{idUser: string | null}>`
    color: #ABABAB;
    font-family: Inter;
    font-weight: 400;
    > user {
        color: ${({idUser}) => {
            switch(idUser) {
                case '1':
                    return '#3544FE'
                case '2':   
                    return '#9E0D0D'
                case '3':
                    return '#6BBF34'
                case '4':
                    return '#ED6318'
                case '5':
                    return '#7B12D6'
                default:
                    return null
            }
        }};
    }

    > tile {
        /* color: '#FFF' !important; */
        font-weight: 600;
        background: white;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    > money {
        color: gold;
        font-weight: 600;
    }

    > turns {
        /* color: '#FFF' !important; */
        font-weight: 600;
        background: white;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

const newMsgCss = css<{newMsg?: boolean}>`
    &::after {
        content: attr(data-new-msg);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #FCEDD6;
        font-size: 2.5vmin;
        position: absolute;
        width: 4vh;
        height: 4vh;
        top: -20%;
        right: 10%;
        transform: translateZ(1px);
        background: #B90000;
        border-radius: 100%;
        border: 1px solid #FCEDD6;
        box-shadow: 0px 4px 2vh #D9D9D9;
    }
`

export const SButtonWrap = styled.div`
    display: flex;
    height: 7vh;
    gap: 0.2vh;
`

export const SChatButtonGamePage = styled.button<{gamePage?: boolean, colors?: string[], first?: boolean, newMsg?: boolean}>`
    width: 16vw;
    height: 8vh;
    color: ${({first}) => first ? '#FFF' : '#060606'};
    background: ${({colors}) => colors 
        ? `linear-gradient(270deg, ${colors[0]} 0%, ${colors[1]} 52.69%, ${colors[2]} 100%)` 
        : 'linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), #613A24'};
    font-size: 3vmin;
    padding: 0 2vh 1vh 1vh;
    border-radius: 2vh 9vh 0 0;
    text-align: center;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transform-style: preserve-3d;

    &.active {
        transform: translateZ(1px);
        
        &::before {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            width: calc(16vw + 4px);
            height: calc(8vh + 4px);
            background: ${({colors, first}) => colors ? 
                (first ? 
                    'linear-gradient(-45deg, rgba(255, 89, 36, 100), rgba(255, 162, 41, 100))'
                        :
                    `linear-gradient(20deg, ${colors[0]} 0%, ${colors[1]} 52.69%, ${colors[2]} 100%)`
                ) 
                    :
                'linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), #613A24'
            };
            border-radius: inherit;
            transform: translateZ(-1px);
        }
    }

    ${({newMsg}) => newMsg ? newMsgCss : null}
`

export const SBlock = styled.div<{gamePage?: boolean, colors?: string[][], activeBtn?: number}>`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.7vh;
    background: rgba(0, 0, 0, 0.50);
    transform-style: preserve-3d;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: url(${gameChatBG}) center center/cover no-repeat;
        transform: translateZ(-1px);
    }
    
    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: ${({gamePage, colors, activeBtn}) => gamePage ? 
            (activeBtn ? 
                `linear-gradient(0deg, ${colors![activeBtn!][0]} 0%, ${colors![activeBtn!][1]} 52.69%, ${colors![activeBtn!][2]} 100%)` 
                    : 
                'linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1))')
                :
            'linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1))'};
        border-radius: inherit;
        transform: translateZ(-2px);
    }
    
`