export enum ChatUserActionType {
    SET_UP_CHAT_USER = 'SET_UP_CHAT_USER',
    UPDATE_CHAT_USER = 'UPDATE_CHAT_USER'
}

export interface ChatUserState {
    chatUserState: any
}

export interface ChatUserAction {
    type: string,
    payload?: any
}