import {
    useState,
    useMemo
} from 'react'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setActiveTabUserList } from '../../store/actionCreators/activeTabUserList'
import { openDevModal } from '../../store/actionCreators/devModal'

import {
    userListConfig,
    userListTab
} from './config'
import { textsMainPage } from '../mainContent/config'
import { translation } from '../../utils/translation'

import search from '../../assets/icons/search.svg'

import { 
    SUserList,
    SOnline,
    SUsersListWrap,
    SUser,
    SAvatar,
    SStatsWrap,
    SUserBtn,
    SButtonWrap,
    SUserListBlock
} from './style'
import { 
    SListButton
} from '../lobbiesList/style'
import { SSendMessageBtn } from '../chatUser/style'
import { 
    SInputWrap,
    SInput,
    SSendMessageWrap
} from '../chatUser/style'

export const UserList = () => {

    const dispatch = useDispatch()
    
    const { activeTabUserListState } = useTypedSelector(state => state.activeTabUserListState)
    const { translationStateMainPage } = useTypedSelector(state => state.translationStateMainPage)

    const [userListTabState, setUserListTabState] = useState(userListTab)

    const changeActiveTabGameList = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        const id = (e.target as HTMLDivElement).id
        if (id) {
            dispatch(setActiveTabUserList(id))
            switch(id) {
                case 'allUsers':
                    return 
                case 'friends':
                    return dispatch(openDevModal())
                case 'myClan':
                    return dispatch(openDevModal())
            }
        }
    }

    const userListTabContent = useMemo(() => {
        return userListTabState.map(({id}) => {
            const text = (id: string) => {
                switch(id) {
                    case 'allUsers':
                        return translation('ВСЕ ИГРОКИ', translationStateMainPage, textsMainPage)
                    case 'friends':
                        return translation('ДРУЗЬЯ', translationStateMainPage, textsMainPage)
                    case 'myClan':
                        return translation('МОЙ КЛАН', translationStateMainPage, textsMainPage)
                    default:
                        return null
                }
            }

            return (
                <SListButton
                    key={id}
                    id={id}
                    className={id === activeTabUserListState ? 'active' : undefined}
                >
                    <span>
                        {text(id)}
                    </span>
                </SListButton>
            )
        })
    }, [activeTabUserListState, translationStateMainPage])

    const userListContent = useMemo(() => {
        return userListConfig.map(({avatar, nickname, rang, device}, i) => {
            return (
                <SUser id={nickname} key={i + 1}>
                    <SAvatar src={avatar} alt='avatar'/>
                    <SStatsWrap>
                        <div style={{'display': 'flex'}}>
                            <span>
                                {nickname}
                            </span>
                            <img src={device} alt='device' style={{'height': '1.4vh', 'marginLeft': '4px'}}/>
                        </div>
                        <span style={{'color': '#FFC93D'}}>
                            {rang}
                        </span>
                    </SStatsWrap>
                    <SUserBtn>
                        <span/>
                        <span/>
                        <span/>
                    </SUserBtn>
                </SUser>
            )
        })
    }, [])

    return (
        <div style={{
            'display': 'flex',
            'justifyContent': 'end',
            'gridArea': 'userList'
        }}>
            <SUserList>
                <SButtonWrap
                    onClick={changeActiveTabGameList}
                >
                    {userListTabContent}
                </SButtonWrap>
                <SUserListBlock>
                    <SOnline>
                        {translation('Онлайн', translationStateMainPage, textsMainPage)}:
                        <span style={{'color': '#FA9E14', 'marginLeft': '4px'}}>
                            0
                        </span>
                    </SOnline>
                    <SUsersListWrap>
                        {/* {userListContent} */}
                    </SUsersListWrap>
                    <SSendMessageWrap>
                        <SInputWrap>
                            <SInput 
                                type='text' 
                                name='search' 
                                placeholder={`${translation('Введите ник игрока', translationStateMainPage, textsMainPage)}...` }
                                id='search'/>
                        </SInputWrap>
                        <SSendMessageBtn
                            width='3.5vh'
                            height='3.5vh'
                        >
                            <img
                                src={search}
                                alt='search'
                            />
                        </SSendMessageBtn>
                    </SSendMessageWrap>
                </SUserListBlock>
            </SUserList>
        </div>
    )
}