import styled from 'styled-components'

import { device } from '../../theme'

export const SChatInfoModal = styled.div`
    position: absolute;
    top: 1vh;
    right: 3.2vh;
    display: flex;
    justify-content: end;
    gap: 1vh;
    z-index: 10;
    color: #FF9F9F;
`

export const SChatInfoModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 20vw;
    height: 9vh;
    padding: 1vh 1.2vh 0 1vw;
    background: linear-gradient(317deg, #3B2525 0%, rgba(35, 20, 20, 0.00) 100%), #313131;
    border-radius: 0.7vh 0.7vh 0 0;
    transform-style: preserve-3d;

    &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(130% + 4px);
        background: linear-gradient(rgba(183, 93, 93, 1), rgba(120, 49, 49, 1));
        border-radius: 0.7vh;
        transform: translateZ(-1px);
    }

    @media (${device.tablet}) and (orientation: portrait) {
        height: 7.5vh;
        width: 40vw;
        &::before {
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(130% + 2px);
        }
    }

    @media (${device.mobileL}) {
        height: 7.5vh;
        width: 50vw;
    }
`

export const SP = styled.p`
    width: 100%;
    text-align: center;
    margin-bottom: 0.5vh;

    @media (${device.tablet}) {
        font-size: 12px;
    }
`

export const SOl = styled.ol`
    overflow-y: scroll;
    width: 100%;
    height: 5vh;

    ::-webkit-scrollbar {
        width: 1.2vh;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
    }
    
    ::-webkit-scrollbar-thumb {
        background: rgba(175, 175, 175, 0.3);
        border-radius: 1vh;
    }

    @media (${device.tablet}) {
        font-size: 10px;
    }
`

export const SOpenInfoChatModal = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5vh;
    height: 2.5vh;
    background: rgba(181, 92, 92, 0.1);
    border: 1px solid #B55C5C;
    border-radius: 100%;
    color: #B55C5C;
    font-size: 2.3vmin;

    @media (${device.mobileL}) {
        width: 3vh;
        height: 3vh;
        font-size: 16px;
    }
`