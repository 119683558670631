import {
    useEffect,
    useCallback
} from 'react'

import WTSServise from '../../services/WTSServise'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setUpCurrentUser } from '../../store/actionCreators/currentUser'
import { setCustomTooltipState } from '../../store/actionCreators/customTooltip'

import { textsMainPage } from '../mainContent/config'
import { translation } from '../../utils/translation'

import noAvatar from '../../assets/img/no_avatar.png'
import vip from '../../assets/icons/vip.svg'

import {
    SUserCardWrap,
    SNickName,
    SWrapCardContentGrid,
    SWrapAvatar,
    SAvatar,
    SVip,
    SRank,
    SContentRank,
    SNameIndicator,
    SRatingUserCard,
    SAva,
    SPlusWrap
} from './style'

export const UserCard = () => {

    const dispatch = useDispatch()

    const { currentUserState } = useTypedSelector(state => state.currentUserState)
    const { translationStateMainPage } = useTypedSelector(state => state.translationStateMainPage)
    
    useEffect(() => {
        updateUser()
    }, [])
    
    const refreshToken = async () => {
        try {
            const response = await WTSServise.refreshToken()
            localStorage.setItem('accessToken', response.data.data.tokens.accessToken)
            updateUser()
        } catch(e: any) {
            if (!e) return
            console.log(e)
        }
    }

    const updateUser = async () => {
        try {
            const response = await WTSServise.currentUser()
            dispatch(setUpCurrentUser(response.data.data.user))
        } catch (e: any) {
            if (!e) return

            if (e.request.status === 401) {
                refreshToken()
                window.location.href = '/login'
                return
            }
            
            if (e.request.status === 400) {
                dispatch(setCustomTooltipState({
                    status: 'error',
                    titleText: translation('Ошибка', translationStateMainPage, textsMainPage),
                    text: translation('Не удалось получить данные о пользователе с сервера', translationStateMainPage, textsMainPage)
                }))
                return
            }
            
            console.log(e)
        }
    }

    const rang = 'Новичок'
    const progress = '0'

    const imageOnErrorHandler = (
        event: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
        event.currentTarget.src = noAvatar
    }
    
    return (
        <SUserCardWrap>
            <SNickName>
                {currentUserState?.username}
            </SNickName>
            <SWrapCardContentGrid>
                <SWrapAvatar>
                    <SAvatar>
                        <SVip
                            src={vip}
                            alt='vip'
                        />
                        <SAva
                            src={currentUserState?.avatarUri 
                                ? currentUserState.avatarUri 
                                : noAvatar
                            } 
                            alt='avatar'
                            onError={imageOnErrorHandler}
                        />
                    </SAvatar>
                    <SRank>
                        <SContentRank
                            progress={currentUserState?.progress 
                                ? currentUserState.progress 
                                : progress
                            }
                        >
                            {currentUserState?.rang 
                                ? translation(currentUserState.rang, translationStateMainPage, textsMainPage)
                                : translation('Новичок', translationStateMainPage, textsMainPage)
                            }
                        </SContentRank>
                    </SRank>
                </SWrapAvatar>
                <SNameIndicator gridArea={'gold'}>
                    {translation('Золото', translationStateMainPage, textsMainPage)}
                </SNameIndicator>
                <SNameIndicator gridArea={'tokens'}>
                    {translation('Токены', translationStateMainPage, textsMainPage)}
                </SNameIndicator>
                <SNameIndicator gridArea={'capital'}>
                    {translation('Капитал', translationStateMainPage, textsMainPage)}
                </SNameIndicator>
                <SRatingUserCard gridArea={'goldValue'}>
                    <span style={{'color': '#FFC93D'}}>
                        {currentUserState?.gold 
                            ? String(currentUserState.gold).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') 
                            : '0'
                        } кг.
                    </span>
                    <SPlusWrap>
                        <span/>
                        <span/>
                    </SPlusWrap>
                </SRatingUserCard>
                <SRatingUserCard gridArea={'totensValue'}>
                    <span style={{'color': '#FFC93D'}}>
                        {currentUserState?.tokens 
                            ? String(currentUserState.tokens).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') 
                            : '0'
                        }
                    </span>
                    <SPlusWrap>
                        <span/>
                        <span/>
                    </SPlusWrap>
                </SRatingUserCard>
                <SRatingUserCard 
                    gridArea={'totalCapital'}
                    justifyContent='start'
                >
                    <span>
                        {translation('Общий', translationStateMainPage, textsMainPage)}:
                    </span>
                    <span style={{'color': '#FFC93D', 'paddingLeft' : '4px'}}>
                        {currentUserState?.totalCapital ? currentUserState.totalCapital : '0'}
                    </span>
                    <span style={{'color' : '#127709', 'paddingLeft' : '4px'}}>
                        $
                    </span>
                </SRatingUserCard>
                <SRatingUserCard 
                    gridArea={'weeklyCapital'}
                    justifyContent='start'
                >
                    <span>
                        {translation('За неделю', translationStateMainPage, textsMainPage)}: 
                    </span> 
                    <span style={{'color': '#FFC93D', 'paddingLeft' : '4px'}}>
                        {currentUserState?.weeklyCapital ? currentUserState.weeklyCapital : '0'}
                    </span>
                    <span style={{'color' : '#127709', 'paddingLeft' : '4px'}}>
                        $
                    </span>
                </SRatingUserCard>
            </SWrapCardContentGrid>
        </SUserCardWrap>
    )
}