import {
    useMemo,
    useCallback
} from 'react'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setNewGameModal } from '../../store/actionCreators/newGameModal'
import { openDevModal } from '../../store/actionCreators/devModal'

import { menuButtonsConfig } from './config'
import { textsMainPage } from '../mainContent/config'
import { translation } from '../../utils/translation'

import { 
    SMenuButtons,
    SMenuButton
} from './style'

export const MenuUser = () => {

    const dispatch = useDispatch()

    const { translationStateMainPage } = useTypedSelector(state => state.translationStateMainPage)

    const buttonClick = useCallback((id: string) => {
        switch(id) {
            case 'newgame':
                return () => dispatch(setNewGameModal())
            default:
                return () => dispatch(openDevModal())
        }
    }, [])

    const textMenuBtn = useCallback((id: string) => {
        switch(id) {
            case 'data':
                return translation('ДАННЫЕ', translationStateMainPage, textsMainPage)
            case 'rating':
                return translation('РЕЙТИНГ', translationStateMainPage, textsMainPage)
            case 'store':
                return translation('МАГАЗИН', translationStateMainPage, textsMainPage)
            case 'bank':
                return translation('БАНК', translationStateMainPage, textsMainPage)
            case 'newgame':
                return translation('СОЗДАТЬ ИГРУ', translationStateMainPage, textsMainPage)
            case 'inviteFrend':
                return translation('ПРИГЛАСИТЬ ДРУГА', translationStateMainPage, textsMainPage)
        }
    }, [translationStateMainPage])

    const menuButtons = useMemo(() => {
        return menuButtonsConfig.map(({id, icon, bigSize}) => {

            const iconItem = icon ? <img src={icon} alt={id} style={{'height' : '4.3vh'}}/> : null

            const click = () => {
                buttonClick(id)?.()
            }
            
            return (
                <SMenuButton
                    id={id}
                    key={id}
                    gridArea={id}
                    bigSize={bigSize}
                    onClick={click}
                >
                    {iconItem}
                    {textMenuBtn(id)}
                </SMenuButton>
            )
        })
    }, [translationStateMainPage])

    return (
        <div style={{
            'display' : 'flex',
            'justifyContent': 'center',
            'gridArea': 'menuUser'
        }}>
            <SMenuButtons>
                {menuButtons}
            </SMenuButtons>
        </div>
    )
}