import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { closeInfoModal } from '../../store/actionCreators/infoModal'

import { textsAuthPage } from '../authorization/config'
import { translation } from '../../utils/translation'

import {
    ScrollWrap,
    SH3,
    SP,
    SBlock
} from './style'

import { 
    SModalWrap,
    STitle,
    SH2,
    SCloseModalBtn,
} from '../newGameModal/style'

export const InfoModal = () => {

    const dispatch = useDispatch()

    const { infoModalState } = useTypedSelector(state => state.infoModalState)
    const { translationStateAuthPage } = useTypedSelector(state => state.translationStateAuthPage)
    
    return (
        <SModalWrap
            showModal={infoModalState}
        >
            <SBlock>
                <STitle>
                    <SH2>
                        {translation('СПРАВКА', translationStateAuthPage, textsAuthPage)}
                    </SH2>
                    <SCloseModalBtn onClick={() => dispatch(closeInfoModal())}>
                        <span/>
                        <span/>
                    </SCloseModalBtn>
                </STitle>
                <ScrollWrap>
                    <SH3>
                        {translation('КРАТКОЕ ОПИСАНИЕ ИГРЫ', translationStateAuthPage, textsAuthPage)}
                    </SH3>
                    <SP>
                        {translation('Многопользовательская игра «Магнат» – это игра, созданная на основе популярнейшей игры «Монополия» (Monopoly), в которой есть возможность покупать, продавать, арендовать собственность.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('После броска кубиков ваша фишка перемещается по полю и становится на клетке в зависимости от выпавшего на кубиках количества очков. Игра становится интересной благодаря дополнительному функционалу, который разнообразит игровой процесс.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        <span>
                            {translation('Цель игры', translationStateAuthPage, textsAuthPage)}
                        </span> 
                        - {translation('остаться единственным не обанкротившимся игроком.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SH3>
                        {translation('ПРАВИЛА УЧАСТИЯ В ИГРЕ', translationStateAuthPage, textsAuthPage)}
                    </SH3>
                    <SP>
                        {translation('У вас есть несколько способов начать игру: создать игру, присоединиться к созданной игре, пригласить друга в игру.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        1. {translation('Для создания игры нужно нажать кнопку «Создать игру». Откроется окно с параметрами создания, в котором можно указать название игры, тип игры (стандартный, командный 2х2), количество игроков от 2 до 8 человек (стандартной игры), выбрать поле (малое (от 2 до 5 человек), среднее (от 2 до 6 человек), большое (от 2 до 8 человек), ставку золотом, пароль для приватной игры.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        2. {translation('Присоединиться к созданной игре можно, нажав кнопку «Играть» в списке «Лобби». Присоединится к игре 2х2 можно, нажав на значок «+», чтобы играть за определенную команду.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        3. {translation('Пригласить друга в игру 1х1 (друг против друга) можно, нажав на выбранного друга в списке друзей и выбрав пункт «Пригласить в игру». Для игры 2х2 в паре – выбрать пункт «Пригласить в пару».', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SH3>
                        {translation('НАЧАЛО ИГРЫ', translationStateAuthPage, textsAuthPage)}
                    </SH3>
                    <SP>
                        {translation('Фишки всех игроков выстраиваются на поле ', translationStateAuthPage, textsAuthPage)}
                        <span>
                            {`«${translation('Старт', translationStateAuthPage, textsAuthPage)}»`}
                        </span> 
                        {translation(', после чего поочередно каждый делает свой ход, и фишка перемещается в зависимости от выпавшего на кубиках количества очков.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('В начале игры каждому игроку дается стартовый капитал в размере 2 000 000 $.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SH3>
                        {translation('ХОД ИГРЫ', translationStateAuthPage, textsAuthPage)}
                    </SH3>
                    <SP>
                        <span>
                            {`«${translation('Бросить кубики', translationStateAuthPage, textsAuthPage)}»`}
                        </span> 
                        {translation(' осуществляет ход фишки игрока по полю. Время на ход игрока – 75 секунд. Ход текущего игрока определяет индикатор отсчета времени на ход. При выпадении дубля игрок ходит еще раз. При выпадении трех дублей подряд игрок перемещается на «Таможню».', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        <span>
                            {`«${translation('Купить', translationStateAuthPage, textsAuthPage)}»`}
                        </span> 
                        {translation(' осуществляет покупку участка с недвижимостью, если она никому не принадлежит.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        <span>
                            {`«${translation('Аукцион', translationStateAuthPage, textsAuthPage)}»`}
                        </span> 
                        {translation(' осуществляет выставление участка с недвижимостью на аукцион. При этом недвижимость могут приобрести другие участники игры.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        <span>
                            {`«${translation('Заложить', translationStateAuthPage, textsAuthPage)}»`}
                        </span> 
                        {translation(' осуществляет залог вашей недвижимости. В основном закладывают тогда, когда не хватает денег для погашения долгов.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        <span>
                            {`«${translation('Выкупить', translationStateAuthPage, textsAuthPage)}»`}
                        </span> 
                        {translation(' осуществляет выкуп заложенной вами недвижимости. Как правило, выкуп из залога стоит дороже, чем сам залог.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        <span>
                            {`«${translation('Построить', translationStateAuthPage, textsAuthPage)}»`}
                        </span> 
                        {translation(' осуществляет постройку филиалов и холдингов. Строительство возможно только в том случае, когда у вас выстроилась монополия.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        <span>
                            {`«${translation('Продать', translationStateAuthPage, textsAuthPage)}»`}
                        </span> 
                        {translation(' осуществляет продажу филиалов и холдингов. Как правило, продавать филиалы и холдинги нужно для того, чтобы пополнить свой баланс для погашения долгов.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        <span>
                            {`«${translation('Заключить сделку', translationStateAuthPage, textsAuthPage)}»`}
                        </span> 
                        {translation(' осуществляет обмен, продажу или покупку недвижимости между игроками.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        <span>
                            {`«${translation('Взять кредит', translationStateAuthPage, textsAuthPage)}»`}
                        </span> 
                        {translation(' (только для VIP аккаунта). Появляется возможность взять кредитные средства в банке. Кредитные деньги даются на 15-25 ходов (в зависимости от количества подарков у игрока), после чего нужно обязательно вернуть их банку («Погасить кредит»).', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        <span>
                            {`«${translation('Сдаться', translationStateAuthPage, textsAuthPage)}»`}
                        </span> 
                        {translation(' осуществляет выход из игрового процесса и объявление игрока банкротом. После нажатия вся приобретенная недвижимость становится свободной для покупки другими участниками игры. В игре 2х2 вся недвижимость переходит игроку-партнеру.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('При попадании на свободный участок с недвижимостью, которая пока еще никому не принадлежит, вы можете купить эту недвижимость. Также можно выставить недвижимость на аукцион другому игроку. Предложивший за недвижимость в аукционе самую высокую цену становится ее владельцем.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('Игроки, имеющие недвижимость, могут взимать арендную плату с игроков, которые попадают на их участок. При попадании фишки на участок, который контролирует другой игрок, вы обязаны оплатить аренду за пребывание на его участке.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('Как только у вас выстроилась монополия (несколько фирм одной цветовой группы), у вас появляется возможность строить филиалы и холдинги («Построить»). При строительстве филиалов и холдингов арендная плата значительно возрастает, поэтому вам следует вести строительство на как можно большем количестве участков. Строить можно только по одному объекту в монополии за один ход. Продавать филиалы и холдинги за один ход можно сколько угодно.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('Если вы нуждаетесь в деньгах для погашения каких-либо долгов, вы можете заложить вашу недвижимость (', translationStateAuthPage, textsAuthPage)}
                        <span>
                            {`«${translation('Заложить', translationStateAuthPage, textsAuthPage)}»`}
                        </span> 
                        {translation('), продать ваши филиалы («Продать») или взять кредит («Взять кредит»).', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('В ХОДЕ ИГРЫ ИГРОК МОЖЕТ ПОПАДАТЬ НА ТАКИЕ ПОЛЯ:', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('Старт', translationStateAuthPage, textsAuthPage)}
                        {translation(' При остановке на клетке ', translationStateAuthPage, textsAuthPage)}
                        <span>
                            {`«${translation('Старт', translationStateAuthPage, textsAuthPage)}»`}
                        </span>
                        {translation(' игрок получает вознаграждение в размере 400 000$.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('Налог', translationStateAuthPage, textsAuthPage)}
                        {translation(' При попадании на клетку ', translationStateAuthPage, textsAuthPage)}
                        <span>
                            {`«${translation('Налог', translationStateAuthPage, textsAuthPage)}»`}
                        </span>
                        {translation(' игрок обязан оплатить ', translationStateAuthPage, textsAuthPage)}
                        <span>
                            18%
                        </span>
                        {translation(' от имеющегося капитала ', translationStateAuthPage, textsAuthPage)}
                        {`(«${translation('Оплатить', translationStateAuthPage, textsAuthPage)}»).`}
                    </SP>
                    <SH3>
                        {translation('КОМАНДНЫЕ ИГРЫ (2х2)', translationStateAuthPage, textsAuthPage)}
                    </SH3>
                    <SP>
                        {translation('В игре присутствует возможность играть в командные игры 1х2 или 2х2, (на стандартной карте 42 клетки) 1х2, 2х2 или 2х2х2 (на большой карте 72 клетки), 1х2, 2х2, 2х2х2, 2х2х2х2 (на гигантской карте 132 клетки).', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('- При попадании на участок игрока-партнера вы не платите ему аренду.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('- При заключении сделки с игроком-партнером снимается ограничение на обмен в 30% разницы сторон.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('- Если игрок-партнер сдается, то все его фирмы и наличные передаются союзнику.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                    <SP>
                        {translation('- При победе выигрыш распределяется равными долями между вами и игроком-партнером.', translationStateAuthPage, textsAuthPage)}
                    </SP>
                </ScrollWrap>
            </SBlock>
        </SModalWrap>
    )
}