import {
    useMemo,
    useEffect
} from 'react'

import WTSServise from '../../services/WTSServise'
import ConnectSocket from '../../sockets/lobbie/createConnectionSocket'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setUpTranslateMainPage } from '../../store/actionCreators/translation'
import { setCustomTooltipState } from '../../store/actionCreators/customTooltip'

import { UserCard } from '../userCard'
import { MenuUser } from '../menuUser'
import { LobbiesList } from '../lobbiesList'
import { UserList } from '../usersList'
import { ChatUser } from '../chatUser'
import { NewGameModal } from '../newGameModal'
import { YouLeaveModal } from '../youLeaveModal'
import { DevModal } from '../devModal'
import { ReadyToPlayModal } from '../readyToPlayModal'
import { InfoModal } from '../infoModal'

import { textsMainPage } from './config'
import { translation } from '../../utils/translation'

import { SMainContent } from './style'

export const MainContent = () => {

    const dispatch = useDispatch()

    const { languageState } = useTypedSelector(state => state.languageState)
    const { translationStateMainPage } = useTypedSelector(state => state.translationStateMainPage)

    useEffect(() => {
        updateTranslate()
    }, [languageState])
        
    const updateTranslate = async () => {
        try {
            const response = await WTSServise.translations(textsMainPage, languageState)
            let translateArr: string[] = []
            response.data.data.phraseTranslations.map((item: any) => {
                if(item.phrase.translation) {
                    translateArr.push(item.phrase.translation.text)
                    return
                } else {
                    translateArr.push(item.phrase.text)
                    return
                }
            })
            dispatch(setUpTranslateMainPage(translateArr))
        
        } catch (e: any) {
            if (!e) return
            
            if (e.request.status === 400) {
                dispatch(setCustomTooltipState({
                    status: 'error',
                    titleText: translation('Ошибка', translationStateMainPage, textsMainPage),
                    text: translation('Ошибка сервера', translationStateMainPage, textsMainPage)
                }))
                return
            }
            
            console.log(e)
        }
    }

    //TODO - React is swears at it
    useMemo(() => {
        ConnectSocket()
    }, [])
    
    return (
        <SMainContent>
            <UserCard/>
            <MenuUser/>
            <LobbiesList/>
            <UserList/>
            <ChatUser/>
            <NewGameModal/>
            <ReadyToPlayModal/>
            <DevModal/>
            <YouLeaveModal/>
            <InfoModal/>
        </SMainContent>
    )
}
