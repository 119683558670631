import { socket } from '../../sockets/lobbie/createConnectionSocket'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { closeGiveUpModal } from '../../store/actionCreators/giveUp'

import { textsGamePage } from '../gameContent/config'
import { translation } from '../../utils/translation'

import {
    SP,
    SBtn
} from '../readyToPlayModal/style'
import { 
    SModalWrap,
    STitle,
    SH2,
    SCloseModalBtn,
    SGreyWrap
} from '../newGameModal/style'
import { SBlock } from '../devModal/style'

export const GiveUpModal = () => {

    const dispatch = useDispatch()

    const { giveUpModalState } = useTypedSelector(state => state.giveUpModalState)
    const { translationStateGamePage } = useTypedSelector(state => state.translationStateGamePage)

    const yes = () => {
        socket.emit("lobby.leave", {})
        window.location.href = "/user"
    }

    const no = () => {
        dispatch(closeGiveUpModal())
    }

    return (
        <SModalWrap
            /* padding={'33vh 40.5vw'} */
            showModal={giveUpModalState}
        >
            <SBlock>
                <STitle>
                    <SH2>
                        {translation('СДАТЬСЯ', translationStateGamePage, textsGamePage)}?
                    </SH2>
                    <SCloseModalBtn
                        onClick={() => dispatch(closeGiveUpModal())}
                    >
                        <span/>
                        <span/>
                    </SCloseModalBtn>
                </STitle>
                <SP
                    marginTop='8vh'
                >
                    {translation('Вы уверены, что хотите сдаться', translationStateGamePage, textsGamePage)}?
                </SP>
                <SGreyWrap
                    style={{'gap' : '2vh'}}
                >
                    <SBtn
                        color='green'
                        onClick={yes}
                        style={{'minWidth': '80px'}}
                    >
                        {translation('ДА', translationStateGamePage, textsGamePage)}
                    </SBtn>
                    <SBtn
                        color='yellow'
                        onClick={no}
                        style={{'minWidth': '80px'}}
                    >
                        {translation('НЕТ', translationStateGamePage, textsGamePage)}
                    </SBtn>
                </SGreyWrap>
            </SBlock>
        </SModalWrap>
    )
}
