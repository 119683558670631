import {io} from "socket.io-client";
import {useState, useEffect} from 'react';
import {LobbiesActionType} from "../../types/lobbiesReduserType";
import {useDispatch} from 'react-redux';
import {getUserLobbyDataSC, getUsersTimerCallback} from "./socketHelpCommands";
import {MenuButtonsActionType} from "../../types/menuButtonsType";
import {MenuGame} from "../../components/menuGame";
import {PlayersActionType} from "../../types/playersReduserType";
import auction from "./lobbieHandlers/auction";
import {AuctionDataActionType} from "../../types/auctionDataReduserType";
import {closeAuctionModal, openAuctionModal} from "../../store/actionCreators/auctionModal";
import {openYouLostModal} from "../../store/actionCreators/youLostModal";
import {openYouWinModal} from "../../store/actionCreators/youWinModal";
import {closeAdditionMenuButton, openMenuButtonHide} from "../../store/actionCreators/menuButtonHide";
import {openMakeADealModal, setUpDealDetails} from "../../store/actionCreators/makeADealModal";
import {setCustomTooltipState} from "../../store/actionCreators/customTooltip";
import SoundManager from "../../utils/SoundManager";
import { setUpLobbiesTurns } from "../../store/actionCreators/lobbieTotalTurns";

import {socketsUrl} from "../../enviroment/backend.sockets";
import {openNoMoneyModal} from "../../store/actionCreators/noMoneyModal";
import postFrameMessage from "./sendIframeData";
export let socket: any;

declare const window: any;

const ConnectSocket = async () => {

    //TODO change on Env
    socket = io(socketsUrl, {
        extraHeaders: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
    });

    window["recallAllowed"] = () => {
        socket.emit("lobby.get.player.allowed", {});
    }

    //TODO Move to single file
    const postFrameMessage = (messageName: string, data: any) => {
        const iframe: any = document.getElementById("iframe");

        if (iframe)
            iframe.contentWindow.postMessage(JSON.stringify({
                ...data,
                ...{
                    type: messageName
                }
            }), "*");
    }

    const dispatch = useDispatch()

    socket.on("connection", (ans: any) => {
        console.log("CONNECTED SUCCESS");

        (async () => {
            const ans: any = await getUserLobbyDataSC(socket);

            if (
                ans?.lobby?.started
                && window.location.pathname != '/game') //This is bad
            {
                window.location.href = '/game'
            }

            if (window.location.pathname == '/game') {
                socket.on("lobby.get.user.result", (ans: any) => {
                    console.log("lobby.get.user.result", ans.lobby.totalTurns);
                    postFrameMessage("lobby.get.user.result", ans);
                    dispatch(setUpLobbiesTurns(ans.lobby.totalTurns))
                })
            }
        })();

    });

    //Получение даты об аукционе
    socket.on("lobby.game.auction.start", (resp: any) => {
        console.log("lobby.game.auction.start", resp);

        dispatch({
            type: AuctionDataActionType.SET_UP_AUCTION_DATA,
            payload: resp
        })
    });

    //Показать аукцион
    socket.on("lobby.game.auction.show", () => {
        console.log("lobby.game.auction.show", {});

        SoundManager.playAuction();

        dispatch(openAuctionModal());
    });

    //Скрытие аукциона
    socket.on("lobby.game.auction.hide", () => {
        console.log("lobby.game.auction.hide", {});
        dispatch(closeAuctionModal());
    });


    socket.on("lobby.error", (err: any) => {
        console.log("Lobby error", err);

        if (
            err.error.indexOf("user with id") <= -1 &&
            err.error.indexOf("auction don't started") <= -1 &&
            err.error.indexOf("the current move is not for this player") <= -1 &&
            err.error.indexOf("can not build on this turn") <= -1 &&
            err.error.indexOf("object unsub") <= -1
        ) {
            if (err.error.indexOf("does not have enough money") >= 0) {
                dispatch(openNoMoneyModal());
                postFrameMessage("get.game.decline", {})
                return;
            }

            dispatch(setCustomTooltipState({
                status: 'error',
                titleText: 'Ошибка',
                text: err.error
            }))
        }
    });

    socket.on("lobby.message.get", (ans: any) => {
        console.log("Lobby message get", ans);

        //we got existed lobbies
        if (ans.lobbies) {
            dispatch({
                type: LobbiesActionType.SET_UP_LOBBIES,
                payload: ans.lobbies
            })
        }
    });

    if (window.location.pathname !== '/game')
        socket.emit("lobby.get.all", {});

    socket.on("connect_error", (err: any) => {
        console.log("Connection error", err)
    });

    socket.on("lobby.refresh", (ans: any) => {
        console.log("lobby.refresh", ans);

        dispatch({
            type: LobbiesActionType.SET_UP_LOBBIES,
            payload: ans.lobbies
        })
    });

    socket.on("lobby.game.trade.show", (ans: any) => {
        SoundManager.playIGetMakeADeal();

        dispatch(openMakeADealModal({
            mode: "partner",
            data: ans
        }))
    });

    socket.on("lobby.game.lose", (ans: any) => {
        if (ans.player.userId == localStorage.getItem("userId")) {
            dispatch(openYouLostModal());
        }
    });

    socket.on("lobby.game.win", (ans: any) => {
        if (ans.player.userId == localStorage.getItem("userId")) {
            SoundManager.playWinnerSound();

            dispatch(openYouWinModal(ans));
        }
    });

    socket.on("lobby.game.start", (ans: any) => {

        console.log("lobby.game.start", ans);

        //TODO maybe we must to think about better way?
        window.location.href = '/game'
    });

    socket.on("lobby.game.player.next", (resp: any) => {
        console.log("lobby.game.player.next", resp);

        if (resp?.player.userId === Number(localStorage.getItem("userId"))) {
            SoundManager.playCurrentTurn();
        }

        postFrameMessage("lobby.game.player.next", resp);

        //TODO - client is must know, that timer need to be update?
        socket.emit("lobby.get.timer", {});
    });

    socket.on("lobby.game.player.move", (resp: any) => {
        SoundManager.playRollDice();

        console.log("lobby.game.player.move", resp);
        postFrameMessage("lobby.game.player.move", resp);

        //TODO - client is must know, that timer need to be update?
        socket.emit("lobby.get.timer", {});
    });

    socket.on("lobby.game.dice.result", (resp: any) => {
        console.log("lobby.game.dice.result", resp);
        postFrameMessage("lobby.game.dice.result", resp);
    });

    socket.on("lobby.game.allowed", (ans: any) => {
        console.log("lobby.game.allowed", ans);
        const obj: any = {};
        if (ans.allowed)
            ans.allowed.map((item: any) => {
                obj[item] = true
            });

        if (obj["lobby.game.dice"] || obj["lobby.game.pay"])
            dispatch(openMenuButtonHide())

        dispatch({
            type: MenuButtonsActionType.SET_UP_BUTTONS,
            payload: obj
        })
    });

    window.addEventListener(
        "message", async (event: any) => {
            if (event.origin !== "http://localhost:8999" &&
                event.origin !== "http://donmagnate.app.wtsdemo.ru"
            ) return;

            const data: any = JSON.parse(event.data);

            if (data.type === "game.build") {
                socket.emit("lobby.game.build", {
                    tileId: Number(data.tileId)
                });

                dispatch(closeAdditionMenuButton());
                dispatch(openMenuButtonHide());
            } else if (data.type === "game.mortgage") {
                socket.emit("lobby.game.mortgage", {
                    tileIds: data.tilesId
                });
                console.log("lobby.game.mortgage", {
                    tileIds: data.tilesId
                });

            } else if (data.type === "game.sell") {

                for (let i in data.data) {
                    if (data.data[i] && data.data[i]?.fillial) {
                        socket.emit("lobby.game.sell", {
                            "tiles": [
                                {
                                    "tileId": Number(i),
                                    "branchCount": Math.abs(data.data[i].fillial - data.data[i].currently)
                                }
                            ]
                        });
                    }
                }

                //TODO - hack to update balance
                socket.emit("lobby.get.timer", {});
            } else if (data.type === "game.redeem") {
                console.log(data);
                socket.emit("lobby.game.redeem", {
                    tileIds: [Number(data.tileId)]
                });

                //TODO - hack to update balance
                socket.emit("lobby.get.timer", {});
            } else if (data.type === "show.menu") {
                console.log("OPEN BUTTONS");
                dispatch(openMenuButtonHide())
            } else if (data.type === "game.makedeal.data") {
                console.log ("make a deal", data.response);
                dispatch(setUpDealDetails(data.response))
            } else if (data.type === "lobby.game.dice.animation.end") {
                socket.emit("lobby.game.dice.animation.end", {});
            } else if (data.type === "lobby.game.move.animation.end") {
                socket.emit("lobby.game.move.animation.end", {});
            }

        });

};

export default ConnectSocket;
