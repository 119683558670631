import { socket } from '../../sockets/lobbie/createConnectionSocket'
import sendIframeData from '../../sockets/lobbie/sendIframeData'
import postFrameMessage from '../../sockets/lobbie/sendIframeData'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { openAuctionModal } from '../../store/actionCreators/auctionModal'
import { closeMakeADealModal } from '../../store/actionCreators/makeADealModal'
import { openGiveUpModal } from '../../store/actionCreators/giveUp'
import {
    closeMenuButtonHide,
    closeAdditionMenuButton,
    openAdditionMenuButton,
    openMenuButtonHide
} from '../../store/actionCreators/menuButtonHide'

import { textsGamePage } from '../gameContent/config'
import { translation } from '../../utils/translation'

import cubes from '../../assets/img/cubes.png'

import {
    SMenu,
    SRedMenuBtn,
    SMenuBtn,
    SAnimationWrap
} from './style'

export const MenuGame = () => {
    //TODO remove
    const dispatch = useDispatch()
    const { translationStateGamePage } = useTypedSelector(state => state.translationStateGamePage)
    const { MenuButtonsListState } = useTypedSelector(state => state.menuButtonsReducer)
    const { MenuButtonsHideState , AdditionMenuButtonsState, AdditionMenuButtonsCommandState } = useTypedSelector(state => state.menuButtonsHideReducer)

    let buy = new Audio("/sounds/buy.mp3");

    const _callEvent = (name: string, opt: any) => {
        console.log("Called button: " + name);
        socket.emit(name, opt);
    }
    const rollTheDice = () => {
        _callEvent("lobby.game.dice", {})
        dispatch(closeMenuButtonHide())
    };
    const eventBuy = () => {
        buy.play();
        _callEvent("lobby.game.buy", {});
    }
    const eventExit = () => {
        dispatch(openGiveUpModal());

       // _callEvent("lobby.leave", {});
        //window.location.href = "/user";
    }
    const eventPay = () => _callEvent("lobby.game.pay", {});
    const eventSkip = () => _callEvent("lobby.game.skip", {});
    const eventAuction = () => _callEvent("lobby.game.auction", {});
    //TODO remove
    const eventAuctionMW = () => {
        dispatch(openAuctionModal());
    }
    const eventBuild = () => {
        //sendIframeData("build", {fromUserId: localStorage.getItem("userId")})
        sendIframeData("build", {fromUserId: localStorage.getItem("userId")})

        dispatch(openAdditionMenuButton("build"));
        dispatch(closeMenuButtonHide());
    };

    const eventMortgage = () => {
        sendIframeData("mortgage", {fromUserId: localStorage.getItem("userId")})

        dispatch(openAdditionMenuButton("mortgage"));
        dispatch(closeMenuButtonHide());
    };

    const eventSell = () => {
        sendIframeData("sell", {fromUserId: localStorage.getItem("userId")})

        dispatch(openAdditionMenuButton("sell"));
        dispatch(closeMenuButtonHide());
    };

    const eventRedeem = () => {
        sendIframeData("redeem", {fromUserId: localStorage.getItem("userId")})
    }

    const eventFrontConfirm = () => {
        //postFrameMessage("get.game.makeadeal.confirmation", {})
        postFrameMessage("send.game.confirm", {})
        dispatch(closeAdditionMenuButton());
        dispatch(openMenuButtonHide());
    }

    const eventFrontDecline = () => {
        postFrameMessage("get.game.decline", {})
        dispatch(closeAdditionMenuButton());
        dispatch(openMenuButtonHide());

        if (AdditionMenuButtonsCommandState == "makeadeal") {
            dispatch(closeMakeADealModal());
        }
    }

    return (
        <SMenu>
            <SRedMenuBtn style={{display: 'none'}}>
                {translation('ВЫХОД', translationStateGamePage, textsGamePage)}
            </SRedMenuBtn>
            <SRedMenuBtn onClick={eventExit}>
                {translation('СДАТЬСЯ', translationStateGamePage, textsGamePage)}
            </SRedMenuBtn>
            {MenuButtonsListState["lobby.game.dice"] && MenuButtonsHideState && !AdditionMenuButtonsState ? (
                <SAnimationWrap>
                    <SMenuBtn
                        height='11vh'
                        marginTop='0'
                        onClick={rollTheDice}
                    >
                        <img
                            src={cubes}
                            alt='cubes'
                            style={{'height': '6vh', 'margin': '0 auto'}}
                        />
                        <span>
                            {translation('БРОСИТЬ КУБИКИ', translationStateGamePage, textsGamePage)}
                        </span>
                    </SMenuBtn>
                </SAnimationWrap>
            ) : <></>}
            {MenuButtonsListState["lobby.game.buy"] && MenuButtonsHideState && !AdditionMenuButtonsState ? (
                <SMenuBtn
                    marginTop='3vh'
                    onClick={eventBuy}
                >
                    {translation('КУПИТЬ', translationStateGamePage, textsGamePage)}
                </SMenuBtn>
            ) : <></>}
            {MenuButtonsListState["lobby.game.build"] && MenuButtonsHideState && !AdditionMenuButtonsState ? (
                <SMenuBtn onClick={eventBuild}>
                    {translation('ПОСТРОИТЬ', translationStateGamePage, textsGamePage)}
                </SMenuBtn>
            ) : <></>}
            {MenuButtonsListState["lobby.game.sell"] && MenuButtonsHideState && !AdditionMenuButtonsState ? (
                <SMenuBtn onClick={eventSell}>
                    {translation('ПРОДАТЬ', translationStateGamePage, textsGamePage)}
                </SMenuBtn>
            ) : <></>}
            {MenuButtonsListState["lobby.game.mortgage"] && MenuButtonsHideState && !AdditionMenuButtonsState ? (
                <SMenuBtn onClick={eventMortgage}>
                    {translation('ЗАЛОЖИТЬ', translationStateGamePage, textsGamePage)}
                </SMenuBtn>
            ) : <></>}
            {MenuButtonsListState["lobby.game.pay"] && MenuButtonsHideState && !AdditionMenuButtonsState ? (
                <SMenuBtn onClick={eventPay}>
                    {translation('ОПЛАТИТЬ', translationStateGamePage, textsGamePage)}
                </SMenuBtn>
            ) : <></>}
            {MenuButtonsListState["lobby.game.auction"] && MenuButtonsHideState && !AdditionMenuButtonsState ? (
                <SMenuBtn onClick={eventAuction}>
                    {translation('АУКЦИОН', translationStateGamePage, textsGamePage)}
                </SMenuBtn>
            ) : <></>}
            {MenuButtonsListState["lobby.game.skip"] && MenuButtonsHideState && !AdditionMenuButtonsState ? (
                <SMenuBtn onClick={eventSkip}>
                    {translation('ОТКАЗАТЬСЯ', translationStateGamePage, textsGamePage)}
                </SMenuBtn>
            ) : <></>}
            {MenuButtonsListState["lobby.game.redeem"] && MenuButtonsHideState && !AdditionMenuButtonsState ? (
                <SMenuBtn onClick={eventRedeem}>
                    {translation('ВЫКУПИТЬ', translationStateGamePage, textsGamePage)}
                </SMenuBtn>
            ) : <></>}
            {AdditionMenuButtonsState
            && AdditionMenuButtonsCommandState != "makeadeal" ? (
                <SMenuBtn onClick={eventFrontConfirm}>
                    {translation('ГОТОВО', translationStateGamePage, textsGamePage)}
                </SMenuBtn>
            ) : <></>}
            {AdditionMenuButtonsState ? (
                <SMenuBtn onClick={eventFrontDecline}>
                    {translation('ОТМЕНА', translationStateGamePage, textsGamePage)}
                </SMenuBtn>
            ) : <></>}
            <SMenuBtn 
                height='10vh'
                style={{display: 'none'}}
            >
                {translation('ВЗЯТЬ КРЕДИТ', translationStateGamePage, textsGamePage)}
            </SMenuBtn>
        </SMenu>
    )
}
