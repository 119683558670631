import {
    useState,
    useEffect
} from 'react'

import { useTypedSelector } from '../../hooks/useTypedSelector'

import { getTimeRemaining } from '../../utils/getTimeRemaining'
import { translation } from '../../utils/translation'
import { textsGamePage } from '../gameContent/config'

import {
    SRatingWrap,
    SRating,
} from '../header/style'

export const GameStatisticsContent = () => {

    const { lobbieDataListState } = useTypedSelector(state => state.lobbieDataListState)
    const { translationStateGamePage } = useTypedSelector(state => state.translationStateGamePage)

    const [ remainingData, setRemainingData ] = useState<{
        difference: number,
        days: string,
        hours: string,
        minutes: string,
        seconds: string
    }>(getTimeRemaining(lobbieDataListState.startGameTime))
    
    useEffect(() => {
        const countdown = setInterval(() => {
            setRemainingData(getTimeRemaining(lobbieDataListState.startGameTime))
        }, 1000)
      
        return () => {
            clearInterval(countdown)
        }
    }, [])

    return (
        <SRatingWrap>
            <SRating>
                {translation('Время в игре', translationStateGamePage, textsGamePage)}: 
                <span style={{'color' : '#ECAC07', 'paddingLeft' : '4px'}}>
                    {remainingData?.hours}:{remainingData?.minutes}:{remainingData?.seconds}
                </span>
            </SRating>
            <SRating>
                {translation('Ход', translationStateGamePage, textsGamePage)}:
                <span style={{'color' : '#ECAC07', 'paddingLeft' : '4px'}}>
                    {lobbieDataListState.totalTurns}
                </span>
            </SRating>
            <SRating>
                {translation('Ставка', translationStateGamePage, textsGamePage)}:
                <span style={{'color' : '#ECAC07', 'paddingLeft' : '4px'}}>
                    Нет
                </span>
            </SRating>
            <SRating>
                {translation('Зрители', translationStateGamePage, textsGamePage)}:
                <span style={{'color' : '#ECAC07', 'paddingLeft' : '4px'}}>
                    0
                </span>
            </SRating>
        </SRatingWrap>
    )
}