import styled from 'styled-components'

import { device } from '../../theme'

export const SBlock = styled.div`
    position: relative;
    width: 20vw;
    min-width: 246px;
    height: 35vh;
    border-radius: 0.7vh;
    background: #1A1A1A;
    transform-style: preserve-3d;
    
    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1));
        border-radius: inherit;
        transform: translateZ(-2px);
    }

    @media (${device.tablet}) {
        &::before {
            top: -1px;
            left: -1px;
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }
`