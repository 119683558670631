import {
    useState,
    useMemo,
    useCallback,
    useEffect
} from 'react'

import WTSServise from '../../services/WTSServise'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setCustomTooltipState, closeCustomTooltip } from '../../store/actionCreators/customTooltip'

import { Checkbox } from '../checkbox'
import ReCaptcha from '@matt-block/react-recaptcha-v2';
import { Spinner } from '../spinner'

import { socialButtonsConfig, textsAuthPage } from '../authorization/config'
import { translation } from '../../utils/translation'

import {
    SLabel,
    SInput,
    SA,
    SDarkgrayBox,
    SBtn,
    SP,
    SLine,
    SGridBox,
    SButton,
    SForm,
    SReCaptchaWrap
} from './style'

export const EntryContent = ({textSocialButtonsFun} : {textSocialButtonsFun: (id: string) => string | null}) => {

    const dispatch = useDispatch()

    const { translationStateAuthPage } = useTypedSelector(state => state.translationStateAuthPage)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [valueValidUsername, setValueValidUsername] = useState<string | null>(null)
    const [valueValidPassword, setValueValidPassword] = useState<string | null>(null)
    const [validUsername, setValidUsername] = useState<boolean | null>(null)
    const [validPassword, setValidPassword] = useState<boolean | null>(null)
    const [validReCaptcha, setValidReCaptcha] = useState<boolean | null>(null)
    const [checkedRememberMe, setCheckedRememberMe] = useState(true)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        /* (function() { 
            const reCaptchaWidth = 302
            const form = document.querySelector('form')
            const formWidth = form?.getBoundingClientRect().width;
            console.log(formWidth, 'nen')
            if(formWidth) {
                if(reCaptchaWidth > formWidth) {
                    const reCaptchaScale = formWidth / reCaptchaWidth
                    const recaptcha = document.querySelector('g-recaptcha') as HTMLElement
                    recaptcha!.style.transform = `scale(${reCaptchaScale})`;
                    recaptcha!.style.transformOrigin = 'left top';
                }
            }           
        })() */
    }, [])
    
    const checkValidUsername = useCallback((username: string) => {
        if(username === '') {
            setValidUsername(false)
            setValueValidUsername(translation('Пустое поле почты или никнейма', translationStateAuthPage, textsAuthPage))
            return
        }
        setValidUsername(true)
        setValueValidUsername(null)
        /* if(!(/\S+@\S+\.\S+/.test(username))) {
            setValidUsername(false)
            dispatch(setCustomTooltipState({
                status: 'notice',
                titleText: 'Уведомление',
                text: 'Введите корректный email'
            }))
            return false
        } */
    }, [translationStateAuthPage])

    const checkValidPassword = useCallback((password: string) => {
        if(password === '') {
            setValidPassword(false)
            setValueValidPassword(translation('Пустое поле пароля', translationStateAuthPage, textsAuthPage))
            return
        }
        if (password.length < 8) {
            setValidPassword(false)
            setValueValidPassword(translation('Длина пароля менее 8 символов', translationStateAuthPage, textsAuthPage))
            return
        }
        setValidPassword(true)
        setValueValidPassword(null)
    }, [translationStateAuthPage])

    const checkValidReCaptcha = useCallback(() => {
        if(!validReCaptcha) {
            setValidReCaptcha(false)
            /* dispatch(setCustomTooltipState({
                status: 'notice',
                titleText: 'Уведомление',
                text: 'Проверка reCAPTCHA не пройдена'
            })) */
            return
        }
        setValidReCaptcha(true)
        return
    }, [])

    const changeUsername = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        checkValidUsername(e.target.value)
        setUsername(e.target.value)
    }, [])

    const changePassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        checkValidPassword(e.target.value)
        setPassword(e.target.value)
    }, [])

    const login = async (username: string, password: string) => {
        setLoading(true)
        try {
            const response = await WTSServise.login(username, password)

            if (checkedRememberMe) {
                localStorage.setItem('accessToken', response.data.data.tokens.accessToken)
                localStorage.setItem('userId', response.data.data.user.id)
                localStorage.setItem('currentUserNickname', response.data.data.user.username)
            }

            if (!checkedRememberMe) {
                sessionStorage.setItem('accessToken', response.data.data.tokens.accessToken)
                sessionStorage.setItem('userId', response.data.data.user.id)
                sessionStorage.setItem('currentUserNickname', response.data.data.user.username)
            }

            dispatch(closeCustomTooltip())
            setLoading(false)
            window.location.href = '/user'

        } catch (e: any) {
            
            setLoading(false)
            
            if (!e) return
            
            if (e.request.status === 400) {
                dispatch(setCustomTooltipState({
                    status: 'error',
                    titleText: translation('Ошибка', translationStateAuthPage, textsAuthPage),
                    text: translation('Электронная почта или пароль неверны', translationStateAuthPage, textsAuthPage)
                }))
                return
            }
        
            dispatch(setCustomTooltipState({
                status: 'error',
                titleText: translation('Ошибка', translationStateAuthPage, textsAuthPage),
                text: translation('Ошибка сервера', translationStateAuthPage, textsAuthPage)
            }))
        }
    }

    const postData = () => {
        if(validUsername && validPassword /* && validReCaptcha */) {
            login(username, password)
        } else {
            if (!validUsername) {
                dispatch(setCustomTooltipState({
                    status: 'notice',
                    titleText: translation('Уведомление', translationStateAuthPage, textsAuthPage),
                    text: valueValidUsername ? valueValidUsername : translation('Пустое поле почты или никнейма', translationStateAuthPage, textsAuthPage)
                }))
                return
            }

            if(!validPassword) {
                dispatch(setCustomTooltipState({
                    status: 'notice',
                    titleText: translation('Уведомление', translationStateAuthPage, textsAuthPage),
                    text: valueValidPassword ? valueValidPassword : translation('Пустое поле пароля', translationStateAuthPage, textsAuthPage)
                }))
                return
            }
        }
    }

    const handleKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            postData()
        }
    }, [])

    const checkedRememberMeChange = useCallback((newState: boolean) => {
        setCheckedRememberMe(newState)
    }, [])

    const socialEntryButtons = useMemo(() => {
        return socialButtonsConfig.map(({id, icon}, i) => {
            return (
                <SButton
                    id={id}
                    gridArea={id}
                    key={id}
                    green={!i} 
                    odd={i%2}
                    icon={icon}
                    entry={true}
                >
                    {textSocialButtonsFun(id)}
                </SButton>
            )
        })
    }, [textSocialButtonsFun])

    return (
        <>
            <SForm
                action=''
                method='post'
                name='form'
                className='.form'
            >
                <SLabel
                    htmlFor='usernameInput'
                >
                    {`${translation('Электронная почта или никнейм', translationStateAuthPage, textsAuthPage)}:`}
                </SLabel>
                <SInput
                    id='usernameInput'
                    type='text' 
                    name='username' 
                    placeholder=''
                    value={username}
                    autoComplete='username'
                    onChange={(e) => changeUsername(e)}
                    className={username ? (validUsername === null ? undefined : (validUsername ? 'green' : 'red')) : undefined}
                />
                <SLabel htmlFor='passwordInput'>
                    {`${translation('Пароль', translationStateAuthPage, textsAuthPage)}:`}
                </SLabel>
                <SInput
                    id='passwordInput'
                    type='password' 
                    name='password'
                    value={password}
                    autoComplete='password'
                    onChange={(e) => changePassword(e)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    className={password ? (validPassword === null ? undefined : (validPassword ? 'green' : 'red')) : undefined}
                />
                <Checkbox 
                    initialState={checkedRememberMe}
                    id='rememberMe'
                    onCheckboxChange={checkedRememberMeChange}
                >
                    <span>
                        {translation('Запомнить меня', translationStateAuthPage, textsAuthPage)}
                    </span>
                </Checkbox>
            </SForm>
            <SReCaptchaWrap>
                <ReCaptcha
                    siteKey='6LcROE0nAAAAAEywxo7XFl6vczVHl96THQHTWiJT'
                    theme="light"
                    size="normal"
                    onSuccess={(captcha) => setValidReCaptcha(true) /* console.log(`Successful, result is ${captcha}`) */}
                    onError={() => setValidReCaptcha(false)/* console.log("Something went wrong, check your conenction") */}
                    onExpire={() => setValidReCaptcha(false)/* console.log("Verification has expired, re-verify.") */}
                />
            </SReCaptchaWrap>
            
            <SA href='/password'>
                {translation('Восстановить пароль', translationStateAuthPage, textsAuthPage)}
            </SA>
            <SDarkgrayBox>
                {loading 
                    ? <Spinner fontSize='4.5vmin' height='5.5vh'/> 
                    : <SBtn 
                        width={'25vh'} 
                        id='submit'
                        onClick={postData}
                    >
                        {translation('ПРОДОЛЖИТЬ', translationStateAuthPage, textsAuthPage)}
                    </SBtn>
                }

                <SP>
                    {`${translation('Вход с помощью', translationStateAuthPage, textsAuthPage)}:`}
                </SP>
                <SLine/>
                <SGridBox>
                    {socialEntryButtons}
                </SGridBox>
            </SDarkgrayBox>
            
        </>
    )
}
