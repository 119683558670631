import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { closeYouLeaveModal } from '../../store/actionCreators/youLeaveModal'

import { textsMainPage } from '../mainContent/config'
import { translation } from '../../utils/translation'

import {
    SP,
    SBtn
} from '../readyToPlayModal/style'
import { 
    SModalWrap,
    STitle,
    SH2,
    SCloseModalBtn,
    SGreyWrap
} from '../newGameModal/style'
import { SBlock } from '../devModal/style'

export const YouLeaveModal = () => {

    const dispatch = useDispatch()

    const { youLeaveModalState } = useTypedSelector(state => state.youLeaveModalState)
    const { translationStateMainPage } = useTypedSelector(state => state.translationStateMainPage)
    
    const leave = () => {

        const accessTokenLocalStorage = localStorage.getItem('accessToken')
        const userIdLocalStorage = localStorage.getItem('userId')
        const accessTokenSessionStorage = sessionStorage.getItem('accessToken')
        const userIdSessionStorage = sessionStorage.getItem('userId')
        const currentUserNicknameLocalStorage = localStorage.getItem('currentUserNickname')
        const currentUserNicknameSessionStorage = sessionStorage.getItem('currentUserNickname')

        if(accessTokenLocalStorage && userIdLocalStorage && currentUserNicknameLocalStorage) {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('userId')
            localStorage.removeItem('currentUserNickname')
        }
        if(accessTokenSessionStorage && userIdSessionStorage && currentUserNicknameSessionStorage) {
            sessionStorage.removeItem('accessToken')
            sessionStorage.removeItem('userId')
            sessionStorage.removeItem('currentUserNickname')
        }
        
        window.location.href = '/login'
        dispatch(closeYouLeaveModal())
    }

    return (
        <SModalWrap
            showModal={youLeaveModalState}
        >
            <SBlock>
                <STitle>
                    <SH2>
                        {translation('ВЫХОДИМ', translationStateMainPage, textsMainPage)}?
                    </SH2>
                    <SCloseModalBtn
                        onClick={() => dispatch(closeYouLeaveModal())}
                    >
                        <span/>
                        <span/>
                    </SCloseModalBtn>
                </STitle>
                <SP marginTop='8vh'>
                    {translation('Вы уверены, что хотите выйти', translationStateMainPage, textsMainPage)}?
                </SP>
                <SGreyWrap style={{'gap' : '2vh'}}>
                    <SBtn
                        color='green'
                        onClick={leave}
                        style={{'minWidth': '80px'}}
                    >
                        {translation('ДА', translationStateMainPage, textsMainPage)}
                    </SBtn>
                    <SBtn
                        color='yellow'
                        onClick={() => dispatch(closeYouLeaveModal())}
                        style={{'minWidth': '80px'}}
                    >
                        {translation('НЕТ', translationStateMainPage, textsMainPage)}
                    </SBtn>
                </SGreyWrap>
            </SBlock>
        </SModalWrap>
    )
}