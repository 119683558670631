import {
    useMemo,
    useState
} from 'react'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { closeYouWinModal } from '../../store/actionCreators/youWinModal'

import { textsGamePage } from '../gameContent/config'
import { translation } from '../../utils/translation'

import youWin from '../../assets/img/you_win.png'
import avatar from '../../assets/img/avatar.png'
import vipImg from '../../assets/icons/vip.svg'
import videoIcon from '../../assets/icons/video_icon.svg'
import noAvatar from '../../assets/img/no_avatar.png'

import {
    SH2,
    SContentWrap,
    SImg,
    SUserWrapUl,
    SAvatar,
    SVip,
    SBlackBgBlock,
    SBlock
} from './style'
import {
    SModalWrap,
    SGreyWrap
} from '../newGameModal/style'
import { SBtn } from '../readyToPlayModal/style'

export const YouWinModal = () => {

    const dispatch = useDispatch()

    const { youWinModalState, youWinModalData } = useTypedSelector(state => state.youWinModalState)
    const { translationStateGamePage } = useTypedSelector(state => state.translationStateGamePage)
    const { PlayersListState } = useTypedSelector(state => state.playersReducer)

    //console.log (PlayersListState);
    //console.log (youWinModalData);

    const [users, setUsers] = useState([
        {
            avatar: avatar,
            nickname: 'NickName 1',
            vip: true
        },
        {
            avatar: avatar,
            nickname: 'NickName 2',
            vip: false
        }
    ])

    useMemo(() => {
        if (PlayersListState && PlayersListState.length){
            PlayersListState.map((player: any, item: number) => {
                console.log (player);
                users[item].vip = false;
                users[item].nickname = player.username;
                users[item].avatar = player.avatarUri;
            });

            setUsers(users);
        }
    },[PlayersListState]);

    const imageOnErrorHandler = (
        event: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
        event.currentTarget.src = noAvatar
    }

    const usersWinList = useMemo(() => {
        return users.map(({avatar, nickname, vip}) => {

            const vipContent = vip 
                ? <SVip src={vipImg} alt='vip'/> 
                : null

            return (
                <li key={nickname}>
                    <SAvatar vip={vip}>
                        {vipContent}
                        <img
                            style={{'height': '9vh', 'borderRadius': '0.8vh'}}
                            src={avatar ? '23332' : '/'}
                            alt='avatar'
                            onError={imageOnErrorHandler}
                        />
                    </SAvatar>
                    <span>
                        {nickname}
                    </span>
                </li>
            )
        })
    }, [PlayersListState, users])

    const closeModal = () => {
        dispatch(closeYouWinModal())
        window.location.href = "/user"
    }

    const btnContent = useMemo(() => {
        /* Нужен тригер */
        return false ?
            <>
                <SBtn
                    color='yellow' 
                    onClick={() => closeModal()}
                >
                    {translation('ОК', translationStateGamePage, textsGamePage)}
                </SBtn>
            </>
            :
            <>
                <SBtn
                    color='yellow'
                    onClick={() => closeModal()}
                    style={{'minWidth': '80px'}}
                >
                    {translation('ЗАБРАТЬ', translationStateGamePage, textsGamePage)}
                </SBtn>
                <SBtn
                    color='green'
                    style={{'color': '#FFF', 'width': '13vw', 'minWidth': '130px'}}
                >
                    <img
                        src={videoIcon}
                        alt='video_icon'
                        style={{'height': '2vh'}}
                    />
                    {translation('УВЕЛИЧИТЬ НАГРАДУ', translationStateGamePage, textsGamePage)}
                </SBtn>
            </>
    }, [])

    return (
        <SModalWrap
            /* padding={'30vh 38vw'} */ 
            showModal={youWinModalState}
        >
            <SBlock>
                <SContentWrap>
                    <SImg
                        src={youWin}
                        alt='you win'
                    />
                    <SH2>
                        {translation('ПОЗДРАВЛЯЕМ', translationStateGamePage, textsGamePage)}
                    </SH2>
                    <SH2>
                        {translation('С ПОБЕДОЙ', translationStateGamePage, textsGamePage)}!
                    </SH2>
                    <div style={{'marginTop': '2vh'}}>
                        <SUserWrapUl>
                            {usersWinList}
                        </SUserWrapUl>
                    </div>
                    <SBlackBgBlock marginTop={'1vh'}>
                        <span style={{'fontSize': '2vmin'}}>
                            0 $
                        </span>
                        <span style={{'color': '#FFC93D', 'fontSize': '2vmin'}}>
                            +0 кг.
                        </span>
                    </SBlackBgBlock>
                </SContentWrap>
                <SGreyWrap>
                    {btnContent}
                </SGreyWrap>
            </SBlock>
        </SModalWrap>
    )
}
