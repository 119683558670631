import {
    useState,
    useEffect,
    useMemo,
    useCallback
} from 'react'

import { socket } from '../../sockets/lobbie/createConnectionSocket'
import postFrameMessage from '../../sockets/lobbie/sendIframeData'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { closeMakeADealModal } from '../../store/actionCreators/makeADealModal'
import { setUpDealDetails } from '../../store/actionCreators/makeADealModal'
import { closeAdditionMenuButton, openMenuButtonHide } from '../../store/actionCreators/menuButtonHide'

import { usersArrTemplate } from './config'
import { colors } from '../gameContent/config'
import { textsGamePage } from '../gameContent/config'
import { translation } from '../../utils/translation'

import dealIcon from '../../assets/icons/deal_icon.svg'
import vipImg from '../../assets/icons/vip.svg'
import dollar from '../../assets/icons/dollar.svg'
import aIcon from '../../assets/icons/a_icon.svg'
import noAvatar from '../../assets/img/no_avatar.png'

import {
    SP,
    SDealIcon,
    STimer,
    SUsersWrap,
    SUser,
    SContentUserWrap,
    SStatsWrap,
    SAvatar,
    SVip,
    SNickName,
    SRang,
    STransparentBlock,
    SItemWrap,
    SItem,
    SChangeSurchargeBlock,
    SSurchargeWrap,
    InputSurcharge,
    SPSurcharge,
    SBtnWrap,
    SBlock
} from './style'
import {
    STitle,
    SH2
} from '../newGameModal/style'
import { SBtn } from '../readyToPlayModal/style'
import { SDotsWrap } from '../volumeModal/style'

export const MakeADealModal = () => {

    const dispatch = useDispatch()

    const { translationStateGamePage } = useTypedSelector(state => state.translationStateGamePage)
    const { PlayersListState } = useTypedSelector(state => state.playersReducer)
    const {
        makeADealModalState,
        makeADealModalDataState,
        dealDetails
    } = useTypedSelector(state => state.makeADealModalState)

    const [remainingSeconds, setRemainingSeconds] = useState(180000)
    const [surcharge, setSurcharge] = useState(20)
    const [dealPlayers, setDealPlayers] = useState({});

    let [usersArr, setUsersArr] = useState(usersArrTemplate);
    let [capital, setCapital] = useState(0);

    const timer = new Date(remainingSeconds)
    const minutes = timer.getMinutes() < 10 ? `0${timer.getMinutes()}` : timer.getMinutes()
    const seconds = timer.getSeconds() < 10 ? `0${timer.getSeconds()}` : timer.getSeconds()

    useEffect(() => {
        if (makeADealModalDataState?.data && makeADealModalState) {
            postFrameMessage("get.game.makeadeal.fields", {
                userOne: localStorage.getItem("userId"),
                userTwo: makeADealModalDataState.data.id
            })
        }
        if (makeADealModalDataState && makeADealModalDataState?.data)
            if (makeADealModalDataState.mode == 'partner') {
                usersArr[0].id = makeADealModalDataState.data.trade.initiator.userId;
                usersArr[0].idKey = 1;
                usersArr[0].items = [];
                usersArr[0].common = makeADealModalDataState.data.trade.initiator.balance;
                usersArr[0].weekly = makeADealModalDataState.data.trade.initiator.capital;
                makeADealModalDataState.data.trade.initiatorTiles.map((item: any) => {
                    usersArr[0].items.push({
                        id: item.ownedBy.currentCell,
                        name: String(item.name),
                        price: String(item.monopoly.purchasePrice)
                    })
                })
                usersArr[1].id = makeADealModalDataState.data.trade.partner.userId;
                usersArr[1].idKey = 2;
                usersArr[1].items = [];
                usersArr[1].common = makeADealModalDataState.data.trade.partner.balance;
                usersArr[1].weekly = makeADealModalDataState.data.trade.partner.capital;
                makeADealModalDataState.data.trade.partnerTiles.map((item: any) => {
                    usersArr[1].items.push({
                        id: item.ownedBy.currentCell,
                        name: String(item.name),
                        price: String(item.monopoly.purchasePrice)
                    })
                })

                setCapital(0);
                setUsersArr(usersArr)
                dispatch(setUpDealDetails({}))

                console.log("NEW DATA", usersArr);
            }

    }, [PlayersListState, makeADealModalState, makeADealModalDataState]);

    const updateUserArrByDealDetails = () => {
        capital = 0;

        usersArr[0].id = Number(localStorage.getItem("userId"));

        //Fixed
        if (dealDetails?.fieldsData?.u2)
            usersArr[1].id = dealDetails.fieldsData.u2.userId;

        //Set up fields of tails
        if (usersArr /*&& dealDetails.fieldsData*/) {
            usersArr.map(item => {

                item.items = [];

                if (dealDetails.fieldsData) {
                    if (dealDetails.fieldsData.u1?.userId == item.id) {

                        dealDetails.fieldsData.u1.fields.map((dealItem: any) => {
                            item.items.push({
                                id: dealItem.fieldId,
                                name: dealItem.name,
                                price: String(dealItem.monopoly.purchasePrice)
                            })
                            capital += Number(dealItem.monopoly.purchasePrice);
                        })

                    } else {

                        dealDetails.fieldsData.u2.fields.map((dealItem: any) => {
                            item.items.push({
                                id: dealItem.fieldId,
                                name: dealItem.name,
                                price: String(dealItem.monopoly.purchasePrice)
                            })
                            capital += Number(dealItem.monopoly.purchasePrice);
                        })
                    }
                }

            })
        }

        //Set up user data
        if (PlayersListState && PlayersListState[0]?.userId)
            usersArr.map(item => {
                if (item.id == PlayersListState[0].userId) {
                    item.nickmame = PlayersListState[0].username;
                    item.idKey = 1;
                    item.common = PlayersListState[0].balance;
                    item.weekly = PlayersListState[0].capital;
                    item.avatar = PlayersListState[0].avatarUri;
                } else /*if (item.id==PlayersListState[1].userId)*/ {
                    item.nickmame = PlayersListState[1].username;
                    item.idKey = 2;
                    item.common = PlayersListState[1].balance;
                    item.weekly = PlayersListState[1].capital;
                    item.avatar = PlayersListState[1].avatarUri;
                }
            });

        setCapital(capital * 0.3);
        setUsersArr(usersArr)
    };

    useMemo(() => {
        if (makeADealModalDataState.mode !== 'partner') {
            updateUserArrByDealDetails();
        }
    }, [setUsersArr, dealDetails])

    useEffect(() => {
        const countdown = setInterval(() => {
            setRemainingSeconds(prevSeconds => {
                if (prevSeconds === 0) {
                    clearInterval(countdown)
                    /* Действия после завершения таймера */
                    dispatch(closeMakeADealModal())
                    return 0
                } else {
                    return prevSeconds - 1000;
                }
            })
        }, 1000)

        return () => {
            clearInterval(countdown);
        }
    }, [])

    const changeSurcharge = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const value = (e.target as HTMLInputElement).value
        if (value) setSurcharge(parseInt(value))
    }

    const convertFieldsArr = (id: number, arr: any) => {
        let outputArr: any = [];
        arr.map((item: any) => {
            outputArr.push(item.id)
        })
        return outputArr;
    };

    const sign = () => {
        // Действия при нажатии на кнопку ПОДПИСАТЬ
        if (makeADealModalDataState.mode == 'partner') {
            socket.emit("lobby.game.trade.accept", {});
        } else {
            let u1 = usersArr[0].id;
            let u2 = usersArr[1].id;

            /*console.log("SEND TRADE", {
                "initiatorTileIds": convertFieldsArr(u1, usersArr[0].items),
                "partnerId": u2,
                "partnerTileIds": convertFieldsArr(u2, usersArr[1].items),
                "additionalPayment": capital * (surcharge / 100)
            });*/

            socket.emit("lobby.game.trade", {
                "initiatorTileIds": convertFieldsArr(u1, usersArr[0].items),
                "partnerId": u2,
                "partnerTileIds": convertFieldsArr(u2, usersArr[1].items),
                "additionalPayment": capital * (surcharge / 100)
            })
        }
        
        dispatch(closeMakeADealModal())
        dispatch(closeAdditionMenuButton())

        postFrameMessage("lobby.game.removeFocus", {
            "action": "sign",
            "userType": makeADealModalDataState.mode
        })
    }

    const cancel = () => {
        // Действия при нажатии на кнопку ОТМЕНИТЬ
        if (makeADealModalDataState.mode == 'partner') {
            socket.emit("lobby.game.trade.decline", {});
        }

        dispatch(closeMakeADealModal())
        dispatch(closeAdditionMenuButton())
        dispatch(openMenuButtonHide());

        postFrameMessage("lobby.game.removeFocus", {
            "action": "cancel",
            "userType": makeADealModalDataState.mode
        })
    }

    const clickItem = () => {
        console.log("CLICK ITEM");
    };

    const imageOnErrorHandler = (
        event: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
        event.currentTarget.src = noAvatar
    }

    const usersContent = useMemo(() => {
        return usersArr.map(({idKey, avatar, nickmame, rang, vip, common, weekly, items}) => {
            const vipContent = vip 
                ? <SVip
                    src={vipImg}
                    alt='vip'
                  /> 
                : null

            let totalPrice = 0;

            for (const item of items) {
                totalPrice += parseInt(item.price.replace(/\s/g, ''))
            }

            const itemsContent = items.map(({name, price}, i) => {
                return (
                    <SItem
                        key={i}
                        onClick={clickItem}
                    >
                        <span>
                            {name}
                        </span>
                        <span>
                            {price}
                        </span>
                    </SItem>
                )
            })

            return (
                <SUser
                    key={idKey}
                    colors={colors[idKey]}
                >
                    <SContentUserWrap>
                        <SStatsWrap>
                            <SAvatar vip={vip}>
                                {vipContent}
                                <img
                                    src={avatar ? avatar : "/"}
                                    alt='avatar'
                                    onError={imageOnErrorHandler}
                                />
                            </SAvatar>
                            <SNickName>
                                {nickmame}
                            </SNickName>
                            <SRang>
                                ({translation(rang, translationStateGamePage, textsGamePage)})
                            </SRang>
                            <STransparentBlock gridArea='totalCapital'>  
                                <span>
                                    <img
                                        src={dollar}
                                        alt='dollar'
                                        style={{'height': '1.7vh'}}
                                    />
                                    {common}
                                </span>
                            </STransparentBlock>
                            <STransparentBlock gridArea='weeklyCapital'>
                                <span>
                                    <img
                                        src={aIcon}
                                        alt='a'
                                        style={{'height': '1.3vh'}}
                                    />
                                    {weekly}
                                </span>
                            </STransparentBlock>
                        </SStatsWrap>
                        <SItemWrap>
                            {itemsContent}
                        </SItemWrap>
                        <SP>
                            {translation('Сумма активов', translationStateGamePage, textsGamePage)}:
                            <span style={{'color': '#FFC93D', 'marginLeft': '1vh'}}>
                                {totalPrice.toLocaleString('en-US').replace(/,/g, ' ')}
                            </span>
                        </SP>
                    </SContentUserWrap>

                </SUser>
            )
        })
    }, [dealDetails, translationStateGamePage])

    const youWillPayTextRender = useCallback((data: any): string => {
        if(data[0].items.length !== 0 && data[1].items.length !== 0) {
            return `${translation('Вы доплатите', translationStateGamePage, textsGamePage)}`
        }

        if(data[0].items.length === 0) {
            return `${translation('Вы доплатите', translationStateGamePage, textsGamePage)}`
        }

        return `${translation('Вам доплатят', translationStateGamePage, textsGamePage)}`
    }, [usersArr])

    return (
        <SBlock
            style={{
                'position': 'absolute',
                'top': '26.5vh',
                'left': '26vw',
                'width': '50vw',
                'height': '51vh'
            }}
            showModal={makeADealModalState}
        >
            <STitle>
                <SH2>
                    {translation('ЗАКЛЮЧАЕМ СДЕЛКУ', translationStateGamePage, textsGamePage)}
                </SH2>
                <SDealIcon
                    src={dealIcon}
                    alt='deal icon'
                />
                <STimer>
                    {minutes}:{seconds}
                </STimer>
            </STitle>
            <SUsersWrap>
                {usersContent}
            </SUsersWrap>
            <SChangeSurchargeBlock>
                <SSurchargeWrap>
                    <SPSurcharge>
                        {youWillPayTextRender(usersArr)}: 
                        {makeADealModalDataState.data?.trade.additionalPayment 
                            ? ` ${String(Math.round(makeADealModalDataState.data.trade.additionalPayment)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
                            : ` ${String(Math.round(capital * (surcharge / 100))).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
                        }$
                    </SPSurcharge>
                    <span style={makeADealModalDataState.mode == 'partner' ? {opacity: '0'} : {}}>
                        {surcharge}%
                    </span>
                </SSurchargeWrap>

                <SDotsWrap style={makeADealModalDataState.mode == 'partner' ? {opacity: '0'} : {'width': '98%'}}>
                    <span/><span/><span/><span/><span/>
                    <span/><span/><span/><span/><span/>
                    <span/><span/><span/><span/><span/>
                    <span/><span/><span/><span/><span/>
                    <span/><span/><span/><span/><span/>
                    <span/><span/><span/><span/>
                </SDotsWrap>
                <InputSurcharge
                    type="range"
                    min="0"
                    max="100"
                    step="10"
                    style={makeADealModalDataState.mode == 'partner' ? {opacity: '0'} : {}}
                    value={surcharge}
                    onChange={(e) => changeSurcharge(e)}
                />
            </SChangeSurchargeBlock>
            <SBtnWrap>
                <SBtn
                    color='green'
                    onClick={sign}
                >
                    {translation('ПОДПИСАТЬ', translationStateGamePage, textsGamePage)}
                </SBtn>
                <SBtn
                    color='yellow'
                    onClick={cancel}
                >
                    {translation('ОТМЕНИТЬ', translationStateGamePage, textsGamePage)}
                </SBtn>
            </SBtnWrap>
        </SBlock>
    )
}