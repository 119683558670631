export const getTimeRemaining = (serverTime: string) => {

    const difference = Date.parse(String(new Date)) - Date.parse(serverTime)

    const days = Math.floor((difference/(1000*60*60*24)))
    const hours = Math.floor((difference/(1000*60*60) % 24))
    const minutes = Math.floor((difference/1000/60) % 60)
    const seconds = Math.floor((difference/1000) % 60)

    function getZero(num: number){
        if (num >= 0 && num < 10) {
            return '0' + num
        } else {
            return String(num)
        }
    }

    return {
        difference: difference,
        days: getZero(days),
        hours: getZero(hours),
        minutes: getZero(minutes),
        seconds: getZero(seconds)
    }
}