import {
    useState,
    useMemo,
    useCallback
} from 'react'

import { socket } from '../../sockets/lobbie/createConnectionSocket'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setNewGameModal } from '../../store/actionCreators/newGameModal'
import { setCustomTooltipState, closeCustomTooltip } from '../../store/actionCreators/customTooltip'

import { Checkbox } from '../checkbox'

import { textsMainPage } from '../mainContent/config'
import { translation } from '../../utils/translation'

import userBtn from '../../assets/icons/userBtn.svg'
import userValue from '../../assets/icons/userValue.svg'

import {
    SModalWrap,
    STitle,
    SH2,
    SCloseModalBtn,
    SWrap,
    SBtn,
    SValueGamersWrap,
    SBtnGamers,
    SValueWrap,
    SGreyWrap,
    SBlock
} from './style'
import { 
    SLabel, 
    SInput 
} from '../entryContent/style'

export const NewGameModal = () => {

    const dispatch = useDispatch()

    const { newGameModalState } = useTypedSelector(state => state.newGameModalState)
    const { translationStateMainPage } = useTypedSelector(state => state.translationStateMainPage)

    const [valueValidNameGame, setValueValidNameGame] = useState<string | null>(null)
    const [valueValidPassword, setValueValidPassword] = useState<string | null>(null)
    const [validNameGame, setValidNameGame] = useState<boolean | null>(null)
    const [validPassword, setValidPassword] = useState<boolean | null>(null)
    const [nameGame, setNameGame] = useState('')
    const [typeGame, setTypeGame] = useState('oneAgainstAll') /* group teams oneAgainstAll */
    const [valueGamers, setValueGamers] = useState('2') /* 1-5 */
    const [tableGame, setTableGame] = useState('standard') /* standard large giant */
    const [rateGame, setRateGame] = useState('0')
    const [password, setPassword] = useState('')
    const [checkedNoVipAndCards, setCheckedNoVipAndCards] = useState(false)
    const [checkedNoDeals, setCheckedNoDeals] = useState(false)
    
    const checkValidNameGame = useCallback((name: string) => {
        if(name.length === 0) {
            setValidNameGame(false)
            setValueValidNameGame(translation('Пустое поле название игры', translationStateMainPage, textsMainPage))
            return
        }
        if(name.length < 3 || name.length > 20) {
            setValidNameGame(false)
            setValueValidNameGame(translation('Минимальная длина названия игры не может быть менее 3 символов, максимальная - более 20 символов', translationStateMainPage, textsMainPage))
            return
        }
        setValidNameGame(true)
        setValueValidNameGame(null)
    }, [translationStateMainPage])

    const checkValidPassword = useCallback((password: string) => {
        if (password.length < 8) {
            setValueValidPassword(translation('Длина пароля менее 8 символов', translationStateMainPage, textsMainPage))
            setValidPassword(false)
            return
        }
        setValidPassword(true)
        setValueValidPassword(null)
    }, [translationStateMainPage])

    const transformStateModalInObj = () => {
        socket.emit("lobby.create", {
            "name": nameGame,
            "fieldType": "standard",
            "gameType": "1vs1vs1vs1vs1", 
            "bet": 0,
            "password": "",
            "deals": false,
            "vip": false,
            "maxPlayers": 2
        });
    }

    const typeGameButtons = useMemo(() => {
        return ['group', 'teams', 'oneAgainstAll'].map((item, i) => {

            const firstItem = item === 'oneAgainstAll' ? null : <img src={userBtn} alt='user icon'/>
            const vs = item === 'teams' || item === 'oneAgainstAll' ? <span>VS</span> : <img src={userBtn} alt='user icon'/>

            return (
                <SBtn
                    disabled={i <= 1 ? true : false}
                    key={i}
                    data-type={item} 
                    className={typeGame === item ? 'active' : undefined}
                    style={{'minWidth': '65px'}}
                >
                    {firstItem}
                    <img src={userBtn} alt='user icon'/>
                    {vs}
                    <img src={userBtn} alt='user icon'/>
                    <img src={userBtn} alt='user icon'/>
                </SBtn>
            )
        })
    }, [typeGame])

    const valueGamersButtons = useMemo(() => {
        return ['1', '2', '3', '4', '5'].map((item, i) => {
            return (
                <SBtnGamers
                    disabled={i > 1 ? true : false}
                    key={i}
                    data-type={item} 
                    className={ i + 1 <= +valueGamers ? 'active' : undefined}
                >
                    <img src={userValue} alt='user icon'/>
                </SBtnGamers>
            )
        })
    }, [valueGamers])

    const tableGameButtons = useMemo(() => {
        return ['standard', 'large', 'giant'].map((item, i) => {
            const textsBtn = [
                translation('СТАНДАРТНЫЙ', translationStateMainPage, textsMainPage), 
                translation('БОЛЬШОЙ', translationStateMainPage, textsMainPage), 
                translation('ГИГАНТСКИЙ', translationStateMainPage, textsMainPage)
            ]
            return (
                <SBtn
                    disabled={i ? true : false}
                    key={i}
                    data-type={item} 
                    className={ tableGame === item ? 'active' : undefined}
                    style={{'minWidth': '70px'}}
                >
                    {textsBtn[i]}
                </SBtn>
            )
        })
    }, [tableGame, translationStateMainPage])

    const rateGameButtons = useMemo(() => {
        return ['0', '1', '3', '5'].map((item, i) => {
            const textsBtn = [
                translation('БЕЗ СТАВКИ', translationStateMainPage, textsMainPage),
                `1 ${translation('кг', translationStateMainPage, textsMainPage)}`,
                `3 ${translation('кг', translationStateMainPage, textsMainPage)}`,
                `5 ${translation('кг', translationStateMainPage, textsMainPage)}`
            ]
            return (
                <SBtn
                    disabled={i ? true : false}
                    key={i}
                    width='6vw' 
                    data-type={item} 
                    className={ rateGame === item ? 'active' : undefined} 
                    color={i ? '#ECAC07' : undefined}
                    style={{'minWidth': '55px'}}
                >
                    {textsBtn[i]}
                </SBtn>
            )
        })
    }, [rateGame, translationStateMainPage])

    const submitData = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        if(validNameGame && (validPassword === null || validPassword === true)){
            transformStateModalInObj()
            dispatch(setNewGameModal())
            dispatch(closeCustomTooltip())
            return
        } else {
            if(!validNameGame) {
                dispatch(setCustomTooltipState({
                    status: 'notice',
                    titleText: translation('Уведомление', translationStateMainPage, textsMainPage),
                    text: valueValidNameGame ? valueValidNameGame : translation('Пустое поле название игры', translationStateMainPage, textsMainPage)
                }))
                return
            }
            if(validPassword === false) {
                dispatch(setCustomTooltipState({
                    status: 'notice',
                    titleText: translation('Уведомление', translationStateMainPage, textsMainPage),
                    text: valueValidPassword ? valueValidPassword : ''
                }))
                return
            }
        }
        
    }

    const changeNameGame = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNameGame(e.target.value)
        checkValidNameGame(e.target.value)
    }

    const changeTypeGame = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        const data = (e.target as HTMLDivElement).getAttribute('data-type')
        if (data) setTypeGame(data)
    }

    const changeValueGamers = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        const data = (e.target as HTMLDivElement).getAttribute('data-type')
        if (data) {
            data === '1' ? setValueGamers('2') : setValueGamers(data)
        }
    }

    const changeTableGame = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        const data = (e.target as HTMLDivElement).getAttribute('data-type')
        if (data) setTableGame(data)
    }

    const changeRateGame = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        const data = (e.target as HTMLDivElement).getAttribute('data-type')
        if (data) setRateGame(data)
    }

    const changePasswordGame = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
        checkValidPassword(e.target.value)
    }

    const checkedNoVipAndCardsChange = (newState: boolean) => {
        setCheckedNoVipAndCards(newState)
    }

    const checkedNoDealsChange = (newState: boolean) => {
        setCheckedNoDeals(newState)
    }

    return (
        <SModalWrap
            showModal={newGameModalState}
        >
            <SBlock>
                <STitle>
                    <SH2>
                        {translation('СОЗДАТЬ ИГРУ', translationStateMainPage, textsMainPage)}
                    </SH2>
                    <SCloseModalBtn
                        onClick={() => dispatch(setNewGameModal())}
                    >
                        <span/>
                        <span/>
                    </SCloseModalBtn>
                </STitle>
                <form
                    action=''
                    method='post'
                    name='form'
                    style={{'margin' : '0 3vh'}}
                >
                    <SLabel
                        htmlFor='nameGame'
                        marginTop={'2.5vh'}
                        fontSize={'1.8vmin'}
                    >
                        {translation('Название игры', translationStateMainPage, textsMainPage)}:
                    </SLabel>
                    <SInput
                        type='text' 
                        name='nameGame'
                        id='nameGame'
                        onChange={(e) => changeNameGame(e)}
                        value={nameGame}
                        className={nameGame ? (validNameGame === null ? undefined : (validNameGame ? 'green' : 'red')) : undefined}
                    />
                    <SLabel
                        htmlFor='typeGame'
                        marginTop={'2.5vh'}
                        fontSize={'1.8vmin'}
                    >
                        {translation('Тип игры', translationStateMainPage, textsMainPage)}:
                    </SLabel>
                    <SWrap
                        onClick={(e) => changeTypeGame(e)}
                    >
                        {typeGameButtons}
                    </SWrap>
                    <SLabel
                        htmlFor='valueGamers'
                        marginTop={'2.5vh'}
                        fontSize={'1.8vmin'}
                    >
                        {translation('Количество игроков', translationStateMainPage, textsMainPage)}:
                    </SLabel>
                    <SValueGamersWrap
                        onClick={(e) => changeValueGamers(e)}
                    >
                        {valueGamersButtons}
                        <SValueWrap>
                            <span
                                style={{'color' : '#ECAC07'}}
                            >
                                {valueGamers}
                            </span>
                            <span>
                                /5
                            </span>
                        </SValueWrap>
                    </SValueGamersWrap>
                    <SLabel
                        htmlFor='tableGame'
                        marginTop={'2.5vh'}
                        fontSize={'1.8vmin'}
                    >
                        {translation('Игровой стол', translationStateMainPage, textsMainPage)}:
                    </SLabel>
                    <SWrap
                        onClick={(e) => changeTableGame(e)}
                    >
                        {tableGameButtons}
                    </SWrap>
                    <SLabel
                        htmlFor='rateGame'
                        marginTop={'2.5vh'}
                        fontSize={'1.8vmin'}
                    >
                        {`${translation('Ставка', translationStateMainPage, textsMainPage)} (${translation('золото', translationStateMainPage, textsMainPage)}):`}
                    </SLabel>
                    <SWrap
                        onClick={(e) => changeRateGame(e)}
                    >
                        {rateGameButtons}
                    </SWrap>
                    <SLabel
                        htmlFor='passwordGame' 
                        marginTop={'2.5vh'}
                        fontSize={'1.8vmin'}
                    >
                        {`${translation('Пароль', translationStateMainPage, textsMainPage)} (${translation('для приватной игры', translationStateMainPage, textsMainPage)}):`}
                    </SLabel>
                    <SInput 
                        type='passwordGame' 
                        name='passwordGame'
                        id='passwordGame'
                        onChange={(e) => changePasswordGame(e)}
                        value={password}
                        className={password ? (validPassword === null ? undefined : (validPassword ? 'green' : 'red')) : undefined}
                    />
                    <Checkbox
                        marginTop={'2.5vh'}
                        initialState={false} 
                        id='noVipAndCards'
                        onCheckboxChange={checkedNoVipAndCardsChange}
                    >
                        <span>
                            {translation('Без VIP и карт', translationStateMainPage, textsMainPage)}
                        </span>
                    </Checkbox>
                    <Checkbox 
                        initialState={true} 
                        id='noDeals'
                        onCheckboxChange={checkedNoDealsChange}
                    >
                        <span>
                            {translation('Без сделок', translationStateMainPage, textsMainPage)}
                        </span>
                    </Checkbox>
                    <SGreyWrap>
                        <SBtn
                            className={'active'}
                            onClick={(e) => submitData(e)}
                            style={{'minWidth': '80px'}}
                        >
                            {translation('СОЗДАТЬ ИГРУ', translationStateMainPage, textsMainPage)}
                        </SBtn>
                    </SGreyWrap>
                </form>
            </SBlock>
        </SModalWrap>
    )
}