import { ChatGame } from "../../components/chatGame"
import ConnectChatSocket from '../../sockets/chat/createChatConnectionsSocket';
import {useMemo} from "react";

export const GameChatPage = (data : any) => {
    useMemo(() => {
        ConnectChatSocket()
    }, [data]);

    return (
        <ChatGame/>
    )
}