import {MenuButtonsAction, MenuButtonsActionType, MenuButtonsState} from '../../types/menuButtonsType'

const initialState: MenuButtonsState = {
    MenuButtonsListState: {}
}

export const menuButtonsReducer = (state = initialState, action: MenuButtonsAction): MenuButtonsState => {
    switch(action.type) {
        case MenuButtonsActionType.SET_UP_BUTTONS:
            return {
                ...state,
                MenuButtonsListState: action.payload
            }
        default:
            return state
    }
}
