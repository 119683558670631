import { NoMoneyModalActionType, NoMoneyModalState, NoMoneyModalAction } from '../../types/noMoneyModalType'
const initialState: NoMoneyModalState = {
    noMoneyModalState: false
}

export const noMoneyModalReducer = (state = initialState, action: NoMoneyModalAction): NoMoneyModalState => {
    switch(action.type) {
        case NoMoneyModalActionType.OPEN_NO_MONEY_MODAL:
            return {
                ...state,
                noMoneyModalState: true
            }
        case NoMoneyModalActionType.CLOSE_NO_MONEY_MODAL:
            return {
                ...state,
                noMoneyModalState: false
            }
        default:
            return state
    }   
}