import { ActiveChatGameBtnActionType, ActiveChatGameBtnState, ActiveChatGameBtnAction } from '../../types/activeChatGameBtnType'

const initialState: ActiveChatGameBtnState = {
    activeChatGameBtnState: 0
}

export const activeChatGameBtnReducer = (state = initialState, action: ActiveChatGameBtnAction): ActiveChatGameBtnState => {
    switch(action.type) {
        case ActiveChatGameBtnActionType.SET_ACTIVE_GAME_CHAT_BTN:
            return {
                ...state,
                activeChatGameBtnState: action.payload
            }
        default: 
            return state
    }
}