import { ActiveTabGameListActionType, ActiveTabGameListState, ActiveTabGameListAction } from '../../types/activeTabGameListType'

const initialState: ActiveTabGameListState = {
    activeTabGameListState: 'gameList'
}

export const activeTabGameListReducer = (state = initialState, action: ActiveTabGameListAction): ActiveTabGameListState => {
    switch(action.type) {
        case ActiveTabGameListActionType.SET_ACTIVE_TAB_GAME_LIST:
            return {
                ...state,
                activeTabGameListState: action.payload
            }
        default: 
            return state
    }
}