import styled, { css } from 'styled-components'

import { device } from '../../theme'

import scrollLines from '../../assets/icons/scroll_lines.svg'
import gameListBG from '../../assets/img/gameListBG.jpg'

export const SLobbiesList = styled.div`
    width: 100%;
    height: 32vh;
    grid-area: gameList;
`

export const SButtonWrap = styled.div<{height?: string}>`
    display: flex;
    height: 3.5vh;
    padding-left: 1.5vh;

    & > *:nth-child(1) {
        z-index: 4;
    }

    & > *:nth-child(2) {
        z-index: 3;
    }

    & > *:nth-child(3) {
        z-index: 2;
    }

    & > *:nth-child(4) {
        z-index: 1;
    }
`

const newMsgCss = css<{newMsg?: boolean}>`
    &::after {
        content: attr(data-new-msg);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FCEDD6;
        font-size: 1.2vmin;
        position: absolute;
        width: 1.8vh;
        height: 1.8vh;
        top: -20%;
        right: 10%;
        transform: translateZ(1px);
        background: red;
        border-radius: 100%;
        border: 1px solid #FCEDD6;
        box-shadow: 0px 4px 19px #D9D9D9;
    }
`

export const SListButton = styled.button<{newMsg?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5vh;
    height: 3.5vh;
    color: #FFF;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
        #414141;
    box-shadow: 2px -4px 7px rgba(0, 0, 0, 0.50);
    font-size: 1.2vmin;
    border-radius: 1vh 2.5vh 0 0;
    text-align: center;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transform-style: preserve-3d;
    width: 7.5vw;
    min-width: 60px;
    margin-left: -2px;
    z-index: 1;

    >*{
        opacity: 0.6;
    }

    >span {
        margin-top: -0.5vh;
    }

    @media (${device.tablet}) {
        min-width: 85px;
        font-size: 9px;
    }

    @media (${device.mobileL}) {
        min-width: 80px;
        font-size: 8px;
    }

    @media (${device.mobileM}) {
        min-width: 70px;
        font-size: 7px;
    }

    @media (${device.mobileS}) {
        min-width: 60px;
    }

    &.active {
        z-index: 10;
        background: 
            linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
            linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
            linear-gradient(0deg, #1A1A1A 0%, #1A1A1A 100%), 
            linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%), 
            linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), 
            #613A24;
        
        >*{
            opacity: 1;
        }
        
        &::before {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            background: linear-gradient(-45deg, rgba(255, 89, 36, 100), rgba(255, 162, 41, 100));
            border-radius: inherit;
            transform: translateZ(-1px);
        }

        @media (${device.tablet}) {
            &::before {
                top: -1px;
                left: -1px;
                position: absolute;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
            }
        }
    }

    ${({newMsg}) => newMsg ? newMsgCss : null}
`

export const SFilterWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 4vh;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
        #1A1A1A;
    border-radius: 1.4vh 1.4vh 0 0;
    padding-left: 1vw;
`

export const SFilter = styled.span<{width: string}>`
    width: ${({width}) => width};
    color: #FFC93D;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    text-align: center;

    @media (${device.mobileL}) {
        font-size: 9px;
    }

    @media (${device.mobileM}) {
        font-size: 8px;
    }
`

export const SLobbiesListContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.3vh;
    padding: 0.5vw;
    height: 27.5vh;
    margin-right: 0.5vh;
    overflow-y: scroll;
    scrollbar-color: #126D11 rgba(0, 0, 0, 0.30);
    scrollbar-width: auto;

    ::-webkit-scrollbar {
        width: 2vh;
        height: 100%;
        background: rgba(0, 0, 0, 0.30);
    }

    ::-webkit-scrollbar-thumb {
        background: 
            url(${scrollLines}) center center/100% no-repeat, 
            linear-gradient(90deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), 
            linear-gradient(270deg, #2D7500 0%, #276207 42.19%, #132E05 100%);
        border: 1px solid #8EFF59;
        border-radius: 2vh;
    }
`

export const SGameLobby = styled.ul`
    display: flex;
    justify-content: start;
    align-items: center;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 89.06%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), 
        #313131;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
    border-radius: 0.4vh;
    height: 6vh;
    padding: 0.5vh 0 0.5vh 0.5vw;
    transform-style: preserve-3d;

    &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(rgba(220, 220, 220, 0.5), rgba(90, 90, 90, 0.5));
        border-radius: inherit;
        transform: translateZ(-1px);
    }
`

export const SLi = styled.li<{justifyContent?: string, width: string}>`
    display: flex;
    justify-content: ${({justifyContent}) => justifyContent ? justifyContent : 'center'};
    align-items: center;
    text-align: center;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: ${({width}) => width};
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 1px 0px 0px rgba(255, 255, 255, 0.2);
    font-size: 1.8vmin;
    gap: 1vh;
`

export const SVs = styled.span`
    font-size: 1.2vmin;
    margin-top: 0.3vh;
`

export const SImg = styled.img`
    margin: 0 auto;
    height: 2.4vh;
`

export const SButtonPlus = styled.button<{colors: string[]}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4vh;
    height: 4vh;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.3);
    border-radius: 1vh;
    transform-style: preserve-3d;

    &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(4vh + 4px);
        height: calc(4vh + 4px);
        background: ${({colors}) => `linear-gradient(${colors[0]})`};
        border-radius: inherit;
        transform: translateZ(-2px);
        opacity: 0.7;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4vh;
        height: 4vh;
        background: rgba(49, 49, 49, 0.8);
        border-radius: inherit;
        transform: translateZ(-1px);
    }

    > span {
        position: absolute; 
        width: 2vh;
        height: 0.5vh;
        background: #127709;
        border-radius: 2px;
    }

    > span:first-child {
        transform: rotate(90deg);
    }

    >img{
        height: 4vh;
        width: 4vh;
        border-radius: 1vh;
    }
`

export const SLobbieButton = styled.button<{status: string | undefined}>`
    position: relative;
    width: 7vw;
    height: 3.5vh;
    border-radius: 1vh;
    font-size: 2vmin;
    transform-style: preserve-3d;
    color: #FFF;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: ${({status}) => {
        switch(status) {
            case 'play':
                return 'linear-gradient(180deg, #06AE17 0%, #126B10 52.69%, #0B6209 100%), linear-gradient(180deg, #353535 0%, #343434 52.69%, #282828 100%)'
            case 'goOut':
                return 'linear-gradient(180deg, #AE0606 0%, #9F1818 52.69%, #620909 100%)'
            case 'look': 
                return 'linear-gradient(180deg, #EDAE00 0%, #DE8400 52.69%, #D06700 100%)'
            case 'closed':
                return 'linear-gradient(180deg, #06AE17 0%, #126B10 52.69%, #0B6209 100%), linear-gradient(180deg, #353535 0%, #343434 52.69%, #282828 100%)'
            default: 
                return null
        }
    }};
    border: ${({status}) => {
        switch(status) {
            case 'play':
                return '1px solid #BEFF97'
            case 'goOut':
                return '1px solid #FF9797'
            case 'look': 
                return '1px solid #FC8314'
            case 'closed':
                return '1px solid #BEFF97'
            default:
                return null
        }
    }};
    
    &::after {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border-radius: inherit;
        transform: translateZ(1px);
    }

    &::before {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        background: linear-gradient(rgba(29, 29, 29, 1), rgba(77, 77, 77, 1));
        border-radius: inherit;
        transform: translateZ(-1px);
        box-shadow: 0px -0.2vh 0.2vh rgba(125, 125, 125, 0.50);
    }

    &:hover {
        &::after {
            background: rgba(0, 0, 0, 0.2);
        }
    }

    &:focus {
        &::after {
            background: rgba(255, 255, 255, 0.2);
        }
    }
`   

export const SClosed = styled.img`
    position: absolute;
    width: 1.7vh;
    height: 2.5vh;
    left: 1vh;
    top: 0.55vh
`

export const SGameListWrap = styled.div<{gridArea?: string}>`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.7vh;
    transform-style: preserve-3d;
    grid-area: ${({gridArea}) => gridArea};
    background: rgba(0, 0, 0, 0.50);
    transform-style: preserve-3d;
    z-index: 20;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: url(${gameListBG}) center center/cover no-repeat;
        transform: translateZ(-1px);
    }
    
    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1));
        border-radius: inherit;
        transform: translateZ(-2px);
    }

    @media (${device.tablet}) {
        &::before {
            top: -1px;
            left: -1px;
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }
`