import {
    useMemo,
    useState,
    useEffect
} from 'react'

import WTSServise from '../../services/WTSServise'
import ConnectSocket, { socket } from '../../sockets/lobbie/createConnectionSocket'
import { getUserLobbyDataSC, getUsersTimerCallback } from '../../sockets/lobbie/socketHelpCommands'
import postFrameMessage from '../../sockets/lobbie/sendIframeData'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setUpTranslateGamePage } from '../../store/actionCreators/translation'
import { setCustomTooltipState } from '../../store/actionCreators/customTooltip'
import { openMakeADealModal } from '../../store/actionCreators/makeADealModal'
import {
    openAdditionMenuButton,
    closeMenuButtonHide,
    closeAdditionMenuButton,
    openMenuButtonHide
} from '../../store/actionCreators/menuButtonHide'

import { MenuGame } from '../menuGame'
import { AuctionModal } from '../auctionModal'
import { NoMoneyModal } from '../noMoneyModal'
import { YouWinModal } from '../youWinModal'
import { YouLostModal } from '../youLostModal'
import { MakeADealModal } from '../makeADealModal'
import { GiveUpModal } from '../giveUpModal'
import { InfoModal } from '../infoModal'

import { colors } from './config'
import { textsGamePage } from './config'
import { translation } from '../../utils/translation'

import dollar from '../../assets/icons/dollar.svg'
import aIcon from '../../assets/icons/a_icon.svg'
import noAvatar from '../../assets/img/no_avatar.png'

import {
    SGameWrap,
    SGamersField,
    SGamersWpap,
    SDealButton,
    SGamerCard,
    SRang,
    SWpap,
    SAvatarWrap,
    SAvatar,
    SStatsWrap,
    SCommonCapital,
    SWeeklyCapital,
    SIframe,
    SIframeWpap,
    SIframeChatGame,
    SAnimationWrap
} from './style'

import { LobbiesActionType } from '../../types/lobbiesReduserType'
import { PlayersActionType } from '../../types/playersReduserType'
import { gamersListConfigType } from '../../types/gamersListConfigType'
import { LobbieDataActionType } from '../../types/lobbieDataReduserType'

export const GameContent = () => {

    const dispatch = useDispatch()

    const { auctionModalState } = useTypedSelector(state => state.auctionModalState)
    const { PlayersListState } = useTypedSelector(state => state.playersReducer)
    const { currentUserState } = useTypedSelector(state => state.currentUserState)
    const { translationStateGamePage } = useTypedSelector(state => state.translationStateGamePage)
    const { languageState } = useTypedSelector(state => state.languageState)
    const { MenuButtonsListState } = useTypedSelector(state => state.menuButtonsReducer)
    const { makeADealModalState } = useTypedSelector(state => state.makeADealModalState)

    useEffect(() => {
        updateTranslate()
    }, [languageState])

    //ConnectSocket();
    
    const updateTranslate = async () => {
        try {
            const response = await WTSServise.translations(textsGamePage, languageState)
            let translateArr: string[] = []
            response.data.data.phraseTranslations.map((item: any) => {
                if(item.phrase.translation) {
                    translateArr.push(item.phrase.translation.text)
                    return
                } else {
                    translateArr.push(item.phrase.text)
                    return
                }
            })

            dispatch(setUpTranslateGamePage(translateArr))
        
        } catch (e: any) {
            if (!e) return
            
            if (e.request.status === 400) {
                dispatch(setCustomTooltipState({
                    status: 'error',
                    titleText: translation('Ошибка', translationStateGamePage, textsGamePage),
                    text: translation('Не удалось перевести страницу', translationStateGamePage, textsGamePage)
                }))
                return
            }
            
            console.log(e)
        }
    }

    let emptyState: gamersListConfigType[] = []
    let [ gamersList, setGamersList ] = useState(emptyState)

    //let time = 60;
    let [ time, setTime ] = useState(0);
    let [ timeListener , setTimeListener ] = useState({});

    useMemo(() => {
        if (timeListener) { // @ts-ignore
            clearTimeout(timeListener)
        }

        timeListener = setTimeout(() => {
            time = time + 1;
            setTime(time)
        }, 1000);
        setTimeListener(timeListener)
    }, [time])

    useMemo(() => {
        let newArr: gamersListConfigType[] = [];

        if (PlayersListState && PlayersListState.length) {
            PlayersListState.map((player: any) => {
                newArr.push({
                    rang: 'Тестеровщик',
                    id: player.userId,
                    name: player.username,
                    nickname: 'никнейм',
                    capital: {
                        common: player.balance,
                        weekly: player.capital
                    },
                    avatar: player.avatarUri,
                    progress: player.secondsLeft ? (player.secondsLeft / 60) * 100 : 0, /* !!! */
                    seconds: player.secondsLeft ? player.secondsLeft : 0
                });

                /*if ( player.secondsLeft )
                    setTime( player.secondsLeft)*/
            });

            setTime(0)
            setGamersList(newArr)
        } else {
            /* gamersList = gamersListConfig; */
        }
    }, [PlayersListState])

    //gamersList = gamersListConfig;

    const openMakeADeal = (id : number|undefined, name : string, nickname : string, avatar : string) => {

        //Send action to game
        postFrameMessage("get.game.makeadeal.fields", {
            userOne: localStorage.getItem("userId"),
            userTwo : id
        })

        dispatch(openAdditionMenuButton("makeadeal"));
        dispatch(closeMenuButtonHide());

        /*dispatch(openMakeADealModal({
            mode: "initiator",
            data: {
                id: id
            }
        }))*/

    }

    const imageOnErrorHandler = (
        event: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
        event.currentTarget.src = noAvatar
    }

    const gamersContent = useMemo(() => {
        return gamersList.map(({rang, id, name, nickname, capital: {common, weekly}, avatar, progress, seconds}, i) => {

            seconds = seconds > 0 ? seconds - time : 0;
            progress = seconds > 0 ? seconds / 60 * 100 : 0
            
            return (
                <SGamersWpap key={i + 1}>
                    <SAnimationWrap colors={i + 1}>
                        <SDealButton 
                            colors={colors[i + 1]}
                            onClick={() => openMakeADeal(id,name,nickname,avatar)}
                            style={
                                id==Number(localStorage.getItem("userId")) 
                                || (!MenuButtonsListState["lobby.game.dice"] 
                                && !MenuButtonsListState["lobby.game.buy"] 
                                && !MenuButtonsListState["lobby.game.pay"] 
                                && !MenuButtonsListState["lobby.game.auction"]) 
                                ? {display:'none'} 
                                : {}}
                        >
                            {translation('Заключить сделку', translationStateGamePage, textsGamePage)}
                        </SDealButton>
                    </SAnimationWrap>
                    <SGamerCard 
                        colors={colors[i + 1]} 
                        /* banknotes={true или false} */
                    >
                        <SRang
                            colors={colors[i + 1]}
                            data-rang={rang
                                ? translation(rang, translationStateGamePage, textsGamePage)
                                : translation('Новичок', translationStateGamePage, textsGamePage)
                            }
                            progress={progress}
                        />
                        <SWpap>
                            <SAvatarWrap
                                data-seconds={seconds}
                                seconds={seconds}
                            >
                                <SAvatar 
                                    src={currentUserState?.avatarUri ? currentUserState.avatarUri : noAvatar}
                                    alt='avatar'
                                    onError={imageOnErrorHandler}
                                />
                            </SAvatarWrap>
                            <SStatsWrap>
                                <span>
                                    {name}
                                </span>
                                <hr/>
                                <span
                                    style={{'opacity': '0.4', 'fontSize': '1.2vmin'}}
                                >
                                    {nickname}
                                </span>
                                <SCommonCapital>
                                    <img
                                        src={dollar}
                                        alt='dollar'
                                    />
                                    <span>
                                        {String(common).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    </span>
                                </SCommonCapital>
                                <hr/>
                                <SWeeklyCapital>
                                    <img
                                        src={aIcon}
                                        alt='a'
                                    />
                                    <span>
                                        {String(weekly).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    </span>
                                </SWeeklyCapital>
                                <hr/>
                            </SStatsWrap>
                        </SWpap>
                    </SGamerCard>
                </SGamersWpap>
            )
        })
    }, [gamersList, time, translationStateGamePage])

    //TODO Move to single file
    const postFrameMessage = (messageName: string, data: any) => {
        const iframe: any = document.getElementById("iframe")

        if (iframe)
            iframe.contentWindow.postMessage(JSON.stringify({
                ...data,
                ...{
                    type: messageName
                }
            }), "*")
    }

    //---TODO simulation InitGame
    let resp: any, lobby : any
    useMemo(async () => {
        resp = await getUserLobbyDataSC(socket)
        lobby = resp.lobby

        dispatch({
            type: LobbieDataActionType.SET_UP_LOBBIE_DATA,
            payload: lobby
        })

        localStorage.setItem("activeLobbieId", lobby.id)
    }, [])
    //---TODO simulation InitGame

    const iframeOnload: any = async () => {
        postFrameMessage("auth", {
            accessToken: localStorage.getItem("accessToken"),
            refreshToken: localStorage.getItem("refreshToken"),
            userId: localStorage.getItem("userId")
        })

        if (resp)
            postFrameMessage("lobby.get.user.result", resp)

        let timerResult: any = {}
        //Dunamicly get timer
        //Dunamicly get timer
        socket.emit("lobby.get.timer", {})

        socket.on("lobby.get.timer.result", async (ans: any) => {
            let timerResult = ans;

            //- - Don't need on react, just update state - -
            let resp: any = await getUserLobbyDataSC(socket)
            let lobby = resp.lobby
            postFrameMessage("lobby.get.user.result", resp)

            lobby.players.map((item: any) => {
                if (item.userId == ans.currentPlayer.userId) {
                    item.secondsLeft = ans.secondsLeft;
                }
            })

            dispatch({
                type: PlayersActionType.SET_UP_PLAYERS,
                payload: lobby.players
            })
        })
    }

    const iframeLink = process.env.REACT_APP_STATUS == 'LOCAL' ?  'http://localhost:8999' : 'https://donmagnate.app.wtsdemo.ru/'

    const auctionContent = auctionModalState ? <AuctionModal/> : null
    const makeAdealModalContent = makeADealModalState ? <MakeADealModal/> : null

    return (
        <SGameWrap>
            <SGamersField>
                {gamersContent}
            </SGamersField>
            <SIframeWpap>
                <SIframe
                    id="iframe"
                    src={iframeLink}
                    onLoad={iframeOnload}
                />
            </SIframeWpap>
            <MenuGame/>
            {auctionContent}    
            <NoMoneyModal/>
            <YouWinModal/>
            <YouLostModal/>
            {makeAdealModalContent}
            <GiveUpModal/>
            <InfoModal/>
        </SGameWrap>
    )
}
