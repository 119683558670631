export enum ChatlobbyActionType {
    SET_UP_CHATLOBBY = 'SET_UP_CHATLOBBY',
    UPDATE_CHATLOBBY = 'UPDATE_CHATLOBBY'
}

export interface ChatlobbyState {
    ChatlobbyListState: any
}

export interface ChatlobbyAction {
    type: string,
    payload?: any
}
