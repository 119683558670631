export enum YouLeaveModalActionType {
    OPEN_YOU_LEAVE_MODAL = 'OPEN_YOU_LEAVE_MODAL',
    CLOSE_YOU_LEAVE_MODAL = 'CLOSE_YOU_LEAVE_MODAL'
}

export interface YouLeaveModalState {
    youLeaveModalState: boolean
}

export interface YouLeaveModalAction {
    type: string
}