import styled from 'styled-components'

import { device } from '../../theme'

export const SModalWrap = styled.div<{/* padding: string,  */showModal: boolean}>`
    position: relative;
    display: ${({showModal}) => showModal ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 50;
    
`/* padding: ${({padding}) => padding}; */

export const STitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5vh;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
        #1A1A1A;
    border-radius: 1.4vh 1.4vh 0 0;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.70) inset, 0px 4px 10px rgba(0, 0, 0, 0.50) inset;
`

export const SH2 = styled.h2`
    font-size: 2vmin;
    color: #FFC93D;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    @media (${device.mobileL}) {
        font-size: 11px;
    }

    @media (${device.mobileM}) {
        font-size: 10px;
    }
`

export const SCloseModalBtn = styled.button<{width?: string, height?: string, color?: string}>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    width: ${({width}) => width ? width : '5vh'};
    height: ${({height}) => height ? height : '5vh'};

    > span {
        width: ${({width}) => width ? `calc(${width} - 2vh)` : '2.5vh'};
        height: 3px;
        background: ${({color}) => color ? color : '#BDBDBD'};
        position: absolute;
    }

    > span:first-child {
        transform: rotate(45deg);
    }

    > span:nth-child(2) {
        transform: rotate(-45deg);
    }
`

export const SWrap = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1vh;
`

export const SBtn = styled.button<{width?: string, color?: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5vh;
    background: linear-gradient(180deg, #353535 0%, #343434 52.69%, #282828 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50);
    height: 3.5vh;
    width: ${({width}) => width ? width : '8vw'};
    border-radius: 1vh;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: ${({color}) => color ? color : '#FFF'};
    transform-style: preserve-3d;
    
    >img {
        opacity: 0.6;
        height: 2vh;

        @media (${device.tablet}) {
            height: 1.5vh;
        }

        @media (${device.mobileL}) {
            height: 1.1vh;
        }
    }

    >span {
        color: #ECAC07;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: 1.8vmin;
    }

    &::after {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border-radius: inherit;
        transform: translateZ(1px);
    }

    &::before {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        background: linear-gradient(rgba(29, 29, 29, 1), rgba(77, 77, 77, 1));
        border-radius: inherit;
        transform: translateZ(-1px);
        box-shadow: 0px -0.2vh 0.2vh rgba(125, 125, 125, 0.50);
    }

    &:hover {
        &::after {
            background: rgba(0, 0, 0, 0.2);
        }
    }

    &:focus {
        &::after {
            background: rgba(255, 255, 255, 0.2);
        }
    }

    &.active {
        background: linear-gradient(180deg, #06AE17 0%, #126B10 52.69%, #0B6209 100%);
        border: 1px solid #BEFF97;
        color: #FFF;
    }

    &:disabled {
        &::after {
            background: rgba(0, 0, 0, 0.2);
        }
    }

    @media (${device.mobileL}) {
        font-size: 8px;
    }
`

export const SValueGamersWrap = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vh;
    margin-top: 1vh;
`

export const SBtnGamers = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.5vh;
    width: 4.5vh;
    border-radius: 1vh;
    transform-style: preserve-3d;
    transform: translateZ(1px);

    >img{
        margin-top: 1px;
    }

    &::after{
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 90%;
        height: 90%;
        background: rgba(217, 217, 217, 0.2);
        transform: translateZ(-1px);
        border-radius: inherit;
    }

    &::before{
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        box-shadow: 0px -0.2vh 0.2vh rgba(125, 125, 125, 0.50);
        transform: translateZ(-2px);
        border-radius: inherit;
    }
    
    &.active {
        &::after {
            background: rgba(89, 163, 90, 1);
        }
        &::before{
            background: linear-gradient(rgba(125, 255, 23, 0), rgba(83, 146, 73, 1));
        }
    }

    &:hover {
        &::after {
            background: rgba(131, 255, 133, 1);
        }
        &::before{
            background: linear-gradient(rgba(125, 255, 23, 0), rgba(131, 255, 133, 1));
        }
    }

    &:disabled {
        &::after {
            background: rgba(217, 217, 217, 0.2);
        }
        &::before {
            background: none;
        }
    }

    @media (${device.mobileL}) {
        height: 3.5vh;
        width: 3.5vh;
    }
`

export const SValueWrap = styled.div`
    position: absolute;
    right: 3vw;
    top: 50%;
    transform: translateY(-50%);

    @media (${device.mobileL}) {
        font-size: 10px;
        /* top: -2.45vh; */
        right: 0;
        /* transform: none; */
    }
`

export const SGreyWrap = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7vh;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(0deg, #232323 0%, #232323 100%), 
        linear-gradient(215deg, #FFD7CA 0%, #DDCDB7 100%);
    border-radius: 0 0 1.4vh 1.4vh;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.50);
    gap: 2vh;
`

export const SBlock = styled.div`
    position: relative;
    width: 30vw;
    height: 80vh;
    border-radius: 0.7vh;
    background: #1A1A1A;
    transform-style: preserve-3d;
    
    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1));
        border-radius: inherit;
        transform: translateZ(-2px);
    }

    @media (${device.tablet}) {
        height: 70vh;
        min-width: 301px;
        &::before {
            top: -1px;
            left: -1px;
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }

    @media (${device.mobileM}) {
        min-width: 301px;
    }

    @media (${device.mobileS}) {
        min-width: 268px; 
    }
`