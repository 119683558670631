export enum ChatInfoModalActionType {
    CHANGE_CHAT_INFO_MODAL = 'CHANGE_CHAT_INFO_MODAL',
    CLOSE_CHAT_INFO_MODAL = 'CLOSE_CHAT_INFO_MODAL'
}

export interface chatInfoModalState {
    chatInfoModalState: boolean
}

export interface chatInfoModalAction {
    type: string
}