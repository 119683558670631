export enum NewGameModalActionType {
    CHANGE_VALUE_NEW_GAME_MODAL = 'CHANGE_VALUE_NEW_GAME_MODAL'
}

export interface NewGameModalState {
    newGameModalState: boolean
}

export interface NewGameModalAction {
    type: string
}