export enum ActiveTabGameListActionType {
    SET_ACTIVE_TAB_GAME_LIST = 'SET_ACTIVE_TAB_GAME_LIST'
}

export interface ActiveTabGameListState {
    activeTabGameListState: string
}

export interface ActiveTabGameListAction {
    type: string,
    payload: string
}