import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
    changeChatInfoModal,
    closeChatInfoModal
} from '../../store/actionCreators/chatInfoModal'

import { translation } from '../../utils/translation'
import { textsMainPage } from '../mainContent/config'

import { 
    SChatInfoModal,
    SChatInfoModalContent,
    SP,
    SOl,
    SOpenInfoChatModal
} from './style'
import { SCloseModalBtn } from '../newGameModal/style'

export const ChatInfoModal = () => {

    const dispatch = useDispatch()

    const { chatInfoModalState } = useTypedSelector(state => state.chatInfoModalState)
    const { translationStateMainPage } = useTypedSelector(state => state.translationStateMainPage)

    const closeModal = () => {
        dispatch(closeChatInfoModal())
    }

    const changeModal = () => {
        dispatch(changeChatInfoModal())
    }

    const chatInfoContent = chatInfoModalState ? 
        <SChatInfoModalContent>
            <SCloseModalBtn
                onClick={closeModal}
                width={'4vh'}
                height={'4vh'}
                color={'#B55C5C'}
            >
                <span/>
                <span/>
            </SCloseModalBtn>
            <SP>
                {translation('В чате запрещено', translationStateMainPage, textsMainPage)}:
            </SP>
            <SOl>
                <li>
                    1. {translation('Мат и оскорбления', translationStateMainPage, textsMainPage)}.
                </li>
                <li>
                    2. {translation('Публичные конфликты', translationStateMainPage, textsMainPage)}.
                </li>
                <li>
                    3. {translation('Оскорбительный ник или аватар', translationStateMainPage, textsMainPage)}.
                </li>
                <li>
                    4. ....
                </li>
                <li>
                    5. ....
                </li>
                <li>
                    6. ....
                </li>
                <li>
                    7. ....
                </li>
            </SOl>
        </SChatInfoModalContent>
        :
        null

    return (
        <SChatInfoModal>
            {chatInfoContent}
            <SOpenInfoChatModal 
                onClick={changeModal}
            >
                i
            </SOpenInfoChatModal>
        </SChatInfoModal>
    )
}