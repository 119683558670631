import { YouLostModalActionType, YouLostModalState, YouLostModalAction } from '../../types/youLostModalType'

const initialState: YouLostModalState = {
    youLostModalState: false,
    youLoseModalData: {}
}

export const youLostModalReducer = (state = initialState, action: YouLostModalAction): YouLostModalState => {
    switch(action.type) {
        case YouLostModalActionType.OPEN_YOU_LOST_MODAL:
            return {
                ...state,
                youLostModalState: true,
                youLoseModalData: action.data
            }
        case YouLostModalActionType.CLOSE_YOU_LOST_MODAL:
            return {
                ...state,
                youLostModalState: false,
                youLoseModalData: {}
            }
        default:
            return state
    }   
}
