import { Header } from '../../components/header'
import { AuthLayoutSection } from '../../components/authLayoutSection'
import { AuthorizationContent } from '../../components/authorization'

export const AuthorizationPage = ()  => (
    <>
        <Header rating={false} game={false} logo={true} authorizationPage={true}/>
        <main>
            <AuthLayoutSection game={false}>
                <AuthorizationContent/>
            </AuthLayoutSection>
        </main>
    </>
)