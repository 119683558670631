export enum YouLostModalActionType {
    OPEN_YOU_LOST_MODAL = 'OPEN_YOU_LOST_MODAL',
    CLOSE_YOU_LOST_MODAL = 'CLOSE_YOU_LOST_MODAL'
}

export interface YouLostModalState {
    youLostModalState: boolean,
    youLoseModalData: any
}

export interface YouLostModalAction {
    type: string,
    data?:any
}
