import { InfoModalActionType, InfoModalState, InfoModalAction } from '../../types/infoModalType'

const initialState: InfoModalState = {
    infoModalState: false
}

export const infoModalReducer = (state = initialState, action: InfoModalAction): InfoModalState => {
    switch(action.type) {
        case InfoModalActionType.INFO_MODAL_OPEN:
            return {
                ...state,
                infoModalState: true
            }
        case InfoModalActionType.INFO_MODAL_CLOSE:
            return {
                ...state,
                infoModalState: false
            }
        default:
            return state
    }   
}