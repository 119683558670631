import googleIcon from '../../assets/icons/google.svg'
import appleIcon from '../../assets/icons/apple.svg'
import facebookIcon from '../../assets/icons/facebook.svg'
import yandexIcon from '../../assets/icons/yandex.svg'

export const socialButtonsConfig = [
    {
        id: 'guestAccountBtn',
    },
    {
        id: 'googleBtn',
        icon: googleIcon
    },
    {
        id: 'appleBtn',
        icon: appleIcon
    },
    {
        id: 'facebookBtn',
        icon: facebookIcon
    },
    {
        id: 'yandexBtn',
        icon: yandexIcon
    }
]

export const textsAuthPage: string[] = [
    /* 0 */'ЯЗЫК',
    /* 1 */'ВХОД',
    /* 2 */'РЕГИСТРАЦИЯ',
    /* 3 */'Электронная почта или никнейм',
    /* 4 */'Никнейм юзера',
    /* 5 */'Пароль',
    /* 6 */'Запомнить меня',
    /* 7 */'Восстановить пароль',
    /* 8 */'ПРОДОЛЖИТЬ',
    /* 9 */'Вход с помощью',
    /* 10 */'Гостевой аккаунт',
    /* 11 */'Только просмотр',
    /* 12 */'Никнейм',
    /* 13 */'Электронная почта',
    /* 14 */'БИЗНЕСМЕН',
    /* 15 */'БИЗНЕСВУМЕН',
    /* 16 */'Повторите пароль',
    /* 17 */'Введите слово с картинки',
    /* 18 */'Согласен с',
    /* 19 */'пользовательским соглашением',
    /* 20 */'Регистрация с помощью',
    /* 21 */'Пустое поле почты или никнейма',
    /* 22 */'Пустое поле пароля',
    /* 23 */'Длина пароля менее 8 символов',
    /* 24 */'Ошибка',
    /* 25 */'Электронная почта или пароль неверны',
    /* 26 */'Ошибка сервера',
    /* 27 */'Уведомление',
    /* 28 */'Пустое поле никнейма',
    /* 29 */'Пустое поле email',
    /* 30 */'Введите корректный email',
    /* 31 */'Успешно',
    /* 32 */'Вы успешно зарегистрировались',
    /* 33 */'Пароли не совпадают',
    /* 34 */'Введите корректное число с картинки',
    /* 35 */'Вы должны быть согласны с пользовательским соглашением',
    /* 36 */'Не удалось перевести страницу',
    /* 37 */'ВЫХОД',
    /* 38 */'СПРАВКА',
    /* 39 */'КРАТКОЕ ОПИСАНИЕ ИГРЫ',
    /* 40 */'Многопользовательская игра «Магнат» – это игра, созданная на основе популярнейшей игры «Монополия» (Monopoly), в которой есть возможность покупать, продавать, арендовать собственность.',
    /* 41 */'После броска кубиков ваша фишка перемещается по полю и становится на клетке в зависимости от выпавшего на кубиках количества очков. Игра становится интересной благодаря дополнительному функционалу, который разнообразит игровой процесс.',
    /* 42 */'Цель игры',
    /* 43 */'остаться единственным не обанкротившимся игроком.',
    /* 44 */'ПРАВИЛА УЧАСТИЯ В ИГРЕ',
    /* 45 */'У вас есть несколько способов начать игру: создать игру, присоединиться к созданной игре, пригласить друга в игру.',
    /* 46 */'Для создания игры нужно нажать кнопку «Создать игру». Откроется окно с параметрами создания, в котором можно указать название игры, тип игры (стандартный, командный 2х2), количество игроков от 2 до 8 человек (стандартной игры), выбрать поле (малое (от 2 до 5 человек), среднее (от 2 до 6 человек), большое (от 2 до 8 человек), ставку золотом, пароль для приватной игры.',
    /* 47 */'Присоединиться к созданной игре можно, нажав кнопку «Играть» в списке «Лобби». Присоединится к игре 2х2 можно, нажав на значок «+», чтобы играть за определенную команду.',
    /* 48 */'Пригласить друга в игру 1х1 (друг против друга) можно, нажав на выбранного друга в списке друзей и выбрав пункт «Пригласить в игру». Для игры 2х2 в паре – выбрать пункт «Пригласить в пару».',
    /* 49 */'НАЧАЛО ИГРЫ',
    /* 50 */'Фишки всех игроков выстраиваются на поле ',
    /* 51 */'Старт',
    /* 52 */', после чего поочередно каждый делает свой ход, и фишка перемещается в зависимости от выпавшего на кубиках количества очков.',
    /* 53 */'В начале игры каждому игроку дается стартовый капитал в размере 2 000 000 $.',
    /* 54 */'ХОД ИГРЫ',
    /* 55 */'Бросить кубики',
    /* 56 */' осуществляет ход фишки игрока по полю. Время на ход игрока – 75 секунд. Ход текущего игрока определяет индикатор отсчета времени на ход. При выпадении дубля игрок ходит еще раз. При выпадении трех дублей подряд игрок перемещается на «Таможню».',
    /* 57 */'Купить',
    /* 58 */' осуществляет покупку участка с недвижимостью, если она никому не принадлежит.',
    /* 59 */'Аукцион',
    /* 60 */' осуществляет выставление участка с недвижимостью на аукцион. При этом недвижимость могут приобрести другие участники игры.',
    /* 61 */'Заложить',
    /* 62 */' осуществляет залог вашей недвижимости. В основном закладывают тогда, когда не хватает денег для погашения долгов.',
    /* 63 */'Выкупить',
    /* 64 */' осуществляет выкуп заложенной вами недвижимости. Как правило, выкуп из залога стоит дороже, чем сам залог.',
    /* 65 */'Построить',
    /* 66 */' осуществляет постройку филиалов и холдингов. Строительство возможно только в том случае, когда у вас выстроилась монополия.',
    /* 67 */'Продать',
    /* 68 */' осуществляет продажу филиалов и холдингов. Как правило, продавать филиалы и холдинги нужно для того, чтобы пополнить свой баланс для погашения долгов.',
    /* 69 */'Заключить сделку',
    /* 70 */' осуществляет обмен, продажу или покупку недвижимости между игроками.',
    /* 71 */'Взять кредит',
    /* 72 */' (только для VIP аккаунта). Появляется возможность взять кредитные средства в банке. Кредитные деньги даются на 15-25 ходов (в зависимости от количества подарков у игрока), после чего нужно обязательно вернуть их банку («Погасить кредит»).',
    /* 73 */'Сдаться',
    /* 74 */' осуществляет выход из игрового процесса и объявление игрока банкротом. После нажатия вся приобретенная недвижимость становится свободной для покупки другими участниками игры. В игре 2х2 вся недвижимость переходит игроку-партнеру.',
    /* 75 */'При попадании на свободный участок с недвижимостью, которая пока еще никому не принадлежит, вы можете купить эту недвижимость. Также можно выставить недвижимость на аукцион другому игроку. Предложивший за недвижимость в аукционе самую высокую цену становится ее владельцем.',
    /* 76 */'Игроки, имеющие недвижимость, могут взимать арендную плату с игроков, которые попадают на их участок. При попадании фишки на участок, который контролирует другой игрок, вы обязаны оплатить аренду за пребывание на его участке.',
    /* 77 */'Как только у вас выстроилась монополия (несколько фирм одной цветовой группы), у вас появляется возможность строить филиалы и холдинги («Построить»). При строительстве филиалов и холдингов арендная плата значительно возрастает, поэтому вам следует вести строительство на как можно большем количестве участков. Строить можно только по одному объекту в монополии за один ход. Продавать филиалы и холдинги за один ход можно сколько угодно.',
    /* 78 */'Если вы нуждаетесь в деньгах для погашения каких-либо долгов, вы можете заложить вашу недвижимость (',
    /* 79 */'Заложить',
    /* 80 */'), продать ваши филиалы («Продать») или взять кредит («Взять кредит»).',
    /* 81 */'В ХОДЕ ИГРЫ ИГРОК МОЖЕТ ПОПАДАТЬ НА ТАКИЕ ПОЛЯ:',
    /* 82 */' При остановке на клетке ',
    /* 83 */' игрок получает вознаграждение в размере 400 000$.',
    /* 84 */'Налог',
    /* 85 */' При попадании на клетку ',
    /* 86 */' игрок обязан оплатить ',
    /* 87 */' от имеющегося капитала ',
    /* 88 */'Оплатить',
    /* 89 */'КОМАНДНЫЕ ИГРЫ (2х2)',
    /* 90 */'В игре присутствует возможность играть в командные игры 1х2 или 2х2, (на стандартной карте 42 клетки) 1х2, 2х2 или 2х2х2 (на большой карте 72 клетки), 1х2, 2х2, 2х2х2, 2х2х2х2 (на гигантской карте 132 клетки).',
    /* 91 */'- При попадании на участок игрока-партнера вы не платите ему аренду.',
    /* 92 */'- При заключении сделки с игроком-партнером снимается ограничение на обмен в 30% разницы сторон.',
    /* 93 */'- Если игрок-партнер сдается, то все его фирмы и наличные передаются союзнику.',
    /* 94 */'- При победе выигрыш распределяется равными долями между вами и игроком-партнером.'
]