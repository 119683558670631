import { MenuButtonsHideActionType } from '../../types/menuButtonsHideType'

export const closeMenuButtonHide = () => {
    return {type: MenuButtonsHideActionType.SET_UP_HIDE_BUTTONS}
}

export const openMenuButtonHide = () => {
    return {type: MenuButtonsHideActionType.SET_UP_SHOW_BUTTONS}
}

export const closeAdditionMenuButton = () => {
    return {type: MenuButtonsHideActionType.SET_HIDE_ADDITION_BUTTON}
}

export const openAdditionMenuButton = (command : string = "default") => {
    return {type: MenuButtonsHideActionType.SET_UP_ADDITION_BUTTON , command: command}
}