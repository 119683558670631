import avatarMini from '../../assets/img/avatar_mini.png'
import android from '../../assets/icons/android.svg'

export const userListTab = [
    {
        id: 'allUsers'
    },
    {
        id: 'friends'
    },
    {
        id: 'myClan'
    }
]

export const userListConfig = [
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    },
    {
        avatar: avatarMini,
        nickname: 'Ник игрока (#1)',
        rang: 'Менеджер',
        device: android
    }
]