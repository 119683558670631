import { VolumeModalActionType, VolumeModalState, VolumeModalAction } from '../../types/volumeModalType'

const initialState: VolumeModalState = {
    volumeModalState: false
}

export const volumeModalReducer = (state = initialState, action: VolumeModalAction): VolumeModalState => {
    switch(action.type) {
        case VolumeModalActionType.CHANGE_VALUE_VOLUME_MODAL:
            return {
                ...state,
                volumeModalState: !state.volumeModalState
            }
        case VolumeModalActionType.CLOSE_VOLUME_MODAL:
            return {
                ...state,
                volumeModalState: false
            }
        default:
            return state
    }   
}