import {
    useState,
    useMemo,
    useEffect
} from 'react'

import WTSServise from '../../services/WTSServise'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { setActiveChatGameBtn } from '../../store/actionCreators/activeChatGameBtn'
import { setUpTranslateGameGhatPage } from '../../store/actionCreators/translation'
import { setUpChatlobby } from '../../store/actionCreators/chatlobbyActionCreate'

import { socket2 } from '../../sockets/chat/createChatConnectionsSocket'

import { textsGameGhatPage } from './config'
import { colors } from '../gameContent/config'
import { translation } from '../../utils/translation'

import {
    SChatWrap,
    SChat,
    SSendMessageWrap,
    SInputWrap,
    SInput,
    SSendMessageBtn,
    SChatWindow,
    SButtonWrap,
    SChatButtonGamePage,
    Li,
    SBlock
} from './style'

export const ChatGame = () => {

    const dispatch = useDispatch()

    const { ChatlobbyListState } = useTypedSelector(state => state.chatlobbyReducer)
    const { activeChatGameBtnState } = useTypedSelector(state => state.activeChatGameBtnState)
    const { translationStateGameChatPage } = useTypedSelector(state => state.translationStateGameChatPage)
    const { languageState } = useTypedSelector(state => state.languageState)

    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        updateTranslate()
    }, [languageState])
    
    const updateTranslate = async () => {
        try {
            const response = await WTSServise.translations(textsGameGhatPage, languageState)
            let translateArr: string[] = []
            response.data.data.phraseTranslations.map((item: any) => {
                if(item.phrase.translation) {
                    translateArr.push(item.phrase.translation.text)
                    return
                } else {
                    translateArr.push(item.phrase.text)
                    return
                }
            })

            dispatch(setUpTranslateGameGhatPage(translateArr))
        } catch (e: any) {
            if (!e) return
            console.log(e)
        }
    }
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    const handleSendMessage = () => {
        if (inputValue.trim() !== '') {
            socket2.emit("message:add", {
                type: "lobby",
                sender: localStorage.getItem('currentUserNickname'),
                lobbyName: localStorage.getItem("activeLobbieId"),
                message: inputValue
            })

            setInputValue('')
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            socket2.emit("message:add", {
                type: "lobby",
                sender: localStorage.getItem('currentUserNickname'),
                lobbyName: localStorage.getItem("activeLobbieId"),
                message: inputValue
            })

            setInputValue('')
        }
    }

    const gamersGhatListContent = useMemo(() => {
        return [translation('ОБЩИЙ ЧАТ', translationStateGameChatPage, textsGameGhatPage)/*, 'ИГРОК 1', 'ВТОРОЙ ИГРОК', 'ИГРОК 3', 'ЕЩЕ ИГРОК', 'ПЯТЫЙ'*/]
            .map((item, i) => {
                return (
                    <SChatButtonGamePage
                        id={i.toString()}
                        key={i}
                        gamePage={true}
                        first={!i}
                        colors={colors[i]}
                        className={i === activeChatGameBtnState ? 'active' : undefined}
                        newMsg={i === 2 ? true : false}
                        data-new-msg={'2'}
                    >
                        {item}
                    </SChatButtonGamePage>
                )
            })
    }, [activeChatGameBtnState, translationStateGameChatPage])

    const _scrollDown = (el:any) => {
        if (el)
            el.scrollTop = el.scrollHeight;
    }
    
    const renderMessages = useMemo(() => {
        return (
            <SChatWindow
                ref={el => _scrollDown(el)}
            >
                {ChatlobbyListState.map((item: string | undefined, i: number) => {
                    if(item) {
                        const regex = /<user id="([^"]+)">/
                        const match = regex.exec(item)
                        const idUser = match ? match[1] : null
                        return (
                            <Li 
                                key={i} 
                                id='messageContent' 
                                idUser={idUser} 
                                dangerouslySetInnerHTML={{ __html: item }} 
                            />
                        )
                    }
                })}
            </SChatWindow>
        )
    }, [ChatlobbyListState])

    return (
        <SChatWrap>
            <SChat>
                <SButtonWrap
                    onClick={(e) => {
                        const target = e.target as HTMLButtonElement
                        const id = +target.id
                        /*dispatch(setActiveChatGameBtn(id))*/
                    }}
                >
                    {gamersGhatListContent}
                </SButtonWrap>
                <SBlock
                    colors={colors}
                    style={{'paddingTop': '1vh'}}
                    gamePage={true}
                    activeBtn={activeChatGameBtnState}
                >
                    {renderMessages}
                    <SSendMessageWrap>
                        <SInputWrap>
                            <SInput
                                type='text'
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                            />
                        </SInputWrap>
                        <SSendMessageBtn
                            onClick={handleSendMessage}
                        >
                            {translation('ОТПРАВИТЬ', translationStateGameChatPage, textsGameGhatPage)}
                        </SSendMessageBtn>
                    </SSendMessageWrap>
                </SBlock>
            </SChat>
        </SChatWrap>
    )
}
