export enum LanguageModalActionType {
    CHANGE_VALUE_LANGUAGE_MODAL = 'CHANGE_VALUE_LANGUAGE_MODAL',
    CLOSE_LANGUAGE_MODAL = 'CLOSE_LANGUAGE_MODAL'
}

export interface LanguageModalState {
    languageModalState: boolean
}

export interface LanguageModalAction {
    type: string,
    payload?: boolean
}