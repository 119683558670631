export enum VolumeModalActionType {
    CHANGE_VALUE_VOLUME_MODAL = 'CHANGE_VALUE_VOLUME_MODAL',
    CLOSE_VOLUME_MODAL = 'CLOSE_VOLUME_MODAL'
}

export interface VolumeModalState {
    volumeModalState: boolean
}

export interface VolumeModalAction {
    type: string,
    payload?: boolean
}