import { MakeADealModalActionType, MakeADealModalState, MakeADealModalAction } from '../../types/makeADealModalType'
const initialState: MakeADealModalState = {
    makeADealModalState: false,
    makeADealModalDataState: {},
    dealDetails: {}
}

export const makeADealModalReducer = (state = initialState, action: MakeADealModalAction): MakeADealModalState => {
    switch(action.type) {
        case MakeADealModalActionType.OPEN_MAKE_A_DEAL_MODAL:
            return {
                ...state,
                makeADealModalState: true,
                makeADealModalDataState: action.payload
            }
        case MakeADealModalActionType.SET_UP_DEAL_DETAILS:
            return {
                ...state,
                makeADealModalState: true,
                dealDetails: action.payload
            }
        case MakeADealModalActionType.CLOSE_MAKE_A_DEAL_MODAL:
            return {
                ...state,
                makeADealModalState: false,
                makeADealModalDataState: {}
            }
        default:
            return state
    }   
}