import styled, { css } from 'styled-components'

import { device } from '../../theme'

import scrollLines from '../../assets/icons/scroll_lines.svg'
import chatUserBG from '../../assets/img/chatUserBG.jpg'

export const SChat = styled.div`
    grid-area: chat;
    height: 19vh;

    @media (${device.tablet}) and (orientation: portrait) {
        margin-bottom: 10vh;
    }

    @media (${device.mobileL}) {
        margin-bottom: 10vh;
    }
`

export const SSendMessageWrap = styled.div` 
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    bottom: 0;
    border-radius: 1.2vh;
    padding: 0.5vh 1vh;
    height: 5vh;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.50);
    gap: 1vh;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
        #212121;
`

export const SInputWrap = styled.div`
    width: 100%;
    height: 3.5vh;
    border-radius: 0.8vh;
    transform-style: preserve-3d;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 1px);
        background: linear-gradient(360deg, rgba(220, 220, 220, 1), rgba(90, 90, 90, 0.2));
        border-radius: inherit;
        transform: translateZ(-1px);
    }
`

export const SInput = styled.input`
    width: 100%;
    height: 100%;
    border-radius: 0.8vh;
    background: rgba(0, 0, 0, 0.80);
    color: #FFF;
    padding: 0 2vh;

    @media (${device.tablet}) {
        font-size: 12px;
    }

    @media (${device.mobileM}) {
        font-size: 11px;
    }
`

export const SSendMessageBtn = styled.button<{width: string, height: string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({width}) => width};
    height: ${({height}) => height};
    border-radius: 0.8vh;
    font-size: 1.7vmin;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50);
    transform-style: preserve-3d;
    color: #FFF;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: 
        linear-gradient(180deg, #06AE17 0%, #126B10 52.69%, #0B6209 100%), 
        linear-gradient(180deg, #353535 0%, #343434 52.69%, #282828 100%);
    border: 1px solid #BEFF97;
    
    >img{
        height: 2vh;
    }
    
    &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: inherit;
        transform: translateZ(1px);
    }
    
    &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background: linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1));
        border-radius: inherit;
        transform: translateZ(-1px);
    }
    
    &:hover {
        &::after {
            background: rgba(0, 0, 0, 0.2);
        }
    }
    
    &:focus {
        &::after {
            background: rgba(255, 255, 255, 0.2);
        }
    }

    @media (${device.tablet}) {
        font-size: 11px;
    }

    @media (${device.mobileM}) {
        font-size: 10px;
    }
`

export const SEmoji = styled.button`
    height: 3.5vh;
    width: 3.5vh;
    
    >img{
        width: 100%;
    }
`

export const SChatWindow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
    height: 12vh;
    padding: 0 2vw 0 1vw;
    margin-right: 0.3vw;
    overflow-y: scroll;
    scrollbar-color: #126D11 rgba(0, 0, 0, 0.30);
    scrollbar-width: auto;
    
    ::-webkit-scrollbar {
        width: 2vh;
        height: 100%;
        background: rgba(0, 0, 0, 0.30);
    }
    
    ::-webkit-scrollbar-thumb {
        background: url(${scrollLines}) center center/100% no-repeat, linear-gradient(90deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(270deg, #2D7500 0%, #276207 42.19%, #132E05 100%);
        border: 1px solid #8EFF59;
        border-radius: 2vh;
        min-height: 5vh;
    }
`

const newMsgCss = css<{newMsg?: boolean}>`
    &::after {
        content: attr(data-new-msg);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FCEDD6;
        font-size: 1.2vmin;
        position: absolute;
        width: 1.8vh;
        height: 1.8vh;
        top: -20%;
        right: 10%;
        transform: translateZ(1px);
        background: red;
        border-radius: 100%;
        border: 1px solid #FCEDD6;
        box-shadow: 0px 4px 19px #D9D9D9;
    }
`

export const SButtonWrap = styled.div<{height?: string}>`
    display: flex;
    height: 3.5vh;
    gap: 0.2vh;
    padding-left: 1.5vh;
`

export const SChatButtonUserPage = styled.button<{newMsg?: boolean}>`
    height: 3.5vh;
    margin-top: 0.5vh;
    color: #FFF;
    background: 
        linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
        #414141;
    box-shadow: 2px -4px 7px rgba(0, 0, 0, 0.50);
    font-size: 1.2vmin;
    padding: 0 1vh 0.5vh 1vh;
    border-radius: 1vh 2.5vh 0 0;
    text-align: center;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transform-style: preserve-3d;
    width: 7.5vw;

    >*{
        opacity: 0.6;
    }

    &.active {
        background: 
            linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 52.60%), 
            linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, rgba(0, 0, 0, 0.40) 100%), 
            linear-gradient(0deg, #1A1A1A 0%, #1A1A1A 100%), 
            linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%), 
            linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), 
            #613A24;

        >*{
            opacity: 1;
        }

        &::before {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            background: linear-gradient(-45deg, rgba(255, 89, 36, 100), rgba(255, 162, 41, 100));
            border-radius: inherit;
            transform: translateZ(-1px);
        }

        @media (${device.tablet}) {
            &::before {
                top: -1px;
                left: -1px;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
            }
        }
    }

    ${({newMsg}) => newMsg ? newMsgCss : null}

    @media (${device.tablet}) {
        min-width: 85px;
        font-size: 9px;
    }

    @media (${device.mobileL}) {
        min-width: 80px;
        font-size: 8px;
    }

    @media (${device.mobileM}) {
        min-width: 70px;
        font-size: 7px;
    }

    @media (${device.mobileS}) {
        min-width: 60px;
    }
`

export const SChatWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.7vh;
    transform-style: preserve-3d;
    background: rgba(0, 0, 0, 0.50);
    transform-style: preserve-3d;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: url(${chatUserBG}) center center/cover no-repeat;
        transform: translateZ(-1px);
    }

    &::before {
        content: '';
        top: -2px;
        left: -2px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(rgba(220, 220, 220, 1), rgba(90, 90, 90, 1));
        border-radius: inherit;
        transform: translateZ(-2px);
    }

    @media (${device.tablet}) {
        &::before {
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }
`

export const SChatMsg = styled.p`
    font-size: 1.2vmin;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50);
    
    >span {
        color: #79B815;
    }

    @media (${device.tablet}) {
        font-size: 11px;
    }

    @media (${device.mobileL}) {
        font-size: 10px;
    }
`